import * as constant from './constants';

export const initialState = {
  loading: false,
  bankAccounts: [],
  bankAccount: null,
};

const bankAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.BANKACCOUNT_LOADING:
      return {
        ...state,
        loading: action.loading,
        bankAccounts: [],
        bankAccount: null,
      };
    case constant.BANKACCOUNTS_FULFILLED:
      return {
        ...state,
        loading: false,
        bankAccounts: action.payload,
      };
    case constant.BANKACCOUNT_FULFILLED:
      return {
        ...state,
        loading: false,
        bankAccount: action.payload,
      };
    default:
      return state;
  }
};

export default bankAccountReducer;
