/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import {
  Row,
  Col,
  Table,
  PageHeader,
  Button,
  Form,
  Card,
  Select,
  DatePicker,
} from 'antd';
import debounce from 'lodash/debounce';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { actions } from '../../../modules/reducers';
import api from '../../../utils/api';

const { RangePicker } = DatePicker;
const { Option } = Select;

const POSOrder = ({
  dispatch, orders, pagination, history, query,
}) => {
  const page = query.get('page');
  const user_id = query.get('user_id');
  const start_date = query.get('start_date');
  const end_date = query.get('end_date');

  const [form] = Form.useForm();
  const [userData, setUsers] = useState([]);

  useEffect(() => {
    dispatch(
      actions.fetchPOSOrders({
        page: page || 1,
        user_id,
        start_date,
        end_date,
      }),
    );
  }, [dispatch, page, start_date, end_date, user_id]);

  const [ShowFilter, setShowFilter] = useState(false);

  const columns = [
    {
      title: 'Invoice',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, record) => (
        <div>
          <p style={{ fontSize: 13, margin: 0 }}>
            <strong>Customer:</strong>
            {' '}
            {record.customer_name}
          </p>
          <p style={{ fontSize: 12 }}>
            <strong>No Hp:</strong>
            {' '}
            {record.customer_phone}
          </p>
          <p style={{ fontSize: 11, fontWeight: 700 }}>{record.invoice_no}</p>
        </div>
      ),
    },
    {
      title: 'Kasir',
      dataIndex: 'cashier',
      key: 'cashier',
      render: (cashier) => cashier?.name || '-',
    },
    {
      title: 'Barang Sortir',
      dataIndex: 'is_barang_sortir',
      key: 'is_barang_sortir',
      render: (text) => (
        <div>
          {text ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
          ) : (
            <CloseCircleOutlined style={{ color: 'red' }} />
          )}
        </div>
      ),
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      render: (value) => `Rp. ${value.toLocaleString('ID')}`,
    },
    {
      title: 'Biaya Tambahan',
      dataIndex: 'additional_cost',
      key: 'additional_cost',
      render: (value) => `Rp. ${value.toLocaleString('ID')}`,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (value) => `Rp. ${value.toLocaleString('id')}`,
    },
    {
      title: 'Tanggal',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      render: (text) => format(new Date(text), 'dd MMM yyyy'),
    },
  ];

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/pos-orders?${query.toString()}`);
  };

  const handleFilter = (value) => {
    if (value.date_range) {
      const [start, end] = value.date_range;
      if (start) {
        query.set('start_date', start.format('YYYY-MM-DD'));
      }
      if (end) {
        query.set('end_date', end.format('YYYY-MM-DD'));
      }
    } else if (value.user_id) {
      query.set('user_id', value.user_id.value);
    }

    query.delete('page');
    history.push(`/pos-orders?${query.toString()}`);
  };

  const handleReset = () => {
    form.resetFields();
    query.delete('start_date');
    query.delete('end_date');
    query.delete('user_id');
    history.push('/pos-orders');
  };

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/user?name=${value}`);
    setUsers(res.data);
  }, 500);

  return (
    <Row>
      <Col span={24} style={{ marginBottom: 16 }}>
        <PageHeader title="Daftar Pesanan di Toko" style={{ padding: '0 0 16px' }} />
        <Button type="primary" onClick={() => setShowFilter(!ShowFilter)}>
          <FilterOutlined />
          {' '}
          {ShowFilter ? 'Sembunyikan Filter' : 'Filter'}
        </Button>
      </Col>
      {ShowFilter ? (
        <Col span={24} style={{ marginBottom: 16 }}>
          <Form layout="vertical" form={form} onFinish={handleFilter} onReset={handleReset}>
            <Card title="Filter berdasarkan">
              <Row>
                <Col span={5}>
                  <Form.Item name="date_range">
                    <RangePicker placeholder={['Tanggal mulai', 'Tanggal akhir']} />
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item name="user_id">
                    <Select
                      labelInValue
                      showSearch
                      placeholder="Ketik Nama User"
                      onSearch={onSearch}
                      filterOption={false}
                    >
                      {userData.map((user) => (
                        <Option key={user.id} value={user.id}>
                          {user.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <>
                <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                  Terapkan Filter
                </Button>
                <Button type="primary" htmlType="reset" danger>
                  Reset Filter
                </Button>
              </>
            </Card>
          </Form>
        </Col>
      ) : null}
      <Col span={24}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={orders}
          onRow={(record) => ({
            onClick: () => {
              history.push(`/pos-orders/${record.id}`);
            },
          })}
          onChange={handleTableChange}
          pagination={{
            current: parseInt(page) || 1,
            total: (pagination && pagination.total) || 0,
            showSizeChanger: false,
          }}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ posOrder }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    pagination: posOrder.pagination,
    orders: posOrder.orders,
    query,
  };
};

export default connect(mapStateToProps)(POSOrder);
