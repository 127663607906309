/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Row, Col, Table, PageHeader, Button, Card, Form, Input,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import { actions } from '../../../modules/reducers';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <span style={{ cursor: 'pointer' }}>{text}</span>,
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (text) => <span style={{ cursor: 'pointer' }}>{text}</span>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Loyalty Point Balance',
    dataIndex: 'loyalty',
    key: 'loyalty_point_balance',
    render: (_, { loyalty }) => <span style={{ cursor: 'pointer' }}>{loyalty ? loyalty.balance : 0}</span>,
  },
];

const Customer = ({
  dispatch, users, query, history, pagination,
}) => {
  const [form] = Form.useForm();

  const page = query.get('page') || 1;
  const name = query.get('name');
  const [ShowFilter, setShowFilter] = useState(false);

  useEffect(() => {
    dispatch(
      actions.fetchUserList({
        name,
        page,
      }),
    );
  }, [dispatch, name, page]);

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/customers?${query.toString()}`);
  };

  const handleFilter = (value) => {
    query.set('name', value.name);
    query.delete('page');

    history.push(`/customers?${query.toString()}`);
  };

  return (
    <Row>
      <PageHeader title="Daftar Pelanggan" style={{ padding: '0 0 16px' }} />
      <Button type="primary" style={{ marginLeft: 15 }} onClick={() => setShowFilter(!ShowFilter)}>
        <FilterOutlined />
        {' '}
        {ShowFilter ? 'Sembunyikan Filter' : 'Filter'}
      </Button>
      {ShowFilter ? (
        <Col span={24} style={{ marginTop: 16 }}>
          <Form layout="vertical" form={form} onFinish={handleFilter}>
            <Card title="Filter berdasarkan">
              <Col span={8}>
                <Form.Item name="name">
                  <Input placeholder="Nama" />
                </Form.Item>
              </Col>
              <>
                <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                  Terapkan Filter
                </Button>

                <Button
                  type="primary"
                  htmlType="reset"
                  onClick={() => {
                    form.resetFields();
                    history.push('/customers');
                  }}
                  danger
                >
                  Reset Filter
                </Button>
              </>
            </Card>
          </Form>
        </Col>
      ) : null}
      <Col span={24}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={users}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => {
              history.push(`/customers/${record.id}`);
            },
          })}
          pagination={{
            current: +query.get('page') || 1,
            total: (pagination && pagination.total) || 0,
            showSizeChanger: false,
          }}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ user }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    query,
    users: user.users,
    pagination: user.pagination,
  };
};

export default connect(mapStateToProps)(Customer);
