import * as constant from './constants';

export const initialState = {
  loading: false,
  categories: [],
  category: null,
  pagination: null,
  sum: 0,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.CATEGORY_LOADING:
      return {
        ...state,
        loading: action.loading,
        categories: [],
        category: null,
        pagination: null,
      };
    case constant.CATEGORIES_FULFILLED:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        pagination: action.pagination,
      };
    case constant.CATEGORY_FULFILLED:
      return {
        ...state,
        loading: false,
        category: action.payload,
      };
    case constant.CATEGORIES_SEARCHED:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case constant.CATEGORIES_SUM:
      return {
        ...state,
        loading: false,
        sum: action.payload,
      };
    default:
      return state;
  }
};

export default categoryReducer;
