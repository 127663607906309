import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Statistic } from 'antd';
import { Line } from 'react-chartjs-2';

import { actions } from '../../modules/reducers';

const Stats = ({ totalStats, graphStats, dispatch }) => {
  useEffect(() => {
    dispatch(actions.fetchTotalStats());
    dispatch(actions.fetchGraphStats());
  }, [dispatch]);

  let graphDataset;

  if (graphStats.length !== 0) {
    graphDataset = {
      labels: graphStats.map((graph) => graph.key),
      datasets: [
        {
          label: 'Pesanan per hari',
          backgroundColor: 'rgba(24, 144, 255,1)',
          data: graphStats.map((graph) => graph.count),
        },
      ],
    };
  }

  return (
    <Row>
      <Col span={24} style={{ marginBottom: 20 }}>
        <h2>Dashboard</h2>
      </Col>
      <Col span={12}>
        {graphStats.length !== 0 ? (
          <Card title="Grafik Pesanan">
            <Line
              data={graphDataset}
              width={200}
              height={300}
              options={{ maintainAspectRatio: false }}
            />
          </Card>
        ) : null}
      </Col>
      {totalStats ? (
        <Col span={11} offset={1}>
          <Card>
            <Row>
              <Col span={12}>
                <Statistic
                  title="Total Order"
                  value={totalStats.total_order}
                  valueStyle={{ color: '#1890ff' }}
                  style={{
                    minHeight: 100,
                    padding: 16,
                    borderBottom: '1px solid #EFEFEF',
                    borderRight: '1px solid #EFEFEF',
                  }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Total Motif"
                  value={totalStats.total_product}
                  valueStyle={{ color: '#1890ff' }}
                  style={{
                    minHeight: 100,
                    padding: 16,
                    borderBottom: '1px solid #EFEFEF',
                  }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Total Customer"
                  value={totalStats.total_customer}
                  valueStyle={{ color: '#1890ff' }}
                  style={{
                    minHeight: 100,
                    padding: 16,
                    borderRight: '1px solid #EFEFEF',
                  }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Total Model"
                  value={totalStats.total_category}
                  valueStyle={{ color: '#1890ff' }}
                  style={{ minHeight: 100, padding: 16 }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      ) : null}
    </Row>
  );
};

const mapStateToProps = ({ stats }) => ({
  totalStats: stats.totalStats,
  graphStats: stats.graphStats,
});

export default connect(mapStateToProps)(Stats);
