import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.PRODUCTION_LOADING,
});

export const fetchExpenseCategories = () => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get('/production/expense-categories');

    if (res.success) {
      dispatch({
        type: constant.PRODUCTION_EXPENSE_CATEGORY_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchProductionFees = (feeId) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/production/tailor-fees/${feeId}`);

    if (res.success) {
      dispatch({
        type: constant.PRODUCTION_FEES_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};
