import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.EXPENSE_LOADING,
});

export const fetchExpenses = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const qs = Object.keys(params)
      .filter((x) => params[x])
      .map((x) => `${x}=${params[x]}`)
      .join('&');

    const { data: res } = await api.get(`/production/expenses?${qs}`);

    if (res.success) {
      dispatch({
        type: constant.EXPENSES_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchExpense = (modelID) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/production/expenses/${modelID}`);

    if (res.success) {
      dispatch({
        type: constant.EXPENSE_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createExpense = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/production/expenses', data);

    if (res.success) {
      message.success('Berhasil menambahkan barang');
      dispatch(fetchExpenses({ page: 1, expense_type: 'SEW' }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteExpense = (model) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/production/expenses/${model.id}`);

    if (res.success) {
      message.success('Berhasil menghapus model.');
      dispatch(fetchExpenses({ page: 1, expense_type: 'SEW' }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateExpense = (formData, model) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/production/expenses/${model.id}`, formData);

    if (res.success) {
      message.success('Berhasil mengubah model.');
      dispatch(fetchExpenses({ page: 1, expense_type: 'SEW' }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const createCutterExpense = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/production/expenses', data);

    if (res.success) {
      message.success('Berhasil menambahkan barang');
      dispatch(fetchExpenses({ page: 1, expense_type: 'CUT' }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteCutterExpense = (model) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/production/expenses/${model.id}`);

    if (res.success) {
      message.success('Berhasil menghapus model.');
      dispatch(fetchExpenses({ page: 1, expense_type: 'CUT' }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateCutterExpense = (formData, model) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/production/expenses/${model.id}`, formData);

    if (res.success) {
      message.success('Berhasil mengubah model.');
      dispatch(fetchExpenses({ page: 1, expense_type: 'CUT' }));
    }
  } catch (err) {
    console.error(err);
  }
};
