import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.MODEL_LOADING,
});

export const fetchModels = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const qs = Object.keys(params)
      .filter((x) => params[x])
      .map((x) => `${x}=${params[x]}`)
      .join('&');

    const { data: res } = await api.get(`/production/models?${qs}`);

    if (res.success) {
      dispatch({
        type: constant.MODELS_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createModel = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/production/models', data);

    if (res.success) {
      message.success('Berhasil menambahkan barang');
      dispatch(fetchModels({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchModel = (modelID) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/production/models/${modelID}`);

    if (res.success) {
      dispatch({
        type: constant.MODEL_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteModel = (model) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/production/models/${model.id}`);

    if (res.success) {
      message.success('Berhasil menghapus model.');
      dispatch(fetchModels({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateModel = (formData, model) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/production/models/${model.id}`, formData);

    if (res.success) {
      message.success('Berhasil mengubah model.');
      dispatch(fetchModels({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};
