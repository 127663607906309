import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  PageHeader,
  Row,
  Table,
  Modal,
  Form,
  Card,
  Select,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { actions } from '../../../modules/reducers';
import CreateExpenseModal from '../components/create';
import UpdateExpenseModal from '../components/update';
import api from '../../../utils/api';

const { confirm } = Modal;
const { Option } = Select;

const ExpensePage = ({
  dispatch,
  expenses,
  query,
  pagination,
  history,
}) => {
  const page = query.get('page') || 1;
  const expense_category_id = query.get('expense_category_id');
  const tailor_id = query.get('tailor_id');

  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [addModalVisible, setAddModalVisible] = useState(false);

  const [selectedExpense, setSelectedExpense] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const [ShowFilter, setShowFilter] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [tailors, setTailors] = useState([]);

  useEffect(() => {
    dispatch(actions.fetchExpenses({ page, expense_category_id, tailor_id, expense_type: 'SEW' }));
  }, [dispatch, page, expense_category_id, tailor_id]);

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/production/tailor/expense?${query.toString()}`);
  };

  const handleDeleteClicked = (expense) => {
    if (expense.is_billed || expense.production_batch_return_id) {
      return notificationApi.error({
        message: expense.is_billed ? 'Pendapatan/Potongan sudah direkap!' : 'Pendapatan/Potongan ada barang kembali',
        description: expense.is_billed ? 'Pendapatan/Potongan sudah direkap! Hapus Rekap terlebih dahulu di menu gaji sebelum menghapus pendapatan/potongan ini' : 'Hapus barang kembali terlebih dahulu sebelum menghapus pendapatan/potongan',
      });
    }

    return confirm({
      title: 'Hapus Pendapatan/Potongan',
      content: 'Anda yakin ingin menghapus Pendapatan/Potongan ini?',
      onOk: () => {
        dispatch(actions.deleteExpense(expense));
      },
    });
  };

  const onCreate = (values) => {
    dispatch(actions.createExpense({ ...values }));
    setAddModalVisible(false);
  };

  const onEdit = (values) => {
    dispatch(actions.updateExpense(values, selectedExpense, { history }));
    setEditModalVisible(false);
    setSelectedExpense(null);
  };

  const handleFilter = (value) => {
    Object.keys(value).forEach((param) => {
      if (param === 'expense_category_id') {
        if (value[param] && value[param].value > 0) {
          query.set(param, value[param].value);
        }
      } else if (param === 'tailor_id') {
        if (value[param] && value[param].value > 0) {
          query.set(param, value[param].value);
        }
      } else if (value[param]) {
        query.set(param, value[param]);
      }
    });
    query.set('page', 1);
    history.push(`/production/tailor/expense?${query.toString()}`);
  };

  const onTailorSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/tailors?name=${value || ''}`);
    setTailors(res.data);
  }, 500);

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/expense-categories?name=${value}`);
    setExpenseCategories(res.data);
  }, 500);

  const columns = [
    {
      title: 'Nama Penjahit',
      dataIndex: 'tailor_name',
      key: 'tailor_name',
      render: (_, record) => (
        <Link to="/production/tailor">
          {record?.production_tailor?.name}
        </Link>
      ),
    },
    {
      title: 'Kategori',
      dataIndex: 'expense_category',
      key: 'expense_category',
      render: (_, record) => `${record.production_expense_category.name}`,
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Jumlah',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => `Rp. ${record.amount.toLocaleString('ID')}`,
    },
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => format(new Date(record.date), 'dd MMM yyyy'),
    },
    {
      title: 'Nomor Seri',
      dataIndex: 'production_batch',
      key: 'production_batch',
      render: (_, record) => (record.production_batch_id ? (
        <Link to={`/production/batch/${record.production_batch.id}`}>
          Seri
          {' '}
          {record.production_batch.batch_no}
        </Link>
      ) : 'N/A'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button type="danger" onClick={() => handleDeleteClicked(record)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Row>
        <PageHeader title="Pendapatan/Potongan" style={{ padding: '0 0 16px' }} />
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => setAddModalVisible(true)}>
              <PlusOutlined />
              Tambah
            </Button>
            <Button type="primary" style={{ marginLeft: 15 }} onClick={() => setShowFilter(!ShowFilter)}>
              <FilterOutlined />
              {' '}
              {ShowFilter ? 'Sembunyikan Filter' : 'Filter'}
            </Button>
          </div>
          <div style={{ marginBottom: 16 }}>
            {ShowFilter ? (
              <Form form={form} layout="horizontal" onFinish={handleFilter}>
                <Card title="Filter berdasarkan">
                  <Col span={8}>
                    <Form.Item name="expense_category_id">
                      <Select
                        labelInValue
                        showSearch
                        placeholder="Ketik Nama Kategori"
                        onSearch={onSearch}
                        filterOption={false}
                      >
                        {expenseCategories.map((category) => (
                          <Option key={category.id} value={category.id}>
                            {category.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="tailor_id">
                      <Select
                        labelInValue
                        showSearch
                        placeholder="Ketik Nama Penjahit"
                        onSearch={onTailorSearch}
                        filterOption={false}
                      >
                        {tailors.map((tailor) => (
                          <Option key={tailor.id} value={tailor.id}>
                            {tailor.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <>
                    <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                      Terapkan Filter
                    </Button>
                    <Button
                      type="primary"
                      htmlType="reset"
                      onClick={(e) => {
                        e.preventDefault();
                        form.resetFields();
                        history.push('/production/expense');
                      }}
                      danger
                    >
                      Reset Filter
                    </Button>
                  </>
                </Card>
              </Form>
            ) : null}
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={expenses}
            onChange={handleTableChange}
            pagination={{
              current: +query.get('page') || 1,
              total: (pagination && pagination.total) || 0,
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <CreateExpenseModal visible={addModalVisible} onCreate={onCreate} onCancel={() => setAddModalVisible(false)} />
      <UpdateExpenseModal
        visible={editModalVisible}
        onCreate={onEdit}
        onCancel={() => {
          setSelectedExpense(null);
          setEditModalVisible(false);
        }}
        data={selectedExpense}
      />
    </>
  );
};

const mapStateToProps = ({ expense }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    query,
    expenses: expense.expenses,
    pagination: expense.pagination,
  };
};

export default connect(mapStateToProps)(ExpensePage);
