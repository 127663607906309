/* eslint-disable import/prefer-default-export */
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.TAGS_LOADING,
});

export const fetchTags = () => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get('/tags');

    if (res.success) {
      dispatch({
        type: constant.TAGS_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};
