import * as constant from './constants';

export const initialState = {
  loading: false,
  batches: [],
  batch: null,
  expenseHistory: [],
  expenseHistoryPagination: null,
  feeHistory: [],
  feeHistoryPagination: null,
  returnHistory: [],
  returnHistoryPagination: null,
  expenseCategories: [],
  pagination: null,
  fabrics: [],
  fabricsPagination: null,
};

const batchReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.BATCH_LOADING:
      return {
        ...state,
        loading: action.loading,
        batches: [],
        batch: null,
        pagination: null,
      };
    case constant.BATCHES_FULFILLED:
      return {
        ...state,
        loading: false,
        batches: action.payload,
        pagination: action.pagination,
      };
    case constant.BATCH_FULFILLED:
      return {
        ...state,
        loading: false,
        batch: action.payload,
      };
    case constant.BATCH_EXPENSE_HISTORY_FULFILLED:
      return {
        ...state,
        loading: false,
        expenseHistory: action.payload,
        expenseHistoryPagination: action.pagination,
      };
    case constant.BATCH_RETURN_HISTORY_FULFILLED:
      return {
        ...state,
        loading: false,
        returnHistory: action.payload,
        returnHistoryPagination: action.pagination,
      };
    case constant.BATCH_FEE_HISTORY_FULFILLED:
      return {
        ...state,
        loading: false,
        feeHistory: action.payload,
        feeHistoryPagination: action.pagination,
      };
    case constant.BATCH_EXPENSE_CATEGORY_FULFILLED:
      return {
        ...state,
        loading: false,
        expenseCategories: action.payload,
      };
    default:
      return state;
  }
};

export default batchReducer;
