/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { Row, Col, Table, Space, Button, Modal, Form, Card, Input, Select, Checkbox } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import { actions } from '../../../modules/reducers';

import api from '../../../utils/api';
import { getImage } from '../../../utils/image';
import { countQuantity } from '../../../utils/number';

const { confirm } = Modal;
const { Option } = Select;

const PosProductPage = ({ dispatch, products, pagination, history, query, sum, sumQty }) => {
  const page = query.get('page');
  const name = query.get('name');
  const categoryId = query.get('category_id');

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(
      actions.fetchPOSProducts({
        page: page || 1,
        name: name || '',
        category_id: categoryId || '',
      })
    );

    dispatch(
      actions.fetchCategories({
        page: page || 1,
      })
    );

    dispatch(actions.fetchTags());

    dispatch(actions.fetchPOSProductSum({ category_id: categoryId || '' }));
    dispatch(actions.fetchPOSProductQty({ category_id: categoryId || '' }));
  }, [dispatch, page, name, categoryId]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [ShowFilter, setShowFilter] = useState(false);
  const [categories, setCategories] = useState([]);

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        const name = get(record, 'detail.name', '');
        const sku = get(record, 'detail.sku', '');
        const categoryName = get(record, 'detail.category.name', '');
        const viewCount = get(record, 'detail.view_count', 0);

        return (
          <>
            <h3>{name}</h3>
            <p style={{ fontSize: 12 }}>SKU: {sku}</p>
            <p style={{ fontSize: 12 }}>Model: {categoryName}</p>
            <Space>
              <span style={{ fontSize: 11 }}>Dilihat: {viewCount}</span>
            </Space>
          </>
        );
      },
    },
    {
      title: 'Gambar',
      dataIndex: 'pictures',
      key: 'pictures',
      render: (text, record) => {
        const picturesRaw = get(record, 'detail.pictures', '');

        if (picturesRaw.length === 0) {
          return null;
        }

        const pictures = JSON.parse(picturesRaw);

        return (
          <Space>
            {pictures.map((picture, idx) => (
              <div key={idx} style={{ maxWidth: 90, overflow: 'hidden', borderRadius: 4 }}>
                <img src={getImage(picture, 100)} alt="slider" style={{ width: '100%', height: '100%' }} />
              </div>
            ))}
          </Space>
        );
      },
    },
    {
      title: 'Stock',
      dataIndex: 'stock_qty',
      key: 'stock_qty',
      render: (text) => (text ? <span>{countQuantity(text)}</span> : 'Kosong'),
    },
    {
      title: 'Harga',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => {
        const onSale = get(record, 'detail.on_sale', false);
        const price = get(record, 'detail.price', 0);
        const salePrice = get(record, 'detail.sale_price', 0);
        const priceAfterDiscount = price - (salePrice / 100) * price;

        if (onSale) {
          return (
            <>
              <span style={{ textDecoration: 'line-through' }}>{`Rp. ${price.toLocaleString('ID')} `}</span>
              <span style={{ color: 'red' }}>{`Rp. ${priceAfterDiscount.toLocaleString('ID')}`}</span>
            </>
          );
        }

        return `Rp. ${price.toLocaleString('ID')}`;
      },
    },
    {
      title: 'Diskon',
      dataIndex: 'sale_price',
      key: 'sale_price',
      render: (_, record) => {
        const salePrice = get(record, 'detail.sale_price', 0);

        return <span>{salePrice}%.</span>;
      },
    },
    {
      title: 'Total Stok Rupiah',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => {
        const price = record.detail.on_sale
          ? record.detail.price - (record.detail.sale_price / 100) * record.detail.price
          : record.detail.price;
        const stockInRp = price * record.stock_qty;
        return (
          <span style={{ color: stockInRp < 0 ? 'red' : 'black' }}>{`Rp. ${stockInRp.toLocaleString('ID')}`}</span>
        );
      },
    },
    {
      title: 'Terakhir Diupdate',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (value) => (value ? format(new Date(value), 'dd MMM yyyy HH:mm') : '-'),
    },
  ];

  const onSelectRow = (_, rows) => {
    setSelectedRowKeys(rows.map((r) => r.id));
  };

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/pos-products?${query.toString()}`);
  };

  const handleFilter = (value) => {
    Object.keys(value).forEach((param) => {
      if (param === 'category_id') {
        if (value[param] && value[param].value > 0) {
          query.set(param, value[param].value);
        }
      } else if (value[param]) {
        query.set(param, value[param]);
      }
    });
    query.set('page', 1);
    history.push('/pos-products?' + query.toString());
  };

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/category?name=${value}`);
    setCategories(res.data);
  }, 500);

  return (
    <>
      <Row>
        <Col span={24}>
          <h2>Daftar Item di Toko</h2>
        </Col>
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button
              type="danger"
              style={{ marginRight: 10 }}
              disabled={selectedRowKeys.length === 0}
              onClick={() =>
                confirm({
                  title: 'Hapus Motif',
                  content: 'Anda yakin ingin menghapus Motif ini?',
                  onOk: () => {
                    setSelectedRowKeys([]);
                    dispatch(actions.deletePOSProduct(selectedRowKeys));
                  },
                })
              }
            >
              Hapus
            </Button>
            <Button type="primary" style={{ marginLeft: 15 }} onClick={() => setShowFilter(!ShowFilter)}>
              <FilterOutlined /> {ShowFilter ? 'Sembunyikan Filter' : 'Filter'}
            </Button>
          </div>
          <div style={{ marginBottom: 16 }}>
            {ShowFilter ? (
              <Form form={form} layout="horizontal" onFinish={handleFilter}>
                <Card title="Filter berdasarkan">
                  <Col span={8}>
                    <Form.Item name="name">
                      <Input placeholder="Nama" />
                    </Form.Item>
                    <Form.Item name="category_id">
                      <Select
                        labelInValue
                        showSearch
                        placeholder="Ketik Nama Model"
                        onSearch={onSearch}
                        filterOption={false}
                      >
                        {categories.map((category) => (
                          <Option key={category.id} value={category.id}>
                            {category.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="is_barang_sortir" valuePropName="checked">
                      <Checkbox>Barang Sortir</Checkbox>
                    </Form.Item>
                  </Col>
                  <>
                    <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                      Terapkan Filter
                    </Button>
                    <Button
                      type="primary"
                      htmlType="reset"
                      onClick={(e) => {
                        e.preventDefault();
                        form.resetFields();
                        history.push('/pos-products');
                      }}
                      danger
                    >
                      Reset Filter
                    </Button>
                  </>
                </Card>
              </Form>
            ) : null}
          </div>
          <Card>
            <p style={{ textAlign: 'right' }}>
              <strong>
                Total: {countQuantity(sumQty)} (Rp. {`${sum.toLocaleString('ID')}`})
              </strong>
            </p>
            <Table
              rowKey="id"
              rowSelection={{
                selectedRowKeys,
                onChange: onSelectRow,
              }}
              columns={columns}
              dataSource={products}
              onChange={handleTableChange}
              onRow={(record) => ({
                onClick: () => {
                  history.push(`/pos-products/${record.id}`);
                },
              })}
              pagination={{
                current: parseInt(page) || 1,
                total: (pagination && pagination.total) || 0,
                showSizeChanger: false,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ posProduct }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    sum: posProduct.sum,
    sumQty: posProduct.sumQty,
    products: posProduct.products,
    loading: posProduct.loading,
    pagination: posProduct.pagination,
    query,
  };
};

export default connect(mapStateToProps)(PosProductPage);
