import omit from 'lodash/omit';

import * as constant from './constants';

export const initialState = {
  // product list
  loading: false,
  products: [],
  pagination: null,
  sum: 0,
  sumQty: 0,

  // product detail
  productMeta: null,
  product: null,
  stockAudit: [],
  stockAuditPagination: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.POS_PRODUCT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case constant.POS_PRODUCTS_FULFILLED:
      return {
        ...state,
        loading: false,
        products: action.payload,
        pagination: action.pagination,
      };
    case constant.POS_PRODUCT_FULFILLED:
      const product = action.payload.detail;
      const productMeta = omit(action.payload, ['detail']);

      return {
        ...state,
        loading: false,
        product,
        productMeta,
      };
    case constant.POS_PRODUCT_STOCK_AUDIT_FULFILLED:
      return {
        ...state,
        loading: false,
        stockAudit: action.payload,
        stockAuditPagination: action.pagination,
      };

    case constant.POS_PRODUCT_TOTAL_SUM_FULFILLED:
      return {
        ...state,
        loading: false,
        sum: action.payload,
      };

    case constant.POS_PRODUCT_TOTAL_QTY_FULFILLED:
      return {
        ...state,
        loading: false,
        sumQty: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
