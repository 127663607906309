import * as constant from './constants';

export const initialState = {
  loading: false,
  tags: [],
};

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.TAGS_LOADING:
      return {
        ...state,
        loading: action.loading,
        tags: [],
      };
    case constant.TAGS_FULFILLED:
      return {
        ...state,
        loading: false,
        tags: action.payload,
      };
    default:
      return state;
  }
};

export default tagsReducer;
