/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import format from 'date-fns/format';
import {
  Row,
  Col,
  Table,
  PageHeader,
  Button,
  Form,
  Card,
  Select,
  Switch,
  Tooltip,
  Space,
  Modal,
} from 'antd';

import { FilterOutlined } from '@ant-design/icons';
import { actions } from '../../modules/reducers';

import api from '../../utils/api';
import { countQuantity } from '../../utils/number';
import { getImage } from '../../utils/image';

const { Option } = Select;
const { confirm } = Modal;

const Carts = ({
  dispatch, carts, pagination, history, query,
}) => {
  const page = query.get('page');
  const userId = query.get('user');
  const isPrepared = query.get('is_prepared');
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(
      actions.fetchCarts({
        page: page || 1,
        user: userId || '',
        is_prepared: isPrepared,
      }),
    );
  }, [dispatch, page, userId, isPrepared]);

  const [ShowFilter, setShowFilter] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [users, setUsers] = useState([]);

  const togglePrepare = (id, prepare) => {
    query.delete('page');
    query.delete('user');

    const data = {
      is_prepared: !prepare ? '1' : '0',
    };

    dispatch(actions.togglePrepareCart(data, id));
    history.push('/carts');
  };

  const columns = [
    {
      title: 'Nama Pelanggan',
      dataIndex: 'user',
      key: 'iser',
      render: (user) => <p>{user.name}</p>,
    },
    {
      title: 'Nama Produk',
      dataIndex: 'product',
      key: 'product',
      render: (product) => <p>{product.name}</p>,
    },
    {
      title: 'Gambar',
      dataIndex: 'product_pictures',
      key: 'product_pictures',
      render: (text, record) => {
        const pictures = JSON.parse(record.product.pictures);

        return (
          <Space>
            {pictures.map((picture, idx) => (
              <div
                key={idx}
                style={{ maxWidth: 90, overflow: 'hidden', borderRadius: 4 }}
              >
                <img
                  src={getImage(picture, 100, '3:4')}
                  alt="slider"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            ))}
          </Space>
        );
      },
    },
    {
      title: 'Banyak pesanan',
      dataIndex: 'qty',
      key: 'qty',
      render: (qty) => <p>{countQuantity(qty)}</p>,
    },
    {
      title: 'Disiapkan',
      dataIndex: 'is_prepared',
      key: 'is_prepared',
      render: (_, record) => (
        <Switch
          defaultChecked={record.is_prepared}
          onChange={() => togglePrepare(record.id, record.is_prepared)}
        />
      ),
    },
    {
      title: 'Tanggal',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => (value ? format(new Date(value), 'dd MMM yyyy HH:mm') : '-'),
    },
  ];

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/carts?${query.toString()}`);
  };

  const handleFilter = ({ customer, is_prepared }) => {
    if (customer) {
      query.set('user', customer.value);
    }

    if (is_prepared !== null) {
      query.set('is_prepared', is_prepared);
    } else {
      query.delete('is_prepared');
    }

    history.push(`/carts?${query.toString()}`);
  };

  const handleReset = () => {
    form.resetFields();
    query.delete('page');
    query.delete('is_prepared');
    query.delete('user');
    history.push('/carts');
  };

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/user?name=${value || ''}`);
    setUsers(res.data);
  }, 500);

  const onSelectRow = (_, rows) => {
    setSelectedRowKeys(rows.map((r) => r.id));
  };

  return (
    <Row>
      <Col span={24} style={{ marginBottom: 16 }}>
        <PageHeader title="Daftar Pesanan" style={{ padding: '0 0 16px' }} />
        <Button
          type="danger"
          style={{ marginRight: 10 }}
          disabled={selectedRowKeys.length === 0}
          onClick={() => confirm({
            title: 'Hapus Motif',
            content: 'Anda yakin ingin menghapus Motif ini?',
            onOk: () => {
              setSelectedRowKeys([]);
              dispatch(actions.deleteCarts(selectedRowKeys));
            },
          })}
        >
          Hapus
        </Button>
        <Button type="primary" onClick={() => setShowFilter(!ShowFilter)}>
          <FilterOutlined />
          {' '}
          {ShowFilter ? 'Sembunyikan Filter' : 'Filter'}
        </Button>
        <Tooltip
          title={
            !userId
              ? 'Untuk mulai mencetak, mohon filter user terlebih dahulu'
              : ''
          }
          placement="right"
        >
          <Button
            type="primary"
            target="_blank"
            href={`/carts/print?${query.toString()}`}
            style={{ marginLeft: 16 }}
            disabled={userId === null || carts.length === 0}
          >
            Print Order Check List
          </Button>
        </Tooltip>
      </Col>
      {ShowFilter ? (
        <Col span={24} style={{ marginBottom: 16 }}>
          <Form
            layout="vertical"
            onFinish={handleFilter}
            onReset={handleReset}
            initialValues={{
              is_prepared: isPrepared,
            }}
          >
            <Card title="Filter berdasarkan">
              <Row>
                <Col span={5}>
                  <Form.Item name="customer">
                    <Select
                      labelInValue
                      showSearch
                      placeholder="Ketik Nama Pelanggan"
                      onSearch={onSearch}
                      filterOption={false}
                    >
                      {users.map((category) => (
                        <Option key={category.id} value={category.id}>
                          {category.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="is_prepared">
                    <Select>
                      <Option value={null}>Semua</Option>
                      <Option value={1}>Sudah Disiapkan</Option>
                      <Option value={0}>Belum Disiapkan</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 8 }}
                >
                  Terapkan Filter
                </Button>
                <Button type="primary" htmlType="reset" danger>
                  Reset Filter
                </Button>
              </>
            </Card>
          </Form>
        </Col>
      ) : null}
      <Col span={24}>
        <Table
          rowKey="id"
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectRow,
          }}
          columns={columns}
          dataSource={carts}
          onChange={handleTableChange}
          pagination={{
            current: parseInt(page) || 1,
            total: (pagination && pagination.total) || 0,
            showSizeChanger: false,
          }}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ cart }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    pagination: cart.pagination,
    carts: cart.carts,
    query,
  };
};

export default connect(mapStateToProps)(Carts);
