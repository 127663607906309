import * as constant from './constants';

export const initialState = {
  loading: false,
  tailors: [],
  tailor: null,
  pagination: null,
};

const tailorReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.TAILOR_LOADING:
      return {
        ...state,
        loading: action.loading,
        tailors: [],
        tailor: null,
        pagination: null,
      };
    case constant.TAILORS_FULFILLED:
      return {
        ...state,
        loading: false,
        tailors: action.payload,
        pagination: action.pagination,
      };
    case constant.TAILOR_FULFILLED:
      return {
        ...state,
        loading: false,
        tailor: action.payload,
      };
    default:
      return state;
  }
};

export default tailorReducer;
