export const mapBatchStatusString = {
  0: 'Selesai',
  1: 'Diproses',
  2: 'Draft',
};

export const mapBatchStatus = {
  0: 'FINISHED',
  1: 'INPROGRESS',
  2: 'DRAFT',
};

export const batchStatusColorMapper = {
  0: 'success',
  1: 'warning',
  2: 'default',
};