import React, { useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Select,
} from 'antd';
import moment from 'moment';
import { debounce } from 'lodash';
import api from '../../../utils/api';

const { Option } = Select;

const UpdateExpenseModal = ({
  visible, onCreate, onCancel, data,
}) => {
  const [form] = Form.useForm();

  const [expenseCategories, setExpenseCategories] = useState([]);
  const [expenseCategory, setExpenseCategory] = useState(null);
  const [tailors, setTailors] = useState([]);
  const [tailor, setTailor] = useState(null);
  const [batch, setBatch] = useState(null);

  useEffect(() => {
    if (data) {
      setExpenseCategory({
        key: data?.production_expense_category?.id,
        label: data?.production_expense_category?.name,
        value: data?.production_expense_category?.id,
      });

      setTailor({
        key: data?.production_tailor?.id,
        label: data?.production_tailor?.name,
        value: data?.production_tailor?.id,
      });

      setBatch({
        key: data?.production_batch_id,
        label: data?.production_batch_id,
        value: data?.production_batch_id,
      });
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      const { data: res } = await api.get('/production/expense-categories');
      setExpenseCategories(res.data);
    })();
  }, []);

  const onTailorSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/tailors?name=${value || ''}`);
    setTailors(res.data);
  }, 500);

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Edit Potongan"
      okText="Update"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            delete values.batch;
            delete values.expense_category;
            delete values.tailor;

            values.production_expense_category_id = expenseCategory.value;
            values.production_tailor_id = tailor.value;
            values.production_batch_id = batch.value ? batch.value : null;
            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          ...data,
          date: data?.date ? moment(data.date) : null,
          expense_category: {
            key: data?.production_expense_category?.id,
            label: data?.production_expense_category?.name,
            value: data?.production_expense_category?.id,
          },
          tailor: {
            key: data?.production_tailor?.id,
            label: data?.production_tailor?.name,
            value: data?.production_tailor?.id,
          },
          batch: {
            key: data?.production_batch_id,
            label: data?.production_batch_id,
            value: data?.production_batch_id,
          },
        }}
      >
        <Form.Item name="tailor" label="Penjahit">
          <Select
            labelInValue
            showSearch
            placeholder="Ketik Nama Penjahit"
            onSearch={onTailorSearch}
            filterOption={false}
            onSelect={(value) => setTailor(value)}
          >
            {tailors.map((tailor) => (
              <Option key={tailor.id} value={tailor.id}>
                {tailor.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="expense_category" label="Kategori">
          <Select
            labelInValue
            showSearch
            placeholder="Ketik Kategori"
            filterOption={false}
            onSelect={(value) => setExpenseCategory(value)}
          >
            {expenseCategories.map((category) => (
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="description"
          label="Deskripsi"
        >
          <Input placeholder="Deskripsi" />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Jumlah"
        >
          <InputNumber min={0} placeholder="Jumlah" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="date"
          label="Tanggal"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal potongan!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateExpenseModal;
