export const bankColumns = [
  {
    title: 'Nama Bank',
    dataIndex: 'bank_name',
    key: 'bank_name',
  },
  {
    title: 'Nama Pemilik Rekening Bank',
    dataIndex: 'account_name',
    key: 'account_name',
  },
  {
    title: 'Nomor Rekening Bank',
    dataIndex: 'value',
    key: 'value',
  },
];

export const courierColumns = [
  {
    title: 'Nama Ekspedisi',
    dataIndex: 'name',
    key: 'name',
  },
];

