import * as constant from './constants';

export const initialState = {
  loading: false,
  fabrics: [],
  fabric: null,
  pagination: null,
};

const fabricReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.FABRIC_LOADING:
      return {
        ...state,
        loading: action.loading,
        fabrics: [],
        fabric: null,
        pagination: null,
      };
    case constant.FABRICS_FULFILLED:
      return {
        ...state,
        loading: false,
        fabrics: action.payload,
        pagination: action.pagination,
      };
    case constant.FABRIC_FULFILLED:
      return {
        ...state,
        loading: false,
        fabric: action.payload,
      };
    default:
      return state;
  }
};

export default fabricReducer;
