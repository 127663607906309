import React, { useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
} from 'antd';
import { mapBatchStatus } from '../../utils';

const { TextArea } = Input;
const { Option } = Select;

const StatusUpdateModal = ({
  visible, onComplete, onCancel, batch,
}) => {
  const [form] = Form.useForm();

  const [selectedStatus, setSelectedStatus] = useState(mapBatchStatus[batch?.status]);

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Update Status Seri"
      okText="Simpan"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onComplete(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          status: mapBatchStatus[batch?.status],
          notes: batch?.notes,
        }}
      >
        <Form.Item
          name="status"
          label="Status"
          rules={[
            {
              required: true,
              message: 'Mohon pilih salah satu status',
            },
          ]}
        >
          <Select onChange={(value) => setSelectedStatus(value)} disabled={mapBatchStatus[batch?.status] === 'FINISHED'}>
            <Option value="INPROGRESS">Diproses</Option>
            <Option value="FINISHED">Selesai</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="notes"
          label="Catatan"
          rules={[
            { required: selectedStatus === 'FINISHED', message: 'Mohon masukkan Catatan Seri' },
          ]}
        >
          <TextArea rows={4} placeholder="Catatan" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StatusUpdateModal;
