import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  PageHeader,
  Row,
  Table,
  Modal,
  Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../modules/reducers';
import ModelModal from '../components/modal';

const { confirm } = Modal;

const ModelPage = ({
  dispatch,
  models,
  query,
  pagination,
  history,
}) => {
  const page = query.get('page') || 1;

  const [addModalVisible, setAddModalVisible] = useState(false);

  const [editModel, setEditModel] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchModels({ page }));
  }, [dispatch, page]);

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/production/model?${query.toString()}`);
  };

  const handleEditClicked = (model) => {
    setEditModel(model);
    setEditModalVisible(true);
  };

  const handleDeleteClicked = (model) => (
    confirm({
      title: 'Hapus Model',
      content: 'Anda yakin ingin menghapus Model ini?',
      onOk: () => {
        dispatch(actions.deleteModel(model));
      },
    })
  );

  const onCreate = (values) => {
    dispatch(actions.createModel({ ...values }));
    setAddModalVisible(false);
  };

  const onEdit = (values) => {
    dispatch(actions.updateModel(values, editModel, { history }));
    setEditModalVisible(false);
    setEditModel(null);
  };

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => record?.model?.name,
    },
    {
      title: 'Harga Potong',
      dataIndex: 'cutting_price',
      key: 'cutting_price',
      render: (_, record) => `Rp. ${record.cutting_price?.toLocaleString('ID')}/lusin`,
    },
    {
      title: 'Harga Jahit',
      dataIndex: 'sewing_price',
      key: 'sewing_price',
      render: (_, record) => `Rp. ${record.sewing_price?.toLocaleString('ID')}/lusin`,
    },
    {
      title: 'Harga Aksesoris',
      dataIndex: 'accessories_price',
      key: 'accessories_price',
      render: (_, record) => `Rp. ${record.accessories_price?.toLocaleString('ID')}/lusin`,
    },
    {
      title: 'Harga Lain-lain',
      dataIndex: 'other_price',
      key: 'other_price',
      render: (_, record) => `Rp. ${record.other_price?.toLocaleString('ID')}/lusin`,
    },
    {
      title: 'Harga Jual',
      dataIndex: 'main_price',
      key: 'main_price',
      render: (_, record) => `Rp. ${record.main_price?.toLocaleString('ID')}/pcs`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEditClicked(record)}>
            Edit
          </Button>
          <Button type="danger" onClick={() => handleDeleteClicked(record)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <PageHeader title="Daftar Model" style={{ padding: '0 0 16px' }} />
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => setAddModalVisible(true)}>
              <PlusOutlined />
              Tambah
            </Button>
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={models}
            onChange={handleTableChange}
            pagination={{
              current: +query.get('page') || 1,
              total: (pagination && pagination.total) || 0,
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <ModelModal
        visible={addModalVisible}
        onCreate={onCreate}
        onCancel={() => setAddModalVisible(false)}
      />
      <ModelModal
        visible={editModalVisible}
        onCreate={onEdit}
        onCancel={() => {
          setEditModel(null);
          setEditModalVisible(false);
        }}
        data={editModel}
      />
    </>
  );
};

const mapStateToProps = ({ model }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    query,
    models: model.models,
    pagination: model.pagination,
  };
};

export default connect(mapStateToProps)(ModelPage);
