import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';
import {
  PDFViewer,
  Page,
  Text,
  View,
  StyleSheet,
  Document,
} from '@react-pdf/renderer';
import format from 'date-fns/format';

import { actions } from '../../../modules/reducers';
import { countQuantity } from '../../../utils/number';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100vh',
    margin: -24,
    padding: 0,
    position: 'fixed',
    border: 'none',
  },
  header: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 16,
    fontSize: 9,
  },
  tableHead: {
    marginVertical: 4,
    borderTop: 1,
    borderBottom: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 16,
    fontSize: 9,
  },
  tableRow: {
    padding: '0 16pt',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    fontSize: 9,
    marginBottom: 3,
    paddingBottom: 2,
    borderBottom: 1,
    borderBottomColor: '#000',
  },
  tableCell: {
    width: '20%',
    fontSize: 9,
  },
});

const tableWidthMapper = {
  NO: '5%',
  'NAMA BARANG': '15%',
  BANYAK: '15%',
  STATUS: '15%',
  CATATAN: '30%',
};

const CartCheckList = ({ dispatch, carts, query }) => {
  const userId = query.get('user');
  const isPrepared = query.get('is_prepared');

  useEffect(() => {
    dispatch(
      actions.fetchCarts({
        page: 1,
        user: userId || '',
        is_prepared: isPrepared,
        limit: 1000,
      }),
    );
  }, [dispatch, userId, isPrepared]);

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4">
          {carts.length !== 0 && (
            <View style={styles.header}>
              <View>
                <Text>Tanggal: {format(new Date(), 'dd/MM/yyyy')}</Text>
                <Text style={{ marginBottom: 2 }}>
                  Customer: {carts[0].user.name}
                </Text>
              </View>
            </View>
          )}
          <View style={styles.tableHead}>
            {['NO', 'NAMA BARANG', 'BANYAK', 'STATUS', 'CATATAN'].map((x) => (
              <View
                key={x}
                style={[
                  styles.tableCell,
                  {
                    width: tableWidthMapper[x],
                    textAlign: x === 'CATATAN' ? 'center' : 'left',
                  },
                ]}
              >
                <Text>{x}</Text>
              </View>
            ))}
          </View>
          {carts.length !== 0 &&
            chunk(
              carts
                .sort((a, b) => {
                  if (
                    a.product.name.toLowerCase() < b.product.name.toLowerCase()
                  ) {
                    return -1;
                  }
                  if (
                    a.product.name.toLowerCase() > b.product.name.toLowerCase()
                  ) {
                    return 1;
                  }
                  return 0;
                })
                .map((x, i) => ({
                  ...x,
                  no: i + 1,
                })),
              50,
            ).map((cart) =>
              cart.map((item) => (
                <View key={item.id}>
                  <View style={styles.tableRow}>
                    <View
                      style={[styles.tableCell, { width: tableWidthMapper.NO }]}
                    >
                      <Text>{item.no}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper['NAMA BARANG'] },
                      ]}
                    >
                      <Text>{item.product.name}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper.BANYAK },
                      ]}
                    >
                      <Text>
                        {countQuantity(item.qty)}({item.qty} pcs)
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper.STATUS },
                      ]}
                    >
                      <View
                        style={{
                          width: 10,
                          height: 10,
                          border: 1,
                          marginLeft: 12,
                        }}
                      />
                    </View>
                  </View>
                </View>
              )),
            )}
        </Page>
      </Document>
    </PDFViewer>
  );
};

const mapStateToProps = ({ cart, user }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    carts: cart.carts,
    user: user.user,
    query,
  };
};

export default connect(mapStateToProps)(CartCheckList);
