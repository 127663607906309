import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.COURIER_LOADING,
});

export const fetchCouriers = () => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get('/courier');

    if (res.success) {
      dispatch({
        type: constant.COURIERS_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createCourier = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/courier', data);

    if (res.success) {
      message.success('Berhasil menambahkan kurir');
      dispatch(fetchCouriers());
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchCourier = (courierID) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/courier/${courierID}`);

    if (res.success) {
      dispatch({
        type: constant.COURIER_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteCourier = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete('/courier', {
      data: {
        ids: data,
      },
    });

    if (res.success) {
      message.success('Berhasil menghapus kurir');
      dispatch(fetchCouriers());
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateCourier = (formData, courier, { history }) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/courier/${courier.id}`, formData);

    if (res.success) {
      message.success('Berhasil mengubah kurir');
      dispatch(fetchCouriers());
      history.push('/options');
    }
  } catch (err) {
    console.error(err);
  }
};
