import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';
import {
  PDFViewer,
  Page,
  Text,
  View,
  StyleSheet,
  Document,
} from '@react-pdf/renderer';
import format from 'date-fns/format';

import { actions } from '../../../modules/reducers';
import { countQuantity } from '../../../utils/number';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100vh',
    margin: -24,
    padding: 0,
    position: 'fixed',
    border: 'none',
  },
  header: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 16,
    fontSize: 9,
  },
  tableHead: {
    marginVertical: 4,
    borderTop: 1,
    borderBottom: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 16,
    fontSize: 9,
  },
  tableRow: {
    padding: '0 16pt',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    fontSize: 9,
    marginBottom: 3,
    paddingBottom: 2,
    borderBottom: 1,
    borderBottomColor: '#000',
  },
  tableCell: {
    width: '20%',
    fontSize: 9,
  },
});

const tableWidthMapper = {
  NO: '5%',
  'NAMA BARANG': '30%',
  BANYAK: '20%',
  STATUS: '15%',
  CATATAN: '30%',
};

const CheckList = ({ dispatch, match, order, user }) => {
  const {
    params: { id },
  } = match;

  useEffect(() => {
    dispatch(actions.fetchOrder(id));
  }, [dispatch, id]);

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        {order &&
          chunk(
            [...order.items]
              .sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              })
              .map((x, i) => ({
                ...x,
                no: i + 1,
              })),
            50,
          ).map((items, idx) => (
            <Page size="A4" key={idx}>
              <View style={styles.header}>
                <View>
                  <Text style={{ marginBottom: 2 }}>
                    No: {order.invoice_no}
                  </Text>
                  <Text style={{ marginBottom: 2 }}>
                    Customer: {order.customer_name}
                  </Text>
                </View>
                <View>
                  <Text>
                    Tanggal: {format(new Date(order.created_at), 'dd/MM/yyyy')}
                  </Text>
                </View>
              </View>
              <View style={styles.tableHead}>
                {['NO', 'NAMA BARANG', 'BANYAK', 'STATUS', 'CATATAN'].map(
                  (x) => (
                    <View
                      key={x}
                      style={[
                        styles.tableCell,
                        {
                          width: tableWidthMapper[x],
                          textAlign: x === 'CATATAN' ? 'center' : 'left',
                        },
                      ]}
                    >
                      <Text>{x}</Text>
                    </View>
                  ),
                )}
              </View>
              <View>
                {items.map((item, idx) => (
                  <View key={idx} style={styles.tableRow}>
                    <View
                      style={[styles.tableCell, { width: tableWidthMapper.NO }]}
                    >
                      <Text>{item.no}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper['NAMA BARANG'] },
                      ]}
                    >
                      <Text>{item.name}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper.BANYAK },
                      ]}
                    >
                      <Text>
                        {countQuantity(item.quantity)}({item.quantity} pcs)
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper.STATUS },
                      ]}
                    >
                      <View
                        style={{
                          width: 10,
                          height: 10,
                          border: 1,
                          marginLeft: 12,
                        }}
                      />
                    </View>
                  </View>
                ))}
              </View>
            </Page>
          ))}
      </Document>
    </PDFViewer>
  );
};

const mapStateToProps = ({ order, user }) => ({
  order: order.order,
  user: user.user,
});

export default connect(mapStateToProps)(CheckList);
