import React from 'react';
import { Button, Modal } from "antd";

const { confirm } = Modal;

export const addressColumn = (onDeleteAddress) => [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Nama Penerima',
    dataIndex: 'receiver',
    key: 'receiver',
  },
  {
    title: 'Nomor Telepon',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Alamat',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Provinsi',
    dataIndex: 'province',
    key: 'province',
  },
  {
    title: 'Kabupaten / Kota',
    dataIndex: 'regency',
    key: 'regency',
  },
  {
    title: 'Kecamatan',
    dataIndex: 'district',
    key: 'district',
  },
  {
    title: 'Desa',
    dataIndex: 'village',
    key: 'village',
  },
  {
    title: 'Aksi',
    dataIndex: 'action',
    key: 'action',
    render: (_, record) => (
      <Button
        type="danger"
        onClick={() =>
          confirm({
            title: 'Hapus Alamat',
            content: 'Apakah anda yakin ingin menghapus alamat ini?',
            onOk: () => {
              onDeleteAddress(record.id)
            },
          })
        }
      >Hapus</Button>
    )
  }
];