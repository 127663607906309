import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Row, Col, Table, Space, Button, Switch,
} from 'antd';
import { PlusOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { actions } from '../../modules/reducers';

import Create from './components/Create';
import { getImage } from '../../utils/image';

const Slide = ({
  dispatch, sliders, history, pagination, query,
}) => {
  const page = query.get('page');

  useEffect(() => {
    dispatch(
      actions.fetchSliders({
        page: page || 1,
      }),
    );
  }, [dispatch, page]);

  const [visible, setVisible] = useState(false);
  const [editSlide, SetEditSlide] = useState(null);

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/slides?${query.toString()}`);
  };

  const onCreate = (values) => {
    dispatch(actions.createSlider(values));
    setVisible(false);
  };

  const deleteSlide = (data) => {
    dispatch(actions.deleteSlider(data));
  };

  const toggleActive = (id, active, src) => {
    const data = {
      src,
      is_active: !active ? '1' : '0',
    };

    dispatch(actions.toggleSlider(data, id));
  };

  const columns = [
    {
      title: 'Foto',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space size="middle">
          <div style={{ maxWidth: 120, overflow: 'hidden' }}>
            <img
              src={getImage(record.src, 200)}
              alt="slider"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </Space>
      ),
    },
    {
      title: 'Aktif',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (text) => <p>{text ? 'Aktif' : 'Tidak Aktif'}</p>,
    },
    {
      title: 'Aktif',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (_, record) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={record.is_active}
          onChange={() => toggleActive(record.id, record.is_active, record.src)}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" danger onClick={() => deleteSlide(record)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={22}>
          <h2>Daftar Slider</h2>
        </Col>
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => setVisible(true)}>
              <PlusOutlined />
              {' '}
              Tambah
            </Button>
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={sliders}
            onChange={handleTableChange}
            pagination={{
              current: parseInt(page) || 1,
              total: (pagination && pagination.total) || 0,
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <Create
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          SetEditSlide(null);
          setVisible(false);
        }}
        editSlide={editSlide}
      />
    </>
  );
};

const mapStateToProps = ({ slider }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    sliders: slider.sliders,
    pagination: slider.pagination,
    query,
  };
};

export default connect(mapStateToProps)(Slide);
