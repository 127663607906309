import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Row,
  Col,
  Table,
  Button,
  Modal,
  PageHeader,
  Card,
  Divider,
  InputNumber,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { actions } from '../../modules/reducers';
import BankAccountCreate from '../BankAccount/components/create';
import CourierCreate from './Courier/Create';
import { bankColumns, courierColumns } from './utils';

const { confirm } = Modal;

const Option = ({ dispatch, options, bankAccounts, history, couriers }) => {
  useEffect(() => {
    dispatch(actions.fetchOptions());
    dispatch(actions.fetchBankAccounts());
    dispatch(actions.fetchCouriers());
  }, [dispatch]);

  const [visible, setVisible] = useState(false);
  const [courierVisible, setCourierVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysCourier, setSelectedRowKeysCourier] = useState([]);

  const onFinish = (values) => {
    dispatch(actions.updateOptions(values));
  };

  const onSelectRow = (_, rows) => {
    setSelectedRowKeys(rows.map((r) => r.id));
  };

  const onSelectRowCourier = (_, rows) => {
    setSelectedRowKeysCourier(rows.map((r) => r.id));
  };

  const onBankAccountCreate = (values) => {
    dispatch(actions.CreateBankAccount(values));
    setVisible(false);
  };

  const onCourierCreate = (values) => {
    dispatch(actions.createCourier(values));
    setCourierVisible(false);
  };

  let initialValue;

  if (options.length !== 0) {
    let split;
    initialValue = options.map((option) => {
      split = {
        ...split,
        [option.key]: option.value,
      };

      return split;
    });
  }

  return (
    <Row gutter={16}>
      <Col span={24}>
        <PageHeader
          title="Pengaturan"
          subTitle="Pengaturan"
          style={{ padding: '16px 0' }}
        />
      </Col>
      <Col span={8}>
        <Card title="Pengaturan">
          {options.length !== 0 ? (
            <>
              <Form
                onFinish={onFinish}
                initialValues={initialValue[initialValue.length - 1]}
                layout="vertical"
              >
                <Form.Item
                  name="email_notification"
                  label="Notifikasi Email"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan Email untuk notifikasi',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="whatsapp_number"
                  label="Nomor Whatsapp"
                  rules={[
                    {
                      required: true,
                      message:
                        'Mohon masukkan nomor telepon yang terhubung dengan whatsapp',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Alamat Toko"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan Alamat toko anda.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="phone_number"
                  label="Nomor Telepon"
                  rules={[
                    {
                      required: true,
                      message:
                        'Mohon masukkan nomor telepon yang dapat dihubungi',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="office_phone"
                  label="Nomor Telepon Kantor"
                  rules={[
                    {
                      required: true,
                      message:
                        'Mohon masukkan nomor telepon kantor yang dapat dihubungi',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Simpan
                  </Button>
                </Form.Item>
              </Form>
              <Divider orientation="left">
                Loyalty Point
              </Divider>
              <Form
                onFinish={onFinish}
                initialValues={initialValue[initialValue.length - 1]}
                layout="vertical"
              >
                <Form.Item
                  name="point_currency"
                  label="Nilai Point dalam Rp"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan Nilai Point dalam Rp',
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
                <Form.Item
                  name="min_spend_to_earn_point"
                  label="Minimum Penukaran Point"
                  rules={[
                    {
                      required: true,
                      message:
                        'Mohon masukkan Minimum penukaran point',
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Simpan
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : null}
        </Card>
      </Col>
      <Col span={16}>
        <Card title="Manajemen Rekening Bank">
          <Button
            type="danger"
            disabled={selectedRowKeys.length === 0}
            style={{ marginRight: 16 }}
            onClick={() =>
              confirm({
                title: 'Hapus Akun Bank',
                content: 'Anda yakin ingin menghapus akun ini?',
                onOk: () => {
                  setSelectedRowKeys([]);
                  dispatch(actions.deleteBankAccount(selectedRowKeys));
                },
              })
            }
          >
            Hapus
          </Button>
          <Button
            type="primary"
            onClick={() => setVisible(true)}
            style={{ marginBottom: 16 }}
          >
            <PlusOutlined /> Tambah Akun Bank
          </Button>
          <Table
            rowKey="id"
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectRow,
            }}
            columns={bankColumns}
            dataSource={bankAccounts}
            onRow={(record) => ({
              onClick: () => {
                history.push(`/bank-accounts/${record.id}`);
              },
            })}
            pagination={false}
          />
        </Card>

        <Card title="Ekspedisi" style={{ marginTop: 32 }}>
          <Button
            type="danger"
            disabled={selectedRowKeysCourier.length === 0}
            style={{ marginRight: 16 }}
            onClick={() =>
              confirm({
                title: 'Hapus Ekspedisi',
                content: 'Anda yakin ingin menghapus ekspedisi ini?',
                onOk: () => {
                  setSelectedRowKeys([]);
                  dispatch(actions.deleteCourier(selectedRowKeysCourier));
                },
              })
            }
          >
            Hapus
          </Button>
          <Button
            type="primary"
            onClick={() => setCourierVisible(true)}
            style={{ marginBottom: 16 }}
          >
            <PlusOutlined /> Tambah Ekspedisi
          </Button>
          <Table
            rowKey="id"
            rowSelection={{
              selectedRowKeys: selectedRowKeysCourier,
              onChange: onSelectRowCourier,
            }}
            columns={courierColumns}
            dataSource={couriers}
            onRow={(record) => ({
              onClick: () => {
                history.push(`/courier/${record.id}`);
              },
            })}
            pagination={false}
          />
        </Card>
      </Col>
      <BankAccountCreate
        visible={visible}
        onCreate={onBankAccountCreate}
        onCancel={() => setVisible(false)}
      />

      <CourierCreate
        visible={courierVisible}
        onCreate={onCourierCreate}
        onCancel={() => setCourierVisible(false)}
      />
    </Row>
  );
};

const mapStateToProps = ({ option, bankAccount, courier }) => ({
  options: option.options,
  bankAccounts: bankAccount.bankAccounts,
  couriers: courier.couriers,
});

export default connect(mapStateToProps)(Option);
