import React from 'react';
import {
  Modal, Form, Input, InputNumber, Select,
} from 'antd';

const { Option } = Select;

const BankAccountCreate = ({
  visible, onCreate, onCancel, bankAccount,
}) => {
  const [form] = Form.useForm();

  const banks = [
    'BNI',
    'BCA',
    'Mandiri',
    'BRI',
    'CIMB Niaga',
    'Bukopin',
    'Jenius',
    'BTPN',
  ];

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Tambah Rekening Baru"
      okText="Simpan"
      cancelText="Batal"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={bankAccount}
      >
        <Form.Item
          name="bank_name"
          label="Nama Bank"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan Nama Bank',
            },
          ]}
        >
          <Select>
            {banks.map((bank) => (
              <Option value={bank} key={bank}>
                {bank}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="account_name"
          label="Nama Pemilik Rekening Bank"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan Nama Pemilik Rekening Bank',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="value"
          label="Nomor Rekening Bank"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan Nomor Rekening Bank',
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BankAccountCreate;
