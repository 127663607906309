import * as constant from './constants';

export const initialState = {
  loading: false,
  carts: [],
  cart: null,
  pagination: null,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.CART_LOADING:
      return {
        ...state,
        loading: action.loading,
        carts: [],
        cart: null,
        pagination: null,
      };
    case constant.CARTS_FULFILLED:
      return {
        ...state,
        loading: false,
        carts: action.payload,
        pagination: action.pagination,
      };
    case constant.CART_FULFILLED:
      return {
        ...state,
        loading: false,
        carts: action.payload,
        pagination: action.pagination,
      };
    default:
      return state;
  }
};

export default cartReducer;
