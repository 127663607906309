import React from 'react';
import {
  Modal,
  Form,
  DatePicker,
} from 'antd';
import moment from 'moment';

const UpdatePeriodModal = ({
  visible, onCreate, onCancel, data,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Edit Periode"
      okText="Update"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          ...data,
          start_at: data?.start_at ? moment(data.start_at) : null,
          end_at: data?.end_at ? moment(data.end_at) : null,
        }}
      >
        <Form.Item
          name="start_at"
          label="Tanggal Mulai Periode"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal mulai periode!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item
          name="end_at"
          label="Tanggal Selesai Periode"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal selesai periode!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdatePeriodModal;
