import * as constant from './constants';

export const initialState = {
  loading: false,
  couriers: [],
  courier: null,
};

const courierReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.COURIER_LOADING:
      return {
        ...state,
        loading: action.loading,
        couriers: [],
        courier: null,
      };
    case constant.COURIERS_FULFILLED:
      return {
        ...state,
        loading: false,
        couriers: action.payload,
      };
    case constant.COURIER_FULFILLED:
      return {
        ...state,
        loading: false,
        courier: action.payload,
      };
    default:
      return state;
  }
};

export default courierReducer;
