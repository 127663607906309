import React, { useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  message,
  Upload,
  Button,
  Switch,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ReactTags from 'react-tag-autocomplete';
import debounce from 'lodash/debounce';

import './react-tag.css';

import api from '../../../utils/api';
import { skuGenerator, dozenToPcs } from '../../../utils/number';

const { Option } = Select;

const ProductCreate = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  const [uploaded, setUploaded] = useState([]);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [batch_code, setBatchCode] = useState('');
  const [name, setName] = useState('');
  const [generatedSKU, setSKU] = useState('');
  const [dozen, setDozen] = useState(0);
  const [pcs, setPcs] = useState(0);
  const [stockQty, setStockQty] = useState(0);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);

  React.useEffect(() => {
    const SKU = skuGenerator(batch_code, name);
    setSKU(SKU);
  }, [batch_code, name]);

  React.useEffect(() => {
    setStockQty(dozenToPcs(dozen, pcs));
  }, [dozen, pcs]);

  const props = {
    multiple: true,
    listType: 'picture',
    customRequest: async (options) => {
      const data = new FormData();
      data.append('folder', 'products');
      data.append('images', options.file);

      try {
        const { data: res } = await api.post('/upload/v2', data);

        if (res.success) {
          options.onSuccess(res.data, options.file);
          const uploadResponse = res.data.map(({ secure_url }) => secure_url);
          setUploaded((upload) => [...upload, ...uploadResponse]);
        } else {
          options.onError(res.message);
        }
      } catch (err) {
        options.onError(err);
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} gambar berhasil diupload`);
      }
      if (info.file.status === 'error') {
        message.error(`${info.file.name} gambar gagal diupload`);
      }
    },
  };

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/category?name=${value || ''}`);
    setCategories(res.data);
  }, 500);

  const handleInputTag = debounce(async (query) => {
    const { data: res } = await api.get(`/tags?${query}`);

    if (res.data.length) {
      setTagOptions(res.data);
    }
  }, 500);

  const handleTagDelete = (i) => {
    const selectedTags = tags.slice(0);
    selectedTags.splice(i, 1);
    setTags(selectedTags);
  };

  const handleTagAddition = (tag) => {
    const newTag = [].concat(tags, tag);
    setTags(newTag);
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Tambah Produk Baru"
      okText="Simpan"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
        setUploaded([]);
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();

            delete values.category;
            values.pictures = uploaded;
            values.sku = generatedSKU;
            values.stock_qty = stockQty;
            values.category_id = category.value;
            values.tags = tags.map((tag) => tag.name);
            onCreate(values);
            setUploaded([]);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="pictures"
          label="Gambar Produk"
          rules={[
            {
              required: true,
              message: 'Mohon upload gambar produk!',
            },
          ]}
        >
          <Upload {...props}>
            <Button>
              <UploadOutlined /> Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="category"
          label="Model"
          rules={[
            {
              required: true,
              message: 'Mohon pilih salah satu model!',
            },
          ]}
        >
          <Select
            labelInValue
            showSearch
            placeholder="Ketik Nama Model"
            onSearch={onSearch}
            filterOption={false}
            onSelect={(value) => setCategory(value)}
          >
            {categories.map((category) => (
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="batch_code"
          label="Kode Penjahit/Produksi"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan kode penjahit atau/dan kode produksi!',
            },
          ]}
        >
          <Input onChange={(e) => setBatchCode(e.target.value)} />
        </Form.Item>
        <Form.Item
          name="name"
          label="Nama Produk"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan nama produk!',
            },
          ]}
        >
          <Input onChange={(e) => setName(e.target.value)} />
        </Form.Item>

        <Form.Item label="SKU (otomatis)">
          <Input
            value={generatedSKU}
            style={{ background: '#EEE' }}
            onChange={(e) => setSKU(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Stock Produk">
          <InputNumber
            readOnly
            style={{ background: '#EEE', width: 250 }}
            value={stockQty}
          />{' '}
          (total dalam pcs)
        </Form.Item>

        <Form.Item>
          <InputNumber
            min={0}
            value={dozen}
            onChange={(value) => {
              setDozen(value);
            }}
          />{' '}
          lusin
          <Select
            style={{ width: 120, marginLeft: 8 }}
            placeholder="pcs"
            onSelect={(value) => setPcs(value)}
            value={pcs}
          >
            {[0, 4, 8].map((pcs) => (
              <Option key={pcs} value={pcs}>
                {pcs}
              </Option>
            ))}
          </Select>{' '}
          pcs
        </Form.Item>
        <Form.Item
          name="price"
          label="Harga Produk"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan harga produk!',
            },
          ]}
        >
          <InputNumber
            defaultValue={0}
            onChange={(value) => {
              setPrice(value);
            }}
          />
        </Form.Item>
        <Form.Item name="sale_price" label="Persentase Diskon">
          <InputNumber
            defaultValue={0}
            onChange={(value) => {
              setDiscount(value);
            }}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
          />
        </Form.Item>
        <Form.Item label="Harga Diskon">
          <InputNumber
            disabled
            value={discount > 0 ? price - price * (discount / 100) : 0}
          />
        </Form.Item>
        <Form.Item name="on_sale" label="Aktifkan Diskon">
          <Switch />
        </Form.Item>
        <Form.Item name="tags" label="Tag">
          <ReactTags
            allowNew
            tags={tags}
            suggestions={tagOptions}
            onDelete={handleTagDelete}
            onAddition={handleTagAddition}
            onInput={handleInputTag}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProductCreate;
