import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';
import {
  PDFViewer,
  Page,
  Text,
  View,
  StyleSheet,
  Document,
} from '@react-pdf/renderer';
import format from 'date-fns/format';

import { actions } from '../../../modules/reducers';
import { countQuantity } from '../../../utils/number';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100vh',
    margin: -24,
    padding: 0,
    position: 'fixed',
    border: 'none',
  },
  header: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginVertical: 8,
    marginHorizontal: 16,
    fontSize: 11,
  },
  tableHead: {
    marginVertical: 4,
    borderTop: 1,
    borderBottom: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 16,
    fontSize: 11,
  },
  tableRow: {
    padding: '0 16pt',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    fontSize: 11,
  },
  tableCell: {
    width: '20%',
    fontSize: 11,
  },
  footer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginHorizontal: 16,
    marginTop: 'auto',
    fontSize: 11,
    borderTop: 1,
    paddingVertical: 8,
  },
});

const groupByCategory = (items) => {
  const categories = items.reduce((dict, item) => {
    const key = `${item.product.category_id}_${item.product.price}_${item.product.sale_price}`;
    const name =
      item.product.sale_price > 0
        ? item.product.category.name +
          ' (disc ' +
          item.product.sale_price +
          '%)'
        : item.product.category.name;
    if (dict.hasOwnProperty(key)) {
      dict[key].push({
        ...item,
        categoryName: name,
      });
    } else {
      dict[key] = [{ ...item, categoryName: name }];
    }

    return dict;
  }, {});

  const sumItemGroup = Object.keys(categories).map((catID) => {
    const subtotal = categories[catID].reduce((total, item) => {
      total += item.subtotal;
      return total;
    }, 0);

    const quantity = categories[catID].reduce((total, item) => {
      total += item.quantity;
      return total;
    }, 0);

    return {
      ...categories[catID][0],
      quantity,
      subtotal,
    };
  });

  return sumItemGroup.map((s, idx) => ({ ...s, no: idx + 1 }));
};

const tableWidthMapper = {
  NO: '10%',
  BANYAK: '20%',
  'NAMA BARANG': '30%',
  HARGA: '15%',
  JUMLAH: '25%',
};

const Invoice = ({ dispatch, match, order, options, bankAccounts }) => {
  const {
    params: { id },
  } = match;

  useEffect(() => {
    dispatch(actions.fetchOptions());
    dispatch(actions.fetchBankAccounts());
    dispatch(actions.fetchOrder(id));
  }, [dispatch, id]);

  let mappedOptions;

  if (options.length !== 0) {
    let split;
    mappedOptions = options.map((option) => {
      split = {
        ...split,
        [option.key]: option.value,
      };

      return split;
    });

    mappedOptions = mappedOptions[mappedOptions.length - 1];
  }

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        {order &&
          chunk(groupByCategory(order.items), 18).map((items, idx) => (
            <Page size="A5" key={idx} orientation="landscape">
              <View style={styles.header}>
                <View>
                  <Text style={{ marginBottom: 2 }}>HELLO Collection</Text>
                  <Text style={{ marginBottom: 2 }}>
                    PRODUCT PAKAIAN WANITA
                  </Text>
                  {mappedOptions && (
                    <>
                      <Text style={{ marginBottom: 2 }}>
                        {mappedOptions.address}
                      </Text>
                      <Text style={{ marginBottom: 2 }}>
                        Telp: {mappedOptions.office_phone}
                      </Text>
                      <Text style={{ marginBottom: 2 }}>
                        HP: {mappedOptions.office_phone} WA:{' '}
                        {mappedOptions.whatsapp_number}
                      </Text>
                    </>
                  )}
                </View>
                <View>
                  <Text style={{ marginBottom: 2 }}>
                    No: {order.invoice_no}
                  </Text>
                  <Text style={{ marginBottom: 2 }}>
                    Jakarta, {format(new Date(order.created_at), 'dd/MM/yyyy')}
                  </Text>
                  <Text style={{ marginBottom: 2 }}>
                    Kepada Yth: {order.customer_name}
                  </Text>
                </View>
              </View>
              <View style={styles.tableHead}>
                {['NO', 'BANYAK', 'NAMA BARANG', 'HARGA', 'JUMLAH'].map((x) => (
                  <View
                    key={x}
                    style={[
                      styles.tableCell,
                      {
                        width: tableWidthMapper[x],
                        textAlign: ['HARGA', 'JUMLAH'].includes(x)
                          ? 'right'
                          : 'left',
                      },
                    ]}
                  >
                    <Text>{x}</Text>
                  </View>
                ))}
              </View>
              <View>
                {items.map((item, idx) => (
                  <View key={idx} style={styles.tableRow}>
                    <View
                      style={[styles.tableCell, { width: tableWidthMapper.NO }]}
                    >
                      <Text>{item.no}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper.BANYAK },
                      ]}
                    >
                      <Text>{countQuantity(item.quantity)}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper['NAMA BARANG'] },
                      ]}
                    >
                      <Text>{item.categoryName}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper.HARGA },
                      ]}
                    >
                      <Text style={{ textAlign: 'right' }}>
                        {item.sale_price
                          ? item.sale_price.toLocaleString('id')
                          : item.price.toLocaleString('id')}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper.JUMLAH },
                      ]}
                    >
                      <Text style={{ textAlign: 'right' }}>
                        {Number(item.subtotal).toLocaleString('id')}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
              <View style={styles.footer}>
                <View>
                  {bankAccounts.length !== 0 ? (
                    <>
                      <Text>
                        {bankAccounts
                          .slice(0, 2)
                          .map(
                            (account) =>
                              `${account.bank_name}: ${account.value} `
                          )}
                      </Text>
                      <Text>
                        AN:{' '}
                        {bankAccounts.length !== 0 &&
                          bankAccounts[0].account_name}
                      </Text>
                    </>
                  ) : null}
                </View>
                <View style={{ width: '50%' }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text style={{ width: '75%', textAlign: 'right' }}>
                      SUBTOTAL: Rp.
                    </Text>
                    <Text style={{ flex: 1, textAlign: 'right' }}>
                      {order.total.toLocaleString('id')}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text
                      style={{
                        width: '75%',
                        textAlign: 'right',
                      }}
                    >
                      {order.discount_desc}
                      {order.discount
                        ? ` (${((order.discount / order.total) * 100).toFixed(
                            2
                          )}%) `.replace('.00', '')
                        : ''}
                      : Rp.
                    </Text>
                    <Text style={{ flex: 1, textAlign: 'right' }}>
                      {order.discount
                        ? `${order.discount.toLocaleString('id')}`
                        : '0'}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text
                      style={{
                        width: '75%',
                        textAlign: 'right',
                      }}
                    >
                      TOTAL: Rp.
                    </Text>
                    <Text style={{ flex: 1, textAlign: 'right' }}>
                      {order.total_after_discount
                        ? order.total_after_discount.toLocaleString('id')
                        : order.total.toLocaleString('id')}
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          ))}
      </Document>
    </PDFViewer>
  );
};

const mapStateToProps = ({ order, user, option, bankAccount }) => ({
  order: order.order,
  options: option.options,
  bankAccounts: bankAccount.bankAccounts,
  user: user.user,
});

export default connect(mapStateToProps)(Invoice);
