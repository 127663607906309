import React from 'react';
import {
  Modal,
  Form,
  Input,
} from 'antd';

const TailorModal = ({
  visible, onCreate, onCancel, data,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title={`${data ? 'Edit' : 'Tambah'} Penjahit Baru`}
      okText="Simpan"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            values.type = "Tukang Jahit";

            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal" initialValues={data}>
        <Form.Item
          name="name"
          label="Nama Penjahit"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan nama penjahit!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Nomor telepon penjahit"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan nomor telepon penjahit!',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TailorModal;
