import React from 'react';
import {
  Modal,
  Form,
  DatePicker,
} from 'antd';
import { format } from 'date-fns';

const cutter_id = process.env.REACT_APP_CUTTER_ID;
const CreateTailorFeeModal = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Penggajian"
      okText="Simpan"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();

            values.start_date = format(new Date(values.start_date), 'yyyy-MM-dd');
            values.end_date = format(new Date(values.end_date), 'yyyy-MM-dd');
            values.paid_at = format(new Date(values.paid_at), 'yyyy-MM-dd');
            onCreate({ ...values, production_tailor_id: cutter_id });
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="start_date"
          label="Tanggal Mulai Rekap"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal mulai rekap!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" disabledTime />
        </Form.Item>
        <Form.Item
          name="end_date"
          label="Tanggal Selesai Rekap"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal selesai rekap!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" disabledTime />
        </Form.Item>
        <Form.Item
          name="paid_at"
          label="Tanggal Pembayaran"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal pembayaran!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" disabledTime />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTailorFeeModal;
