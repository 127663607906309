/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import {
  Row,
  Col,
  Table,
  PageHeader,
  Tag,
  Button,
  Form,
  Card,
  Input,
  Select,
} from 'antd';
import debounce from 'lodash/debounce';

import { FilterOutlined } from '@ant-design/icons';
import { actions } from '../../../modules/reducers';
import { statusOrderMapper } from '../../../utils/status';
import api from '../../../utils/api';

const { Option } = Select;

const statuses = [
  'WAITING_PAYMENT',
  'PAYMENT_RECEIVED',
  'PREPARING',
  'READY',
  'DELIVERING',
  'DELIVERED',
  'DONE',
  'CANCELED',
  'REJECTED',
];

const Order = ({
  dispatch, orders, pagination, history, query,
}) => {
  const page = query.get('page');
  const status = query.get('status');
  const inv = query.get('inv');
  const user_id = query.get('user_id');
  const [form] = Form.useForm();
  const [userData, setUsers] = useState([]);

  useEffect(() => {
    dispatch(
      actions.fetchOrders({
        page: page || 1,
        status,
        inv,
        user_id,
      }),
    );
  }, [dispatch, page, status, inv, user_id]);

  const [ShowFilter, setShowFilter] = useState(false);

  const columns = [
    {
      title: 'Invoice',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, record) => (
        <div>
          <p style={{ fontSize: 13, margin: 0 }}>
            <strong>Customer:</strong>
            {' '}
            {record.customer_name}
          </p>
          <p style={{ fontSize: 12 }}>
            <strong>No Hp:</strong>
            {' '}
            {record.customer_phone}
          </p>
          <p style={{ fontSize: 11, fontWeight: 700 }}>{record.invoice_no}</p>
        </div>
      ),
    },
    {
      title: 'Alamat Pengiriman',
      dataIndex: 'customer_address',
      key: 'customer_address',
      render: (_, record) => (record.delivery_type !== 'PICKUP' ? (
        <div>
          <p style={{ fontSize: 13, margin: 0 }}>
            <strong>Penerima:</strong>
            {' '}
            {record.customer_receiver_name}
          </p>
          <p style={{ fontSize: 12 }}>
            <strong>No Hp Penerima:</strong>
            {' '}
            {record.customer_receiver_phone}
          </p>
          <p style={{ fontSize: 12 }}>
            {record.customer_address.slice(0, 60)}
            {' '}
            {record.customer_address.length > 60 ? '...' : ''}
          </p>
        </div>
      ) : (
        '-'
      )),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (value) => `Rp. ${value.toLocaleString('id')}`,
    },
    {
      title: 'Jenis Pengiriman',
      dataIndex: 'delivery_type',
      key: 'delivery_type',
      render: (value) => (
        <Tag color={value === 'PICKUP' ? 'purple' : 'green'}>
          {statusOrderMapper[value]}
        </Tag>
      ),
    },
    {
      title: 'Jenis Pembayaran',
      dataIndex: 'payment',
      key: 'payment',
      render: (value) => (
        <Tag color={value === 'BANK_TRANSFER' ? 'cyan' : 'magenta'}>
          {statusOrderMapper[value]}
        </Tag>
      ),
    },
    {
      title: 'Status Pembayaran',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        const colorMapper = {
          WAITING_PAYMENT: 'warning',
          CANCELLED: 'red',
          REJECTED: 'red',
          DONE: 'green',
          PAYMENT_RECEIVED: 'volcano',
        };
        return (
          <Tag
            color={
              colorMapper.hasOwnProperty(text) ? colorMapper[text] : 'blue'
            }
          >
            {statusOrderMapper[text]}
          </Tag>
        );
      },
    },
    {
      title: 'Tanggal',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => format(new Date(text), 'dd MMM yyyy HH:mm'),
    },
  ];

  const handleTableChange = (p) => {
    query.set('page', p.current);
    // const path = p.current === 1
    //   ? '/orders'
    //   : `/orders?page=${p.current}`;
    history.push(`/orders?${query.toString()}`);
  };

  const handleFilter = (value) => {
    if (value.status) {
      query.set('status', value.status);
    } else if (value.invoice_code) {
      query.set('inv', value.invoice_code);
    } else if (value.user_id) {
      query.set('user_id', value.user_id.value);
    }

    query.delete('page');
    history.push(`/orders?${query.toString()}`);
  };

  const handleReset = () => {
    form.resetFields();
    query.delete('status');
    query.delete('inv');
    history.push('/orders');
  };

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/user?name=${value}`);
    setUsers(res.data);
  }, 500);

  return (
    <Row>
      <Col span={24} style={{ marginBottom: 16 }}>
        <PageHeader title="Daftar Pesanan di Web" style={{ padding: '0 0 16px' }} />
        <Button type="primary" onClick={() => setShowFilter(!ShowFilter)}>
          <FilterOutlined />
          {' '}
          {ShowFilter ? 'Sembunyikan Filter' : 'Filter'}
        </Button>
      </Col>
      {ShowFilter ? (
        <Col span={24} style={{ marginBottom: 16 }}>
          <Form layout="vertical" onFinish={handleFilter} onReset={handleReset} initialValues={{ inv, status }}>
            <Card title="Filter berdasarkan">
              <Row>
                <Col span={5}>
                  <Form.Item name="status">
                    <Select placeholder="Pilih status">
                      {statuses.map((stat) => (
                        <Option value={stat}>{statusOrderMapper[stat]}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item name="user_id">
                    <Select
                      labelInValue
                      showSearch
                      placeholder="Ketik Nama User"
                      onSearch={onSearch}
                      filterOption={false}
                    >
                      {userData.map((user) => (
                        <Option key={user.id} value={user.id}>
                          {user.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item name="invoice_code">
                    <Input placeholder="Invoice Code" />
                  </Form.Item>
                </Col>
              </Row>
              <>
                <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                  Terapkan Filter
                </Button>
                <Button type="primary" htmlType="reset" danger>
                  Reset Filter
                </Button>
              </>
            </Card>
          </Form>
        </Col>
      ) : null}
      <Col span={24}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={orders}
          onRow={(record) => ({
            onClick: () => {
              history.push(`/orders/${record.id}`);
            },
          })}
          onChange={handleTableChange}
          pagination={{
            current: parseInt(page) || 1,
            total: (pagination && pagination.total) || 0,
            showSizeChanger: false,
          }}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ order }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    pagination: order.pagination,
    orders: order.orders,
    query,
  };
};

export default connect(mapStateToProps)(Order);
