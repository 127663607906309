import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  PageHeader,
  Row,
  Table,
  Modal,
  Space,
  Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions } from '../../../modules/reducers';
import CreateFabricModal from '../components/create';

const { confirm } = Modal;

const FabricPage = ({
  dispatch,
  fabrics,
  query,
  pagination,
  history,
}) => {
  const page = query.get('page') || 1;

  const [addModalVisible, setAddModalVisible] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchFabrics({ page }));
  }, [dispatch, page]);

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/production/fabric?${query.toString()}`);
  };

  const handleDeleteClicked = (fabric) => (
    confirm({
      title: 'Hapus Kain',
      content: 'Anda yakin ingin menghapus Kain ini?',
      onOk: () => {
        dispatch(actions.deleteFabric(fabric));
      },
    })
  );

  const onCreate = (values) => {
    dispatch(actions.createFabric({ ...values }));
    setAddModalVisible(false);
  };

  const columns = [
    {
      title: 'Daftar Kain',
      dataIndex: 'fabrics',
      key: 'fabrics',
      render: (_, record) => (
        <ul>
          {
            record.fabrics.map((fabric) => (
              <li>
                {fabric.name}
                . Harga: Rp.
                {' '}
                {fabric.price_per_yard.toLocaleString('ID')}
                . Penggunaan:
                {' '}
                {fabric.total_fabrics_in_yard}
              </li>
            ))
          }
        </ul>
      ),
    },
    {
      title: 'Daftar seri',
      dataIndex: 'batches',
      key: 'batches',
      render: (_, record) => record.batches.map((batch) => (
        <Tag key={batch.id}>
          <Link to={`/production/batch/${batch.id}`}>
            Seri
            {' '}
            {batch.batch_no}
          </Link>
        </Tag>
      )),
    },
    {
      title: 'Penggunaan rata-rata',
      dataIndex: 'usage_avg',
      key: 'usage_avg',
      render: (usage_avg) => `${usage_avg} / yard`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="danger" onClick={() => handleDeleteClicked(record)}>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <PageHeader title="Perhitungan Modal Kain" style={{ padding: '0 0 16px' }} />
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => setAddModalVisible(true)}>
              <PlusOutlined />
              Hitung
            </Button>
          </div>
          <Table
            rowKey="id"
            bordered
            columns={columns}
            // expandable={{ expandedRowRender }}
            dataSource={fabrics}
            onChange={handleTableChange}
            pagination={{
              current: +query.get('page') || 1,
              total: (pagination && pagination.total) || 0,
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <CreateFabricModal
        visible={addModalVisible}
        onCreate={onCreate}
        onCancel={() => setAddModalVisible(false)}
      />
    </>
  );
};

const mapStateToProps = ({ fabric }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    query,
    fabrics: fabric.fabrics,
    pagination: fabric.pagination,
  };
};

export default connect(mapStateToProps)(FabricPage);
