/* eslint-disable import/prefer-default-export */
import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.ORDER_LOADING,
});

export const fetchOrders = (params) => async (dispatch) => {
  dispatch(loading());

  const qs = Object.keys(params)
    .filter((x) => params[x])
    .map((x) => `${x}=${params[x]}`)
    .join('&');

  try {
    const res = await api.get(`/order?${qs}`);

    if (res.data.success) {
      dispatch({
        type: constant.ORDERS_FULFILLED,
        payload: res.data.data,
        pagination: {
          total: res.data.pagination.rowCount,
          current: res.data.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchOrder = (orderId) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/order/${orderId}/detail`);

    if (res.success) {
      dispatch({
        type: constant.ORDER_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const changeOrderStatus = (status, order) => async (dispatch) => {
  dispatch(loading());

  const formData = {
    status,
  };

  try {
    const { data: res } = await api.put(
      `/order/${order.id}/editstatus`,
      formData
    );

    if (res.success) {
      dispatch(fetchOrder(order.id));
      message.success('Sukses mengganti status order');
    }
  } catch (err) {
    console.error(err);
  }
};

export const rejectOrder = (orderID) => async (dispatch) => {
  dispatch(loading());

  const formData = {
    status: 'REJECTED',
  };

  try {
    const { data: res } = await api.put(`/order/${orderID}/reject`, formData);

    if (res.success) {
      dispatch(fetchOrder(orderID));
      message.success('Pesanan berhasil ditolak');
    }
  } catch (err) {
    console.error(err);
  }
};

export const setDiscount = (id, data) => async (dispatch) => {
  dispatch(loading());

  const formData = {
    discount: data.discount,
    discount_desc: data.discount_desc,
  };

  try {
    const { data: res } = await api.put(`/order/${id}/discount`, formData);

    if (res.success) {
      dispatch(fetchOrder(id));
      message.success('Diskon berhasil ditambahkan ke pesanan');
    } else {
      message.error('Terjadi kesalahan');
    }
  } catch (err) {
    console.error(err);
  }
};
