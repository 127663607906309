/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import {
  Row,
  Col,
  Table,
  Space,
  Button,
  PageHeader,
  Card,
  Form,
  Select,
} from 'antd';
import { PlusOutlined, FilterOutlined } from '@ant-design/icons';

import { actions } from '../../../modules/reducers';

import CategoryCreate from '../components/create';
import { getImage } from '../../../utils/image';
import { countQuantity } from '../../../utils/number';
import api from '../../../utils/api';

const { Option } = Select;

const Category = ({
  dispatch,
  categories,
  history,
  query,
  pagination,
  sum,
}) => {
  const page = query.get('page');
  const name = query.get('name');
  const limit = query.get('limit');

  useEffect(() => {
    dispatch(
      actions.fetchCategories({
        page: page || 1,
        name: name || '',
        limit: limit || 10,
      }),
    );
  }, [dispatch, page, name, limit]);

  useEffect(() => {
    dispatch(actions.fetchSum());
  }, [dispatch]);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [ShowFilter, setShowFilter] = useState(false);
  const [categoryData, setCategories] = useState([]);

  const onCreate = (values) => {
    dispatch(actions.createCategory(values, { history }));
    setVisible(false);
  };

  const handleTableChange = (p) => {
    query.set('page', p.current);
    // const path = p.current === 1
    //   ? '/categories'
    //   : `/categories?page=${p.current}`;
    history.push(`/categories?${query.toString()}`);
  };

  const handleFilter = (value) => {
    query.set('name', value.name.value);

    query.delete('page');
    history.push(`/categories?${query.toString()}`);
  };

  const handlePageSizeChange = (_, value) => {
    query.set('limit', value);

    history.push(`/categories?${query.toString()}`);
  };

  const columns = [
    {
      title: 'Gambar',
      dataIndex: 'picture',
      key: 'picture',
      render: (text, record) => (
        <Space size="middle">
          <div style={{ maxWidth: 60, overflow: 'hidden', borderRadius: 4 }}>
            <img
              src={getImage(record.picture, 100)}
              alt="slider"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </Space>
      ),
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Stok Total',
      dataIndex: 'products_stock_qty',
      key: 'products_stock_qty',
      render: (value) => (value ? countQuantity(value) : 'Kosong'),
    },
    {
      title: 'Total dalam Rupiah',
      dataIndex: 'stock_in_rp',
      key: 'stock_in_rp',
      render: (value) => (value ? `Rp. ${value.toLocaleString('id')}` : '-'),
    },
  ];

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/category?name=${value}`);
    setCategories(res.data);
  }, 500);

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader title="Daftar Model" subTitle="Manajemen Model Produk" style={{ padding: '0 0 16px' }} />
          <Button type="primary" onClick={() => setVisible(true)}>
            <PlusOutlined />
            {' '}
            Tambah
          </Button>
          <Button type="primary" style={{ marginLeft: 15 }} onClick={() => setShowFilter(!ShowFilter)}>
            <FilterOutlined />
            {' '}
            {ShowFilter ? 'Sembunyikan Filter' : 'Filter'}
          </Button>
        </Col>
        {ShowFilter ? (
          <Col span={24} style={{ marginTop: 16 }}>
            <Form layout="vertical" form={form} onFinish={handleFilter}>
              <Card title="Filter berdasarkan">
                <Col span={8}>
                  <Form.Item name="name">
                    <Select
                      labelInValue
                      showSearch
                      placeholder="Ketik Nama Model"
                      onSearch={onSearch}
                      filterOption={false}
                    >
                      {categoryData.map((category) => (
                        <Option key={category.id} value={category.name}>
                          {category.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <>
                  <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                    Terapkan Filter
                  </Button>

                  <Button
                    type="primary"
                    htmlType="reset"
                    onClick={() => {
                      form.resetFields();
                      history.push('/categories');
                    }}
                    danger
                  >
                    Reset Filter
                  </Button>
                </>
              </Card>
            </Form>
          </Col>
        ) : null}
        <Col span={24} style={{ marginTop: 16 }}>
          <Table
            rowKey="id"
            title={() => (
              <div
                style={{
                  textAlign: 'right',
                  fontWeight: 700,
                  fontSize: '18px',
                }}
              >
                {`Total Seluruh Stok: Rp. ${sum.toLocaleString('id')}`}

              </div>
            )}
            columns={columns}
            dataSource={categories}
            onRow={(record) => ({
              onClick: () => {
                history.push(`/categories/${record.id}`);
              },
            })}
            pagination={{
              current: parseInt(page) || 1,
              total: (pagination && pagination.total) || 0,
              showSizeChanger: true,
              pageSize: limit || 10,
              onShowSizeChange: handlePageSizeChange,
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <CategoryCreate visible={visible} onCreate={onCreate} onCancel={() => setVisible(false)} />
    </>
  );
};

const mapStateToProps = ({ category }, { location }) => {
  const query = new URLSearchParams(location.search);

  return {
    categories: category.categories,
    loading: category.loading,
    pagination: category.pagination,
    sum: category.sum,
    query,
  };
};

export default connect(mapStateToProps)(Category);
