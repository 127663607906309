import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Select, InputNumber,
} from 'antd';
import { debounce } from 'lodash';
import api from '../../../utils/api';

const { Option } = Select;

const ModelModal = ({
  visible, onCreate, onCancel, data,
}) => {
  const [form] = Form.useForm();

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/category?name=${value || ''}`);
    setCategories(res.data);
  }, 500);

  useEffect(() => {
    if (data) {
      setCategory({
        key: data?.model?.id,
        label: data?.model?.name,
        value: data?.model?.id,
      });
    }
  }, [data]);

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title={`${data ? 'Edit' : 'Tambah'} Model`}
      okText={`${data ? 'Edit' : 'Tambah'}`}
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();

            delete values.category;
            values.category_id = category.value;
            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          ...data,
          category: {
            key: data?.model?.id,
            label: data?.model?.name,
            value: data?.model?.id,
          },
        }}
      >
        <Form.Item
          name="category"
          label="Model"
          rules={[
            {
              required: true,
              message: 'Mohon pilih salah satu model!',
            },
          ]}
        >
          <Select
            labelInValue
            showSearch
            placeholder="Ketik Nama Model"
            onSearch={onSearch}
            filterOption={false}
            onSelect={(value) => setCategory(value)}
          >
            {categories.map((category) => (
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="cutting_price"
          label="Harga potong per lusin"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan harga potong untuk model!',
            },
          ]}
        >
          <InputNumber defaultValue={0} />
        </Form.Item>
        <Form.Item
          name="sewing_price"
          label="Harga jahit per lusin"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan harga jahit untuk model!',
            },
          ]}
        >
          <InputNumber defaultValue={0} />
        </Form.Item>
        <Form.Item name="accessories_price" label="Harga aksesoris per lusin">
          <InputNumber defaultValue={0} />
        </Form.Item>
        <Form.Item name="other_price" label="Harga lain-lain per lusin">
          <InputNumber defaultValue={0} />
        </Form.Item>
        <Form.Item
          name="main_price"
          label="Harga jual per pcs"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan harga jual untuk model!',
            },
          ]}
        >
          <InputNumber defaultValue={0} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModelModal;
