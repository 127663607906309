const base = {
  COOKIE_NAME: "hello-pgmta-admin",
  BARCODE_KEY: "pgmta_barcode",
};

const development = {
  ...base,
  API_HOST: process.env.REACT_APP_API_HOST,
};

const production = {
  ...base,
  API_HOST: process.env.REACT_APP_API_HOST,
};

const env = process.env.REACT_APP_STAGE || "development";
export default env === "production" ? production : development;
