import { message } from 'antd';

import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.POS_PRODUCT_LOADING,
});

export const fetchPOSProducts = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(
      `/pos-product?page=${params.page}&name=${params.name}&category_id=${params.category_id}`
    );

    if (res.success) {
      dispatch({
        type: constant.POS_PRODUCTS_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchPOSProductSum = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/pos-product-sum?category_id=${params.category_id}`);

    if (res.success) {
      dispatch({
        type: constant.POS_PRODUCT_TOTAL_SUM_FULFILLED,
        payload: res.data.total || 0,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchPOSProductQty = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/pos-product-qty?category_id=${params.category_id}`);

    if (res.success) {
      dispatch({
        type: constant.POS_PRODUCT_TOTAL_QTY_FULFILLED,
        payload: res.data.total || 0,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchPOSStockAudit = (id, page = 1) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/pos-product/${id}/stocks?page=${page}`);

    if (res.success) {
      dispatch({
        type: constant.POS_PRODUCT_STOCK_AUDIT_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchPOSProduct = (id) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/pos-product/${id}`);

    if (res.success) {
      dispatch({
        type: constant.POS_PRODUCT_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateStockPOSProduct = (data, product) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post(`/pos-product/${product.id}/stocks`, data);

    if (res.success) {
      dispatch({
        type: constant.POS_PRODUCT_STOCK_UPDATED,
      });
      message.success('Berhasil Update Stock');
    }
  } catch (err) {
    console.error(err);
  }
};
