import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
} from '@react-pdf/renderer';
import QRious from 'qrious';

import config from '../../../config';

const Barcode = ({ product }) => {
  const [qr, setQR] = useState([]);

  useEffect(() => {
    if (product.length) {
      const allBarcodes = product
        .map((p) => {
          const arr = Array.from(Array(Math.ceil(p.qtyToPrint / 4)).keys());
          return arr.map((x) => {
            const newQR = new QRious({
              value: p.sku,
              size: 100,
            });

            return {
              name: p.name,
              sku: p.sku,
              batch_code: p.batch_code,
              qr: newQR.toDataURL(),
            };
          });
        })
        .reduce((r, a) => r.concat(a), []);

      setQR(allBarcodes);
    }
  }, [product]);

  return (
    <PDFViewer
      style={{
        width: '100%',
        height: '100vh',
        margin: -24,
        padding: 0,
        position: 'fixed',
        border: 'none',
      }}
    >
      <Document>
        {product &&
          qr &&
          chunk(qr, 72).map((pages, idx) => {
            return (
              <Page size="A4" key={idx}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'flex-start',
                  }}
                >
                  {pages.map((barcode, idx) => (
                    <View
                      key={idx}
                      style={{
                        border: 1,
                        borderColor: 'black',
                        width: '12.5%',
                        paddingBottom: 4,
                      }}
                    >
                      <View
                        style={{
                          paddingHorizontal: 12,
                          paddingVertical: 8,
                        }}
                      >
                        <Image src={barcode.qr} style={{ maxWidth: 50 }} />
                      </View>
                      <View style={{ paddingHorizontal: 8 }}>
                        <Text style={{ fontSize: 9, textAlign: 'center' }}>
                          {barcode.batch_code || barcode.sku}
                        </Text>
                        <Text style={{ fontSize: 7, textAlign: 'center' }}>
                          {barcode.name}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </Page>
            );
          })}
      </Document>
    </PDFViewer>
  );
};

const mapStateToProps = () => {
  let product = [];
  const storage = localStorage.getItem(config.BARCODE_KEY);
  try {
    product = JSON.parse(storage);
  } catch (error) {}

  return {
    product,
  };
};

export default connect(mapStateToProps)(Barcode);
