import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  PageHeader,
  Row,
  Table,
  Modal,
  Space,
  Form,
  Card,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { actions } from '../../../modules/reducers';
import CreateTailorFeeModal from '../components/create';
import api from '../../../utils/api';

const { confirm } = Modal;
const { Option } = Select;

const FeePage = ({
  dispatch,
  fees,
  query,
  pagination,
  history,
}) => {
  const page = query.get('page') || 1;
  const tailor_id = query.get('tailor_id');

  const [form] = Form.useForm();

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [tailors, setTailors] = useState([]);

  useEffect(() => {
    dispatch(actions.fetchFees({ page, tailor_id, tailor_type: 'SEW' }));
  }, [dispatch, page, tailor_id]);

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/production/tailor/fee?${query.toString()}`);
  };

  const handleDeleteClicked = (fee) => (
    confirm({
      title: 'Hapus Rekap Gaji',
      content: 'Anda yakin ingin menghapus Rekap Gaji ini?',
      onOk: () => {
        dispatch(actions.deleteFee(fee));
      },
    })
  );

  const onCreate = (values) => {
    dispatch(actions.createFee({ ...values }));
    setCreateModalVisible(false);
  };

  const feeExpandable = (row) => {
    const feeItemColumns = [
      {
        title: 'Kategori',
        dataIndex: 'category',
        key: (_, record) => `category-${record.id}`,
      },
      {
        title: 'Jumlah',
        dataIndex: 'total',
        key: (_, record) => `total-${record.id}`,
        render: (_, record) => `${record.type === 'C' ? '-' : ''} Rp. ${record.total.toLocaleString('ID')}`,
      },
    ];

    return <Table rowKey="id" columns={feeItemColumns} dataSource={row.items} pagination={false} />;
  };

  const handleFilter = (value) => {
    Object.keys(value).forEach((param) => {
      if (param === 'tailor_id') {
        if (value[param] && value[param].value > 0) {
          query.set(param, value[param].value);
        }
      } else if (value[param]) {
        query.set(param, value[param]);
      }
    });
    query.set('page', 1);
    history.push(`/production/tailor/fee?${query.toString()}`);
  };

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/tailors?name=${value}`);
    setTailors(res.data);
  }, 500);

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => record?.production_tailor?.name,
    },
    {
      title: 'Tanggal Mulai Rekap',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (_, record) => format(new Date(record.start_date), 'dd MMM yyyy'),
    },
    {
      title: 'Tanggal Selesai Rekap',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (_, record) => format(new Date(record.end_date), 'dd MMM yyyy'),
    },
    {
      title: 'Tanggal Pembayaran',
      dataIndex: 'paid_at',
      key: 'paid_at',
      render: (_, record) => format(new Date(record.paid_at), 'dd MMM yyyy'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            target="_blank"
            href={`/production/fee/${record.id}/invoice`}
          >
            Print Gaji
          </Button>
          <Button type="danger" onClick={() => handleDeleteClicked(record)}>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <PageHeader title="Gaji Penjahit" style={{ padding: '0 0 16px' }} />
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => setCreateModalVisible(true)}>
              <PlusOutlined />
              Tambah
            </Button>
            <Button type="primary" style={{ marginLeft: 15 }} onClick={() => setShowFilter(!showFilter)}>
              <FilterOutlined />
              {' '}
              {showFilter ? 'Sembunyikan Filter' : 'Filter'}
            </Button>
          </div>
          <div style={{ marginBottom: 16 }}>
            {showFilter ? (
              <Form form={form} layout="horizontal" onFinish={handleFilter}>
                <Card title="Filter berdasarkan">
                  <Col span={8}>
                    <Form.Item name="tailor_id">
                      <Select
                        labelInValue
                        showSearch
                        placeholder="Ketik Nama Penjahit"
                        onSearch={onSearch}
                        filterOption={false}
                      >
                        {tailors.map((tailor) => (
                          <Option key={tailor.id} value={tailor.id}>
                            {tailor.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <>
                    <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                      Terapkan Filter
                    </Button>
                    <Button
                      type="primary"
                      htmlType="reset"
                      onClick={(e) => {
                        e.preventDefault();
                        form.resetFields();
                        history.push('/production/fee');
                      }}
                      danger
                    >
                      Reset Filter
                    </Button>
                  </>
                </Card>
              </Form>
            ) : null}
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={fees}
            onChange={handleTableChange}
            expandable={{ expandedRowRender: feeExpandable, defaultExpandedRowKeys: ['0'] }}
            pagination={{
              current: +query.get('page') || 1,
              total: (pagination && pagination.total) || 0,
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <CreateTailorFeeModal
        visible={createModalVisible}
        onCreate={onCreate}
        onCancel={() => setCreateModalVisible(false)}
      />
    </>
  );
};

const mapStateToProps = ({ fee }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    query,
    fees: fee.fees,
    pagination: fee.pagination,
  };
};

export default connect(mapStateToProps)(FeePage);
