import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  PageHeader,
  Row,
  Table,
  Modal,
  Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../modules/reducers';
import TailorModal from '../components/modal';

const { confirm } = Modal;

const TailorPage = ({
  dispatch,
  tailors,
  query,
  pagination,
  history,
}) => {
  const page = query.get('page') || 1;

  const [addModalVisible, setAddModalVisible] = useState(false);

  const [editTailor, setEditTailor] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchTailors({ page }));
  }, [dispatch, page]);

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/production/tailor?${query.toString()}`);
  };

  const handleEditClicked = (tailor) => {
    setEditTailor(tailor);
    setEditModalVisible(true);
  };

  const handleDeleteClicked = (tailor) => (
    confirm({
      title: 'Hapus Penjahit',
      content: 'Anda yakin ingin menghapus penjahit ini?',
      onOk: () => {
        dispatch(actions.deleteTailor(tailor));
      },
    })
  );

  const onCreate = (values) => {
    dispatch(actions.createTailor({ ...values }));
    setAddModalVisible(false);
  };

  const onEdit = (values) => {
    dispatch(actions.updateTailor(values, editTailor, { history }));
    setEditModalVisible(false);
    setEditTailor(null);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEditClicked(record)}>Edit</Button>
          <Button type="danger" onClick={() => handleDeleteClicked(record)}>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <PageHeader title="Daftar Penjahit" style={{ padding: '0 0 16px' }} />
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => setAddModalVisible(true)}>
              <PlusOutlined />
              Tambah
            </Button>
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={tailors}
            onChange={handleTableChange}
            pagination={{
              current: +query.get('page') || 1,
              total: (pagination && pagination.total) || 0,
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <TailorModal
        visible={addModalVisible}
        onCreate={onCreate}
        onCancel={() => setAddModalVisible(false)}
      />
      <TailorModal
        visible={editModalVisible}
        onCreate={onEdit}
        onCancel={() => {
          setEditTailor(null);
          setEditModalVisible(false);
        }}
        data={editTailor}
      />
    </>
  );
};

const mapStateToProps = ({ tailor }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    query,
    tailors: tailor.tailors,
    pagination: tailor.pagination,
  };
};

export default connect(mapStateToProps)(TailorPage);
