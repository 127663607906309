import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  Card,
  PageHeader,
} from 'antd';

import { actions } from '../../../modules/reducers';

const { confirm } = Modal;

const EditCourier = ({ dispatch, match, courier, history }) => {
  const { params } = match;

  useEffect(() => {
    dispatch(actions.fetchCourier(params.id));
  }, [dispatch, params.id]);

  const onFinish = (data) => {
    dispatch(actions.updateCourier(data, courier, { history }));
  };

  return (
    <>
      <PageHeader
        title="Ubah Ekspedisi"
        subTitle={courier ? courier.bank_name : ''}
        style={{ padding: '0 0 16px' }}
        onBack={() => history.goBack()}
      />
      <Row>
        <Col span={12}>
          <Card>
            {courier ? (
              <Form
                layout="vertical"
                initialValues={courier}
                onFinish={onFinish}
              >
               
                <Form.Item
                  name="name"
                  label="Nama Ekspedisi"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan nama ekspedisi',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Edit Ekspedisi
                  </Button>
                  <Button
                    type="danger"
                    style={{ marginLeft: 10 }}
                    onClick={() =>
                      confirm({
                        title: 'Hapus ekspedisi',
                        content: 'Apakah anda yakin ingin menghapus ekspedisi ini?',
                        onOk: () => {
                          dispatch(
                            actions.deleteCourier([courier.id], { history }),
                          );
                        },
                      })
                    }
                  >
                    Hapus
                  </Button>
                </Form.Item>
              </Form>
            ) : null}
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ courier }) => ({
  courier: courier.courier,
  loading: courier.loading,
});

export default connect(mapStateToProps)(EditCourier);
