import React, { useState } from 'react';
import {
  Modal,
  Form,
  InputNumber,
  Space,
  Input,
  Button,
  Select,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import api from '../../../../utils/api';

const { Option } = Select;

const CreateFabricModal = ({
  visible, onCreate, onCancel
}) => {
  const [form] = Form.useForm();

  const [batches, setBatches] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);

  const onBatchSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/batches?batch_no=${value || ''}`);
    setBatches(res.data);
  }, 500);

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Harga Modal Kain"
      okText="Simpan"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            values.production_batch_ids = selectedBatches.map((batch) => batch.value);
            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          fabrics: [''],
        }}
      >
        <Form.List name="fabrics">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} align="baseline">
                  <Form.Item
                    {...field}
                    label="Nama Kain"
                    name={[field.name, 'name']}
                    rules={[{ required: true, message: 'Tolong masukkan nama kain' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label="Harga per yard"
                    name={[field.name, 'price_per_yard']}
                    rules={[{ required: true, message: 'Harga kain per yard tidak boleh kosong' }]}
                  >
                    <InputNumber
                      min={0}
                      value={price}
                      onChange={(value) => {
                        setPrice(value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label="Kain Terpakai (yard)"
                    name={[field.name, 'total_fabrics_in_yard']}
                    rules={[{ required: true, message: 'Jumlah penggunaan kain tidak boleh kosong' }]}
                  >
                    <InputNumber
                      min={0}
                      value={total}
                      onChange={(value) => {
                        setTotal(value);
                      }}
                    />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Tambahkan Kain Lainnya
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          label="Pilih seri yang menggunakan kain ini:"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan seri!',
            },
          ]}
        >
          <Select
            mode="multiple"
            labelInValue
            showSearch
            allowClear
            style={{ width: '100%' }}
            placeholder="Pilih Seri"
            onChange={(batch) => setSelectedBatches(batch)}
            onSearch={onBatchSearch}
            filterOption={false}
          >
            {batches.map((batch) => (
              <Option key={batch.id} value={batch.id}>
                Seri
                {' '}
                {batch.batch_no}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateFabricModal;
