import { Cookies } from 'react-cookie';
import { message } from 'antd';

import * as constant from './constants';
import api from '../../../utils/api';
import { getRole } from '../../../utils/token';

const cookies = new Cookies();

const loading = () => ({
  type: constant.USER_LOADING,
});

export const userLogin = (data, { history }) => async (dispatch) => {
  try {
    const { data: res } = await api.post('login', data);

    if (res.token) {
      const role = getRole(res.token);
      if (role !== 'ADMIN') {
        message.error('Anda tidak memiliki akses.');
      } else {
        cookies.set('access_token', res.token);
        history.push('/');
      }
    }
  } catch (err) {
    dispatch({
      type: constant.USER_LOADING,
      loading: false,
    });
  }
};

export const fetchUserList = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const qs = Object.keys(params)
      .filter((x) => params[x])
      .map((x) => `${x}=${params[x]}`)
      .join('&');

    const { data: res } = await api.get(`/user?${qs}`);

    if (res.success) {
      const filtered = res.data.filter((user) => user.role.toLowerCase() !== 'admin');

      dispatch({
        type: constant.USER_LIST_FULFILLED,
        payload: filtered,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchUser = (id) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/user/${id}`);

    if (res.success) {
      dispatch({
        type: constant.USER_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateUser = (id, data) => async (dispatch) => {
  try {
    const { data: res } = await api.put(`/user/${id}`, data);

    if (res.success) {
      message.success('Berhasil mengubah pelanggan');
      dispatch(fetchUser(id));
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchAddress = () => async (dispatch) => {
  try {
    const { data: res } = await api.get(`/address`);

    if (res.success) {
      dispatch({
        type: constant.ADDRESS_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteAddress = (addressID) => async (dispatch) => {
  try {
    const { data: res } = await api.delete(`/address/${addressID}`);

    if (res.success) {
      message.success('Berhasil Menghapus Alamat');
      dispatch(fetchAddress());
    }
  } catch (err) {
    console.error(err);
  }
};

export const addNewAddress = (data) => async (dispatch) => {
  try {
    const { data: res } = await api.post(`/address`, data);

    if (res.success) {
      message.success('Berhasil menambah alamat baru');
      dispatch(fetchAddress());
    }
  } catch (err) {
    console.error(err);
  }
};

export const editAddress = (id, data) => async (dispatch) => {
  try {
    const { data: res } = await api.put(`/address/${id}`, data);

    if (res.success) {
      message.success('Berhasil mengubah alamat');
      dispatch(fetchAddress());
    }
  } catch (err) {
    console.error(err);
  }
};

export const updatePassword = (id, data) => async (dispatch) => {
  try {
    const { data: res } = await api.put(`/user/${id}/password`, data);

    if (res.success) {
      message.success('Berhasil mengganti password customer');
      dispatch(fetchUser(id));
    }
  } catch (err) {
    console.error(err);
  }
};
