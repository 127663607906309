import React, { useState } from 'react';
import {
  Modal,
  Form,
  DatePicker,
  Select,
} from 'antd';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import api from '../../../utils/api';

const { Option } = Select;

const CreateTailorFeeModal = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  const [tailors, setTailors] = useState([]);
  const [tailor, setTailor] = useState(null);

  const onTailorSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/tailors?name=${value || ''}`);
    setTailors(res.data);
  }, 500);

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Penggajian"
      okText="Simpan"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            delete values.tailor;
            delete values.batch;

            values.production_tailor_id = tailor.value;
            values.start_date = format(new Date(values.start_date), 'yyyy-MM-dd');
            values.end_date = format(new Date(values.end_date), 'yyyy-MM-dd');
            values.paid_at = format(new Date(values.paid_at), 'yyyy-MM-dd');
            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item name="tailor" label="Penjahit">
          <Select
            labelInValue
            showSearch
            placeholder="Ketik Nama Penjahit"
            onSearch={onTailorSearch}
            filterOption={false}
            onSelect={(value) => setTailor(value)}
          >
            {tailors.map((tailor) => (
              <Option key={tailor.id} value={tailor.id}>
                {tailor.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="start_date"
          label="Tanggal Mulai Rekap"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal mulai rekap!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" disabledTime />
        </Form.Item>
        <Form.Item
          name="end_date"
          label="Tanggal Selesai Rekap"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal selesai rekap!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" disabledTime />
        </Form.Item>
        <Form.Item
          name="paid_at"
          label="Tanggal Pembayaran"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal pembayaran!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" disabledTime />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTailorFeeModal;
