import { combineReducers } from 'redux';

import user from './user';
import category from './category';
import product from './product';
import slider from './slider';
import tags from './tag';
import order from './order';
import bankAccount from './bankAccount';
import option from './option';
import stats from './stats';
import courier from './courier';
import cart from './cart';

import posProduct from './posProduct';
import posOrder from './posOrder';
import posOrderHistories from './posOrderHistories';

import tailor from './tailor';
import model from './model';
import batch from './batch';
import production from './production';
import expense from './expense';
import fee from './fee';
import period from './period';
import fabric from './fabric';

const rootReducer = combineReducers({
  user: user.reducer,
  category: category.reducer,
  product: product.reducer,
  slider: slider.reducer,
  tags: tags.reducer,
  order: order.reducer,
  bankAccount: bankAccount.reducer,
  option: option.reducer,
  stats: stats.reducer,
  courier: courier.reducer,
  cart: cart.reducer,

  posProduct: posProduct.reducer,
  posOrder: posOrder.reducer,
  posOrderHistories: posOrderHistories.reducer,

  tailor: tailor.reducer,
  model: model.reducer,
  batch: batch.reducer,
  production: production.reducer,

  expense: expense.reducer,
  fee: fee.reducer,

  period: period.reducer,
  fabric: fabric.reducer,

});

export const actions = {
  ...user.actions,
  ...category.actions,
  ...product.actions,
  ...slider.actions,
  ...tags.actions,
  ...order.actions,
  ...bankAccount.actions,
  ...option.actions,
  ...stats.actions,
  ...courier.actions,
  ...cart.actions,

  ...posProduct.actions,
  ...posOrder.actions,
  ...posOrderHistories.actions,

  ...tailor.actions,
  ...model.actions,
  ...batch.actions,
  ...production.actions,

  ...expense.actions,
  ...fee.actions,

  ...period.actions,
  ...fabric.actions,
};

export default rootReducer;
