import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  InputNumber,
  Modal,
  Card,
  PageHeader,
  Select,
} from 'antd';

import { actions } from '../../../modules/reducers';

const { confirm } = Modal;
const { Option } = Select;

const Detail = ({ dispatch, match, bankAccount, history }) => {
  const { params } = match;

  useEffect(() => {
    dispatch(actions.fetchBankAccount(params.id));
  }, [dispatch, params.id]);

  const onFinish = (data) => {
    dispatch(actions.updateBankAccount(data, bankAccount, { history }));
  };

  const banks = [
    'BNI',
    'BCA',
    'Mandiri',
    'BRI',
    'CIMB Niaga',
    'Bukopin',
    'Jenius',
    'BTPN',
  ];

  return (
    <>
      <PageHeader
        title="Ubah Rekening Bank"
        subTitle={bankAccount ? bankAccount.bank_name : ''}
        style={{ padding: '0 0 16px' }}
        onBack={() => history.goBack()}
      />
      <Row>
        <Col span={12}>
          <Card>
            {bankAccount ? (
              <Form
                layout="vertical"
                initialValues={bankAccount}
                onFinish={onFinish}
              >
                <Form.Item
                  name="bank_name"
                  label="Nama Bank"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan nama bank',
                    },
                  ]}
                >
                  <Select defaultValue="BNI">
                    {banks.map((bank) => (
                      <Option value={bank} key={bank}>
                        {bank}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="account_name"
                  label="Nama pemilik rekening bank"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan nama pemilik rekening bank',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="value"
                  label="Nomor rekening bank"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan nomor rekening bank',
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Edit Akun Bank
                  </Button>
                  <Button
                    type="danger"
                    style={{ marginLeft: 10 }}
                    onClick={() =>
                      confirm({
                        title: 'Hapus Rekening',
                        content: 'Apakah anda yakin ingin menghapus rekening ini?',
                        onOk: () => {
                          dispatch(
                            actions.deleteBankAccount([bankAccount.id], { history }),
                          );
                        },
                      })
                    }
                  >
                    Hapus
                  </Button>
                </Form.Item>
              </Form>
            ) : null}
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ bankAccount }) => ({
  bankAccount: bankAccount.bankAccount,
  loading: bankAccount.loading,
});

export default connect(mapStateToProps)(Detail);
