import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  PageHeader,
  Row,
  Table,
  Modal,
  Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { actions } from '../../../modules/reducers';
import CreatePeriodModal from '../components/create';
import UpdatePeriodModal from '../components/update';
import { pcsToDozen } from '../../../utils/number';

const { confirm } = Modal;

const PeriodPage = ({
  dispatch,
  periods,
  query,
  pagination,
  history,
}) => {
  const page = query.get('page') || 1;

  const [addModalVisible, setAddModalVisible] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchPeriods({ page }));
  }, [dispatch, page]);

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/production/period?${query.toString()}`);
  };

  const handleDeleteClicked = (model) => (
    confirm({
      title: 'Hapus Periode',
      content: 'Anda yakin ingin menghapus Periode ini?',
      onOk: () => {
        dispatch(actions.deletePeriod(model));
      },
    })
  );

  const handleEditClicked = (record) => {
    setSelectedPeriod(record);
    setEditModalVisible(true);
  };

  const onCreate = (values) => {
    dispatch(actions.createPeriod({ ...values }));
    setAddModalVisible(false);
  };

  const onEdit = (values) => {
    dispatch(actions.updatePeriod(values, selectedPeriod, { history }));
    setEditModalVisible(false);
    setSelectedPeriod(null);
  };

  const columns = [
    {
      title: 'Tanggal Mulai',
      dataIndex: 'start_at',
      key: 'start_at',
      render: (_, record) => format(new Date(record.start_at), 'EE, dd-MMM-yyyy'),
    },
    {
      title: 'Tanggal Selesai',
      dataIndex: 'end_at',
      key: 'end_at',
      render: (_, record) => format(new Date(record.end_at), 'EE, dd-MMM-yyyy'),
    },
    {
      title: 'Rata-rata profit',
      dataIndex: 'profit_percentage',
      key: 'profit_percentage',
      render: (_, record) => `${record.profit_percentage}%` ?? '0%',
    },
    {
      title: 'Total Jahitan',
      dataIndex: 'sewing_total',
      key: 'sewing_total',
      render: (_, record) => { 
        const sewing_total = pcsToDozen(record.sewing_total);

        return (
          <span>
            {sewing_total.dozen}
            {' '}
            lusin {' '}
            {sewing_total.pcs}
            {' '}
            pcs
          </span>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEditClicked(record)}>Edit</Button>
          <Button type="danger" onClick={() => handleDeleteClicked(record)}>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <PageHeader title="Manajemen Periode" style={{ padding: '0 0 16px' }} />
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => setAddModalVisible(true)}>
              <PlusOutlined />
              Tambah
            </Button>
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={periods}
            onChange={handleTableChange}
            pagination={{
              current: +query.get('page') || 1,
              total: (pagination && pagination.total) || 0,
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <CreatePeriodModal
        visible={addModalVisible}
        onCreate={onCreate}
        onCancel={() => setAddModalVisible(false)}
      />
      <UpdatePeriodModal
        visible={editModalVisible}
        onCreate={onEdit}
        onCancel={() => {
          setSelectedPeriod(null);
          setEditModalVisible(false);
        }}
        data={selectedPeriod}
      />
    </>
  );
};

const mapStateToProps = ({ period }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    query,
    periods: period.periods,
    pagination: period.pagination,
  };
};

export default connect(mapStateToProps)(PeriodPage);
