import * as constant from './constants';

export const initialState = {
  loading: false,
  sliders: [],
  slider: null,
  pagination: null,
};

const sliderReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.SLIDER_LOADING:
      return {
        ...state,
        loading: action.loading,
        sliders: [],
        slider: null,
      };
    case constant.SLIDERS_FULFILLED:
      return {
        ...state,
        loading: false,
        sliders: action.payload,
        pagination: action.pagination,
      };
    case constant.SLIDER_FULFILLED:
      return {
        ...state,
        loading: false,
        slider: action.payload,
      };
    default:
      return state;
  }
};

export default sliderReducer;
