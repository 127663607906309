import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Table, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { actions } from '../../../modules/reducers';
import BankAccountCreate from '../components/create';

const ProductPage = ({ dispatch, bankAccounts, history }) => {
  useEffect(() => {
    dispatch(actions.fetchBankAccounts());
  }, [dispatch]);

  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onCreate = (values) => {
    dispatch(actions.createProduct(values));
    setVisible(false);
  };

  const columns = [
    {
      title: 'Nama Bank',
      dataIndex: 'bank_name',
      key: 'bank_name',
    },
    {
      title: 'Nama Pemilik Rekening Bank',
      dataIndex: 'account_name',
      key: 'account_name',
    },
    {
      title: 'Nomor Rekening Bank',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const onSelectRow = (_, rows) => {
    setSelectedRowKeys(rows.map((r) => r.id));
  };

  return (
    <>
      <Row>
        <Col span={20}>
          <h2>Daftar Akun Bank</h2>
        </Col>
        <Col span={4}>
          <Button type="primary" onClick={() => setVisible(true)}>
            <PlusOutlined /> Tambah
          </Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey="id"
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectRow,
            }}
            columns={columns}
            dataSource={bankAccounts}
            onRow={(record) => ({
              onClick: () => {
                history.push(`/bank-accounts/${record.id}`);
              },
            })}
          />
        </Col>
      </Row>
      <BankAccountCreate
        visible={visible}
        onCreate={onCreate}
        onCancel={() => setVisible(false)}
      />
    </>
  );
};

const mapStateToProps = ({ bankAccount }) => ({
  bankAccounts: bankAccount.bankAccounts,
});

export default connect(mapStateToProps)(ProductPage);
