import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.BATCH_LOADING,
});

export const fetchBatches = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const qs = Object.keys(params)
      .filter((x) => params[x])
      .map((x) => `${x}=${params[x]}`)
      .join('&');

    const { data: res } = await api.get(`/production/batches?${qs}`);

    if (res.success) {
      dispatch({
        type: constant.BATCHES_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createBatch = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/production/batches', data);

    if (res.success) {
      message.success('Berhasil menambahkan seri');
      dispatch(fetchBatches({ page: 1 }));
    } else {
      message.error('Terjadi kesalahan ketika menambahkan seri');
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchBatch = (batchID) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/production/batches/${batchID}`);

    if (res.success) {
      dispatch({
        type: constant.BATCH_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteBatch = (batch) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/production/batches/${batch.id}`);

    if (res.success) {
      message.success('Berhasil menghapus seri');
      dispatch(fetchBatches({ page: 1 }));
    } else {
      message.error(res.message ?? 'Terjadi kesalahan ketika menghapus seri');
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateBatch = (formData, batch, { history }) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/production/batches/${batch.id}`, formData);

    if (res.success) {
      message.success('Berhasil mengubah seri');
      dispatch(fetchBatch(batch.id));
      history.push(`/production/batch/${batch.id}`);
    } else {
      message.error(res.message ?? 'Terjadi kesalahan ketika mengubah seri');
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateBatchStatus = (formData, batch, { history }) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/production/batches/${batch.id}/status`, formData);

    if (res.success) {
      message.success('Berhasil mengubah status seri');
      dispatch(fetchBatch(batch.id));
      history.push(`/production/batch/${batch.id}`);
    } else {
      message.error(res.message ?? 'Terjadi kesalahan ketika mengubah status seri');
    }
  } catch (err) {
    console.error(err);
  }
};

export const createBatchFabric = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/production/fabrics', data);

    if (res.success) {
      message.success('Berhasil Menambah Kain');

      dispatch(fetchBatches({ page: 1 }));
    } else {
      message.error(res.message ?? 'Terjadi kesalahan ketika menghitung modal kain');
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchBatchReturns = (id, page = 1) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/production/batches/${id}/returns?page=${page}`);

    if (res.success) {
      dispatch({
        type: constant.BATCH_RETURN_HISTORY_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createBatchReturn = (data, batch) => async (dispatch) => {
  dispatch(loading());

  const formData = {
    ...data,
    production_tailor_id: batch.production_tailor_id,
    production_batch_id: batch.id,
  };

  try {
    const { data: res } = await api.post('/production/returns', formData);

    if (res.success) {
      message.success('Berhasil Menambah Jahitan Kembali');

      dispatch(fetchBatch(batch.id));
      dispatch(fetchBatchReturns(batch.id));
    } else {
      message.error(res.message ?? 'Terjadi kesalahan ketika menambahkan barang kembali');
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateBatchReturn = (data, returnData, batch) => async (dispatch) => {
  dispatch(loading());

  const formData = {
    ...data,
    production_tailor_id: batch.production_tailor_id,
    production_batch_id: batch.id,
  };

  try {
    const { data: res } = await api.put(`/production/returns/${returnData.id}`, formData);

    if (res.success) {
      message.success('Berhasil Mengedit Jahitan Kembali');

      dispatch(fetchBatch(batch.id));
      dispatch(fetchBatchReturns(batch.id));
    } else {
      message.error(res.message ?? 'Terjadi kesalahan ketika mengubah barang kembali');
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteBatchReturn = (returns, batch) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/production/returns/${returns.id}`);

    if (res.success) {
      message.success('Berhasil Menghapus barang kembali');

      dispatch(fetchBatch(batch.id));
      dispatch(fetchBatchReturns(batch.id));
    } else {
      message.error(res.message ?? 'Terjadi kesalahan ketika menghapus barang kembali');
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteBatchFabrics = (fabrics, batch) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/production/fabrics/${fabrics.id}`);

    if (res.success) {
      message.success('Berhasil Menghapus kain');

      dispatch(fetchBatch(batch.id));
    } else {
      message.error(res.message ?? 'Terjadi kesalahan ketika menghapus kain');
    }
  } catch (err) {
    console.error(err);
  }
};
