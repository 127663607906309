import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Upload,
  message,
  Modal,
  Card,
  PageHeader,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { actions } from '../../../modules/reducers';
import api from '../../../utils/api';
import { getImage } from '../../../utils/image';

const { TextArea } = Input;
const { confirm } = Modal;

const Detail = ({ dispatch, match, category, history }) => {
  const { params } = match;

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    dispatch(actions.fetchCategory(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (category) {
      setFileList([
        {
          uid: uuid(),
          name: /[^/]*$/.exec(category.picture),
          status: 'done',
          url: category.picture,
          thumbUrl: getImage(category.picture, 100),
        },
      ]);
    }
  }, [category]);

  const onFinish = (data) => {
    const formData = {
      ...data,
      picture: fileList[0].url,
    };

    dispatch(actions.updateCategory(formData, category, { history }));
  };

  const props = {
    customRequest: async (options) => {
      const data = new FormData();
      data.append('folder', 'categories');
      data.append('images', options.file);

      try {
        const { data: res } = await api.post('/upload/v2', data);

        if (res.success) {
          options.onSuccess(res.data[0], options.file);
        } else {
          options.onError(res.message);
        }
      } catch (err) {
        options.onError(err);
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} gambar berhasil diupload`);
      }
      if (info.file.status === 'error') {
        message.error(`${info.file.name} gambar gagal diupload`);
      }

      let fileList = [...info.fileList];

      fileList = fileList.slice(-1);

      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.secure_url;
        }
        return file;
      });

      setFileList(fileList);
    },
    listType: 'picture',
  };

  return (
    <Row>
      <Col span={24}>
        <PageHeader
          title="Model"
          subTitle={category ? category.name : ''}
          onBack={() => history.goBack()}
          style={{ padding: '0 0 16px' }}
        />
      </Col>
      <Col span={12}>
        <Card>
          {category ? (
            <Form
              layout="vertical"
              initialValues={category}
              onFinish={onFinish}
            >
              <Form.Item
                name="picture"
                label="Gambar"
                rules={[
                  {
                    required: true,
                    message: 'Mohon upload gambar model yang ingin digunakan',
                  },
                ]}
              >
                <Upload {...props} fileList={fileList}>
                  <Button>
                    <UploadOutlined />
                    Upload gambar model
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                name="name"
                label="Nama Model"
                rules={[
                  { required: true, message: 'Mohon masukkan nama model' },
                ]}
              >
                <Input placeholder="Nama" />
              </Form.Item>
              <Form.Item
                name="description"
                label="Deskripsi"
                rules={[
                  { required: true, message: 'Mohon masukkan deskripsi model' },
                ]}
              >
                <TextArea rows={4} placeholder="Deskripsi" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Simpan
                </Button>
                <Button
                  type="danger"
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    confirm({
                      title: 'Hapus Model',
                      content: 'Apakah anda yakin ingin menghapus model ini?',
                      onOk: () => {
                        dispatch(actions.deleteCategory(category, { history }));
                      },
                    })
                  }
                >
                  Hapus
                </Button>
              </Form.Item>
            </Form>
          ) : null}
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ category }) => ({
  category: category.category,
  loading: category.loading,
});

export default connect(mapStateToProps)(Detail);
