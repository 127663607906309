import React, { useState } from 'react';

import { Modal, Form, message, Upload, Button, Switch } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import api from '../../../utils/api';

const Create = ({ visible, onCreate, onCancel, editSlide }) => {
  const [form] = Form.useForm();

  const [uploaded, setUploaded] = useState([]);

  const props = {
    customRequest: async (options) => {
      const data = new FormData();
      data.append('folder', 'sliders');
      data.append('images', options.file);

      try {
        const { data: res } = await api.post('/upload/v2', data);

        if (res.success) {
          options.onSuccess(res.data, options.file);

          const uploadResponse = res.data.map(({ secure_url }) => secure_url);

          setUploaded(uploadResponse);
        }
      } catch (err) {
        options.onError(err);
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      }
      if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Tambah Slide Baru"
      okText="Simpan"
      cancelText="Batal"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();

            if (values.is_active === undefined) {
              values.is_active = false;
            }

            values.src = uploaded;

            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={editSlide}
      >
        <Form.Item
          name="src"
          label="Foto Slider"
          rules={[
            {
              required: true,
              message: 'Foto tidak boleh kosong!',
            },
          ]}
        >
          <Upload {...props}>
            <Button>
              <UploadOutlined /> Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item name="is_active" label="Aktifkan Slider">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Create;
