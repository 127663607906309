import * as constant from './constants';

export const initialState = {
  loading: false,
  options: [],
};

const optionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.OPTIONS_LOADING:
      return {
        ...state,
        loading: action.loading,
        options: [],
      };
    case constant.OPTIONS_FULFILLED:
      return {
        ...state,
        loading: false,
        options: action.payload,
      };
    default:
      return state;
  }
};

export default optionReducer;
