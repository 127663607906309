import { message } from 'antd';

import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.POS_ORDER_HISTORIES_LOADING,
});

export const fetchPOSOrderHistories = (params = {}) => async (dispatch) => {
  dispatch(loading());

  const q = new URLSearchParams();
  const keys = ['page', 'actor', 'is_barang_sortir', 'start_date', 'end_date'];
  for (const key in params) {
    if (params[key] && keys.includes(key)) {
      q.append(key, params[key]);
    }
  }

  try {
    const { data: res } = await api.get(`/pos-product-histories?` + q.toString());

    if (res.success) {
      dispatch({
        type: constant.POS_ORDER_HISTORIES_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchPOSOrderHistory = (id) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/pos-product-histories/${id}`);

    if (res.success) {
      dispatch({
        type: constant.POS_ORDER_HISTORY_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const cancelPOSOrderHistory = (id, history) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/pos-product-histories/${id}`);
    if (res.success) {
      dispatch({ type: constant.POS_ORDER_HISTORY_CANCELLED });
      message.success('Berhasil Cancel History Barang Masuk');
      history.goBack();
    } else {
      if (res.message !== '') {
        message.error(res.message);
      }
    }
  } catch (err) {
    console.error(err);
  }
};
