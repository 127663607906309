import React, { useState } from 'react';
import {
  Button, Modal, Form, Input, message, Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import api from '../../../utils/api';

const CategoryCreate = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  const [uploaded, setUploaded] = useState([]);

  const props = {
    customRequest: async (options) => {
      const data = new FormData();
      data.append('folder', 'categories');
      data.append('images', options.file);

      try {
        const { data: res } = await api.post('/upload/v2', data);

        if (res.success) {
          options.onSuccess(res.data, options.file);

          const uploadResponse = res.data.map(({ secure_url }) => secure_url);

          setUploaded(uploadResponse);
        }
      } catch (err) {
        options.onError(err);
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} gambar berhasil diupload`);
      }
      if (info.file.status === 'error') {
        message.error(`${info.file.name} gambar gagal diupload`);
      }
    },
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Tambah Model Baru"
      okText="Simpan"
      cancelText="Batal"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            values.picture = uploaded;
            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="name"
          label="Nama Model"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan nama model!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Deskripsi Model"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan deskripsi model!',
            },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Form.Item
          name="picture"
          label="Gambar Model"
          rules={[
            {
              required: true,
              message: 'Mohon upload gambar model!',
            },
          ]}
        >
          <Upload {...props}>
            <Button>
              <UploadOutlined />
              {' '}
              Klik untuk upload
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryCreate;
