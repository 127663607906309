import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';

import { Layout } from 'antd';

import Sidebar from './components/Sidebar';
import ScrollToTop from './components/ScrollToTop';

import { CategoryList, CategoryDetail } from './pages/Category';
import { ProductList, ProductDetail, ProductBarcode } from './pages/Product';
import { PosProductList, PosProductDetail } from './pages/PosProduct';
import { CustomerList, CustomerDetail } from './pages/Customer';
import Slide from './pages/Slide';
import Login from './pages/Login';
import {
  OrderList,
  OrderDetail,
  OrderInvoice,
  OrderCheckList,
} from './pages/Order';
import {
  PosOrderList,
  PosOrderDetail,
  PosOrderInvoice,
  PosOrderLetterOfShipping,
} from './pages/PosOrder';
import {
  PosOrderHistoriesDetail,
  PosOrderHistoriesList,
} from './pages/PosOrderHistories';
import { BankAccountDetail } from './pages/BankAccount';
import Option from './pages/Option';
import CourierEdit from './pages/Option/Courier/Edit';
import PrivateRoute from './components/PrivateRoute';
import Stats from './pages/Stats';
import PrintQR from './pages/PrintQR';
import Carts from './pages/Carts';
import CartCheckList from './pages/Carts/Checklist';
import { TailorList } from './pages/Tailor';
import { ModelList } from './pages/Model';
import { BatchDetail, BatchList } from './pages/Batch';
import { ExpenseList } from './pages/Expense';
import { PeriodList } from './pages/Period';
import { FeePage, FeeInvoice } from './pages/Fee';
import { FabricList } from './pages/Fabrics';
import { CutterExpenseList } from './pages/CutterExpense';
import { CutterFeePage } from './pages/CutterFee';

const { Content } = Layout;

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Layout style={{ minHeight: '100vh', overflow: 'hidden' }}>
        <Sidebar />
        <Layout style={{ padding: '24px' }}>
          <Content>
            <Switch>
              <PrivateRoute exact path="/" component={Stats} />
              <PrivateRoute exact path="/categories" component={CategoryList} />
              <PrivateRoute
                exact
                path="/categories/:id"
                component={CategoryDetail}
              />
              <PrivateRoute exact path="/products" component={ProductList} />
              <PrivateRoute
                exact
                path="/products/:id"
                component={ProductDetail}
              />
              <PrivateRoute
                exact
                path="/pos-products"
                component={PosProductList}
              />
              <PrivateRoute
                exact
                path="/pos-products/:id"
                component={PosProductDetail}
              />
              <PrivateRoute exact path="/barcode" component={ProductBarcode} />
              <PrivateRoute exact path="/orders/:id" component={OrderDetail} />
              <PrivateRoute
                exact
                path="/orders/:id/invoice"
                component={OrderInvoice}
              />
              <PrivateRoute
                exact
                path="/orders/:id/checklist"
                component={OrderCheckList}
              />
              <PrivateRoute exact path="/orders" component={OrderList} />
              <PrivateRoute exact path="/pos-orders" component={PosOrderList} />
              <PrivateRoute
                exact
                path="/pos-orders/:id"
                component={PosOrderDetail}
              />
              <Route
                exact
                path="/pos-orders/:id/invoice"
                component={PosOrderInvoice}
              />
              <PrivateRoute
                exact
                path="/pos-orders/:id/letter-of-shipping"
                component={PosOrderLetterOfShipping}
              />
              <PrivateRoute
                exact
                path="/pos-order-histories"
                component={PosOrderHistoriesList}
              />
              <PrivateRoute
                exact
                path="/pos-order-histories/:id"
                component={PosOrderHistoriesDetail}
              />
              <PrivateRoute exact path="/customers" component={CustomerList} />
              <PrivateRoute
                exact
                path="/customers/:id"
                component={CustomerDetail}
              />
              <PrivateRoute exact path="/slides" component={Slide} />
              <PrivateRoute
                exact
                path="/bank-accounts/:id"
                component={BankAccountDetail}
              />
              <PrivateRoute exact path="/options" component={Option} />
              <PrivateRoute exact path="/courier/:id" component={CourierEdit} />
              <PrivateRoute exact path="/stats" component={Stats} />
              <PrivateRoute exact path="/print-qr" component={PrintQR} />
              <PrivateRoute exact path="/carts" component={Carts} />
              <PrivateRoute
                exact
                path="/carts/print"
                component={CartCheckList}
              />
              <PrivateRoute exact path="/production/tailor" component={TailorList} />
              <PrivateRoute exact path="/production/model" component={ModelList} />
              <PrivateRoute exact path="/production/batch" component={BatchList} />
              <PrivateRoute exact path="/production/batch/:id" component={BatchDetail} />
              <PrivateRoute exact path="/production/tailor/expense" component={ExpenseList} />
              <PrivateRoute exact path="/production/cutter/expense" component={CutterExpenseList} />
              <PrivateRoute exact path="/production/tailor/fee" component={FeePage} />
              <PrivateRoute exact path="/production/cutter/fee" component={CutterFeePage} />
              <PrivateRoute exact path="/production/period" component={PeriodList} />
              <PrivateRoute exact path="/production/fabric" component={FabricList} />
              <PrivateRoute
                exact
                path="/production/fee/:id/invoice"
                component={FeeInvoice}
              />

              <Route exact path="/login" component={Login} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
}

export default App;
