import * as constant from './constants';

export const initialState = {
  loading: false,
  periods: [],
  period: null,
  pagination: null,
};

const periodReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.PERIOD_LOADING:
      return {
        ...state,
        loading: action.loading,
        periods: [],
        period: null,
        pagination: null,
      };
    case constant.PERIODS_FULFILLED:
      return {
        ...state,
        loading: false,
        periods: action.payload,
        pagination: action.pagination,
      };
    case constant.PERIOD_FULFILLED:
      return {
        ...state,
        loading: false,
        period: action.payload,
      };
    default:
      return state;
  }
};

export default periodReducer;
