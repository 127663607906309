import { message } from 'antd';

import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.PRODUCT_LOADING,
});

export const fetchProducts = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(
      `/product?page=${params.page}&name=${params.name}&category_id=${params.category_id}`
    );

    if (res.success) {
      dispatch({
        type: constant.PRODUCTS_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createProduct = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/product', data);

    if (res.success) {
      dispatch(fetchProducts({ page: 1, name: '', category_id: '' }));
      message.success('Berhasil Menambah Produk');
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteProduct = (datas) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete('/product', {
      data: {
        ids: datas,
      },
    });

    if (res.success) {
      dispatch(fetchProducts({ page: 1, name: '', category_id: '' }));
      message.success('Berhasil Menghapus Produk');
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchProduct = (id) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/product/${id}`);

    if (res.success) {
      dispatch({
        type: constant.PRODUCT_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchStockAudit = (id, page = 1) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/product/${id}/stocks?page=${page}`);

    if (res.success) {
      dispatch({
        type: constant.PRODUCT_STOCK_AUDIT_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateProduct = (formData, product, { history }) => async (
  dispatch
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/product/${product.id}`, formData);

    if (res.success) {
      dispatch(fetchProduct(product.id));
      message.success('Berhasil Update produk');
      history.push(`/products/${product.id}`);
    }
  } catch (err) {
    console.error(err);
  }
};

export const UpdateStockProduct = (data, product) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post(`/product/${product.id}/stocks`, data);

    if (res.success) {
      dispatch({
        type: constant.PRODUCT_STOCK_UPDATED,
      });
      dispatch(fetchProduct(product.id));
      dispatch(fetchStockAudit(product.id));

      message.success('Berhasil Update Stock');
    }
  } catch (err) {
    console.error(err);
  }
};
