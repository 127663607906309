import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.SLIDER_LOADING,
});

export const fetchSliders = ({ page } = {page: 1}) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/slide?page=${page}`);

    if (res.success) {
      dispatch({
        type: constant.SLIDERS_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createSlider = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/slide', data);

    if (res.success) {
      message.success('Berhasil Menambahkan Slider');
      dispatch(fetchSliders());
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchSlider = (slideId) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/slide/${slideId}`);

    if (res.success) {
      dispatch({
        type: constant.SLIDER_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteSlider = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/slide/${data.id}`);

    if (res.success) {
      message.success('Berhasil Menghapus Slider');
      dispatch(fetchSliders());
    }
  } catch (err) {
    console.error(err);
  }
};

export const toggleSlider = (data, id) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/slide/${id}`, data);

    if (res.success) {
      message.success('Berhasil Mengedit Slider');
      dispatch(fetchSliders());
    }
  } catch (err) {
    console.error(err);
  }
};
