import * as constant from './constants';

export const initialState = {
  fees: null,
  expenseCategories: [],
  pagination: null,
};

const productionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.PRODUCTION_LOADING:
      return {
        ...state,
        loading: action.loading,
        expenseCategories: [],
        pagination: null,
      };
    case constant.PRODUCTION_EXPENSE_CATEGORY_FULFILLED:
      return {
        ...state,
        loading: false,
        expenseCategories: action.payload,
      };
    case constant.PRODUCTION_FEES_FULFILLED:
      return {
        ...state,
        loading: false,
        fees: action.payload,
      };
    default:
      return state;
  }
};

export default productionReducer;
