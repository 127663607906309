import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  PageHeader,
  Row,
  Table,
  Modal,
  Space,
  Form,
  Card,
  Input,
  Select,
  List,
  Typography,
  Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { actions } from '../../../modules/reducers';
import CreateModal from '../components/modal';
import { pcsToDozen } from '../../../utils/number';
import CreateFabricModal from '../components/fabric/create';
import api from '../../../utils/api';
import { batchStatusColorMapper, mapBatchStatusString } from '../utils';

const { confirm } = Modal;
const { Option } = Select;

const BatchPage = ({
  dispatch, batches, query, pagination, history,
}) => {
  const page = query.get('page') || 1;
  const status = query.get('status') || null;
  const tailor_id = query.get('tailor_id');
  const name = query.get('name');

  const [form] = Form.useForm();

  const [addModalVisible, setAddModalVisible] = useState(false);
  const [createFabricModalVisible, setCreateFabricModalVisible] = useState(false);

  const [batchSelection, setBatchSelection] = useState([]);

  const [ShowFilter, setShowFilter] = useState(false);
  const [tailors, setTailors] = useState([]);

  useEffect(() => {
    dispatch(actions.fetchBatches({
      page, status, tailor_id, name,
    }));
  }, [dispatch, page, status, tailor_id, name]);

  useEffect(() => {
    (async () => {
      const { data: res } = await api.get('/production/batches');
      setBatchSelection(res.data);
    })();
  }, []);

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/production/batch?${query.toString()}`);
  };

  const handleDeleteClicked = (batch) => confirm({
    title: 'Hapus Motif',
    content: 'Anda yakin ingin menghapus Motif ini?',
    onOk: () => {
      dispatch(actions.deleteBatch(batch));
    },
  });

  const onCreate = (values) => {
    dispatch(actions.createBatch({ ...values }));
    setAddModalVisible(false);
  };

  const onFabricCreate = (values) => {
    dispatch(actions.createBatchFabric({ ...values }));
    setCreateFabricModalVisible(false);
  };

  const handleFilter = (value) => {
    Object.keys(value).forEach((param) => {
      if (param === 'tailor_id') {
        if (value[param] && value[param].value > 0) {
          query.set(param, value[param].value);
        }
      } else if (param === 'status') {
        if (value[param] !== null) {
          query.set(param, value[param]);
        }
      } else if (value[param]) {
        query.set(param, value[param]);
      }
    });
    query.set('page', 1);
    history.push(`/production/batch?${query.toString()}`);
  };

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/tailors?name=${value}`);
    setTailors(res.data);
  }, 500);

  const columns = [
    {
      title: 'Seri',
      key: 'batch_no',
      dataIndex: 'batch_no',
      render: (_, record) => <Typography.Title level={3}>{record.batch_no}</Typography.Title>,
    },
    {
      title: 'Model',
      key: 'id',
      render: (_, record) => {
        const data = [
          { label: 'Model', value: record.name },
          { label: 'Penjahit', value: record.production_tailor ? record.production_tailor.name : 'Belum ada penjahit' },
        ];

        return (
          <List
            size="small"
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text strong>
                  {item.label}
                  :
                </Typography.Text>
                {' '}
                {item.value}
              </List.Item>
            )}
          />
        );
      },
    },
    {
      title: 'Tanggal',
      dataIndex: 'cutting_at',
      key: 'cutting_at',
      render: (_, record) => {
        const data = [
          { label: 'Tanggal Potong', value: record.cutting_at ? format(new Date(record.cutting_at), 'dd MMM yyyy') : 'Belum melakukan pemotongan' },
          { label: 'Tanggal Jahit', value: record.sewing_at ? format(new Date(record.sewing_at), 'dd MMM yyyy') : 'Belum melakukan jahitan' },
        ];

        return (
          <List
            size="small"
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text>{item.label}</Typography.Text>
                {' '}
                {item.value}
              </List.Item>
            )}
          />
        );
      },
    },
    {
      title: 'Ongkos',
      key: 'ongkos',
      render: (_, record) => {
        const data = [
          { label: 'Ongkos Jahit', value: `Rp. ${record?.sewing_price?.toLocaleString('ID')}` },
          { label: 'Ongkos Potong', value: `Rp. ${record?.cutting_price?.toLocaleString('ID')}` },
          { label: 'Harga Aksesoris', value: `Rp. ${record?.accessories_price?.toLocaleString('ID')}` },
        ];
        return (
          <List
            size="small"
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text strong>
                  {item.label}
                  :
                </Typography.Text>
                {' '}
                {item.value}
              </List.Item>
            )}
          />
        );
      },
    },
    {
      title: 'Jahit',
      dataIndex: 'sewing_total',
      key: 'sewing_total',
      render: (_, record) => {
        const total = pcsToDozen(record.sewing_total);
        const result = pcsToDozen(record.sewing_result);
        const leftOut = pcsToDozen(record.sewing_left_out);
        const data = [
          { label: 'Jumlah Jahit', value: `${total.dozen} lusin ${total.pcs} pcs` },
          { label: 'Sudah Kembali', value: `${result.dozen} lusin ${result.pcs} pcs` },
          { label: 'Sisa Jahit', value: `${leftOut.dozen} lusin ${leftOut.pcs} pcs` },
        ];
        return (
          <List
            size="small"
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text strong>
                  {item.label}
                  :
                </Typography.Text>
                {' '}
                {item.value}
              </List.Item>
            )}
          />
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <Tag color={batchStatusColorMapper[record.status]}>{mapBatchStatusString[record.status]}</Tag>,
    },
    {
      title: 'Untung (%)',
      dataIndex: 'profit_percentage',
      key: 'profit_percentage',
      render: (_, record) => `${record.profit_percentage}%`,
    },
    {
      title: 'Action',
      key: 'action',
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation(); // this will avoid onRow being called
        },
      }),
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => history.push(`/production/batch/${record.id}`)}>Detail</Button>
          <Button
            type="danger"
            onClick={() => handleDeleteClicked(record)}
            disabled={record.status === 0}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <PageHeader title="Daftar Seri" style={{ padding: '0 0 16px' }} />
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => setAddModalVisible(true)} style={{ marginRight: 16 }}>
              <PlusOutlined />
              Tambah
            </Button>
            <Button type="primary" onClick={() => setCreateFabricModalVisible(true)}>
              <PlusOutlined />
              Hitung Modal Kain
            </Button>
            <Button type="primary" style={{ marginLeft: 15 }} onClick={() => setShowFilter(!ShowFilter)}>
              <FilterOutlined />
              {' '}
              {ShowFilter ? 'Sembunyikan Filter' : 'Filter'}
            </Button>
          </div>
          <div style={{ marginBottom: 16 }}>
            {ShowFilter ? (
              <Form form={form} layout="horizontal" onFinish={handleFilter} initialValues={{ status }}>
                <Card title="Filter berdasarkan">
                  <Col span={8}>
                    <Form.Item name="name">
                      <Input placeholder="Nama" />
                    </Form.Item>
                    <Form.Item name="tailor_id">
                      <Select
                        labelInValue
                        showSearch
                        placeholder="Ketik Nama Penjahit"
                        onSearch={onSearch}
                        filterOption={false}
                      >
                        {tailors.map((tailor) => (
                          <Option key={tailor.id} value={tailor.id}>
                            {tailor.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="status">
                      <Select defaultActiveFirstOption>
                        <Option value={null}>Semua</Option>
                        <Option value={0}>Sudah Selesai</Option>
                        <Option value={1}>Dalam Proses</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <>
                    <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                      Terapkan Filter
                    </Button>
                    <Button
                      type="primary"
                      htmlType="reset"
                      onClick={(e) => {
                        e.preventDefault();
                        form.resetFields();
                        history.push('/production/batch');
                      }}
                      danger
                    >
                      Reset Filter
                    </Button>
                  </>
                </Card>
              </Form>
            ) : null}
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={batches}
            onChange={handleTableChange}
            pagination={{
              current: +query.get('page') || 1,
              total: (pagination && pagination.total) || 0,
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <CreateModal
        visible={addModalVisible}
        onCreate={onCreate}
        onCancel={() => setAddModalVisible(false)}
      />
      <CreateFabricModal
        visible={createFabricModalVisible}
        onCreate={onFabricCreate}
        onCancel={() => setCreateFabricModalVisible(false)}
        batches={batchSelection}
      />
    </>
  );
};

const mapStateToProps = ({ batch }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    query,
    batches: batch.batches,
    pagination: batch.pagination,
  };
};

export default connect(mapStateToProps)(BatchPage);
