import * as constant from './constants';

export const initialState = {
  loading: false,
  posOrderHistories: [],
  posOrderHistory: null,
  pagination: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.POS_ORDER_HISTORIES_LOADING:
      return {
        ...state,
        loading: action.loading,
        posOrderHistories: [],
        pagination: null,
      };
    case constant.POS_ORDER_HISTORIES_FULFILLED:
      return {
        ...state,
        loading: false,
        posOrderHistories: action.payload,
        pagination: action.pagination,
      };
    case constant.POS_ORDER_HISTORY_FULFILLED:
      return {
        ...state,
        loading: false,
        posOrderHistory: action.payload,
      };
    case constant.POS_ORDER_HISTORY_CANCELLED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
