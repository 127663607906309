/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  PDFViewer,
  Page,
  Text,
  View,
  StyleSheet,
  Document,
} from '@react-pdf/renderer';
import format from 'date-fns/format';

import { chunk, range } from 'lodash';
import { actions } from '../../../modules/reducers';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100vh',
    margin: -24,
    padding: 0,
    position: 'fixed',
    border: 'none',
  },
  invoiceWrapper: {
    height: '49.99vh',
  },
  crop: {
    borderBottom: 2,
    borderBottomStyle: 'dashed',
  },
  header: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginVertical: 8,
    marginHorizontal: 16,
    fontSize: 11,
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 8,
    marginHorizontal: 16,
    flex: 1,
  },
  tableHead: {
    marginVertical: 4,
    borderTop: 1,
    borderBottom: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 16,
    fontSize: 11,
  },
  tableRow: {
    paddingBottom: '5pt',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    fontSize: 11,
  },
  tableFooter: {
    borderTop: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingVertical: 6,
    marginTop: 'auto',
    fontSize: 11,
  },
  tableCell: {
    width: '20%',
    fontSize: 11,
  },
  tableFooterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 8,
    marginHorizontal: 16,
    flex: 1,
  },
  footerWrapper: {
    // position: 'fixed',
    // bottom: 0,
    // left: 0,
    // right: 0,
  },
  footer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginHorizontal: 16,
    marginTop: 'auto',
    fontSize: 11,
    borderTop: 1,
    paddingVertical: 8,
  },
});

const feeTableWidthMapper = {
  KATEGORI: '50%',
  JUMLAH: '40%',
};

const Invoice = ({
  dispatch, match, fees, options,
}) => {
  const {
    params: { id },
  } = match;

  useEffect(() => {
    dispatch(actions.fetchOptions());
    dispatch(actions.fetchProductionFees(id));
  }, [dispatch, id]);

  let mappedOptions;
  let chunkedDebits = [];
  let chunkedCredits = [];

  if (options.length !== 0) {
    let split;
    mappedOptions = options.map((option) => {
      split = {
        ...split,
        [option.key]: option.value,
      };

      return split;
    });

    mappedOptions = mappedOptions[mappedOptions.length - 1];
  }

  if (fees) {
    chunkedCredits = chunk(fees.credits, 12);
    chunkedDebits = chunk(fees.debits, 12);
  }

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        {fees && [...new Array(Math.max(chunkedCredits.length, chunkedDebits.length))].map((_, idx) => (
          <Page size="A4" orientation="portrait" key={idx}>
            {range(0, 2).map((copies) => {
              const copiesStyle = {
                ...styles.invoiceWrapper,
                ...(copies === 0 ? { ...styles.crop } : {}),
              };

              return (
                <View key={copies} style={copiesStyle}>
                  <View>
                    <View style={styles.header}>
                      <View>
                        <Text style={{ marginBottom: 2 }}>HELLO Collection</Text>
                        <Text style={{ marginBottom: 2 }}>
                          PRODUCT PAKAIAN WANITA
                        </Text>
                        {mappedOptions && (
                        <>
                          <Text style={{ marginBottom: 2 }}>
                            {mappedOptions.address}
                          </Text>
                          <Text style={{ marginBottom: 2 }}>
                            Telp:
                            {' '}
                            {mappedOptions.office_phone}
                          </Text>
                          <Text style={{ marginBottom: 2 }}>
                            HP:
                            {' '}
                            {mappedOptions.office_phone}
                            {' '}
                            WA:
                            {' '}
                            {mappedOptions.whatsapp_number}
                          </Text>
                        </>
                        )}
                      </View>
                      <View>
                        <Text style={{ marginBottom: 2 }}>
                          Periode:
                          {' '}
                          {format(new Date(fees.start_date), 'dd/MM/yyyy')}
                          {' '}
                          -
                          {' '}
                          {format(new Date(fees.end_date), 'dd/MM/yyyy')}
                        </Text>
                        <Text style={{ marginBottom: 2 }}>
                          Jakarta,
                          {' '}
                          {format(new Date(fees.paid_at), 'dd/MM/yyyy')}
                        </Text>
                        <Text style={{ marginBottom: 2 }}>
                          Kepada Yth:
                          {' '}
                          {fees.production_tailor.name}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.header}>
                      <View style={{ flex: 0.5 }}>
                        <Text style={{ fontSize: 18 }}>Pendapatan</Text>
                      </View>
                      <View style={{ flex: 0.5 }}><Text style={{ fontSize: 18 }}>Potongan</Text></View>
                    </View>
                  </View>
                  <View style={styles.body} wrap>
                    <View
                      style={{ flex: 0.5 }}
                      render={({ pageNumber }) => {
                        const key = pageNumber - 1;

                        return chunkedDebits.length > key ? chunkedDebits[key].map((debit) => (
                          <View key={debit.id} style={styles.tableRow}>
                            <View
                              style={[
                                styles.tableCell,
                                { width: feeTableWidthMapper.KATEGORI },
                              ]}
                            >
                              <Text>
                                {debit.expense_item.production_expense_category.name}
                                {' '}
                                -
                                {' '}
                                {debit.expense_item.description}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.tableCell,
                                { width: feeTableWidthMapper.JUMLAH },
                              ]}
                            >
                              <Text style={{ textAlign: 'right' }}>
                                Rp.
                                {' '}
                                {Number(debit.expense_item.amount).toLocaleString('id')}
                              </Text>
                            </View>
                          </View>
                        )) : null;
                      }}
                    />
                    <View
                      style={{ flex: 0.5 }}
                      render={({ pageNumber }) => {
                        const key = pageNumber - 1;

                        return chunkedCredits.length > key ? chunkedCredits[key].map((credit) => (
                          <View key={credit.id} style={styles.tableRow}>
                            <View
                              style={[
                                styles.tableCell,
                                { width: feeTableWidthMapper.KATEGORI },
                              ]}
                            >
                              <Text>
                                {credit.expense_item.production_expense_category.name}
                                {' '}
                                -
                                {' '}
                                {credit.expense_item.description}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.tableCell,
                                { width: feeTableWidthMapper.JUMLAH },
                              ]}
                            >
                              <Text style={{ textAlign: 'right' }}>
                                Rp.
                                {' '}
                                {Number(credit.expense_item.amount).toLocaleString('id')}
                              </Text>
                            </View>
                          </View>
                        )) : null;
                      }}
                    />
                  </View>
                  <View style={styles.footerWrapper} fixed>
                    <View style={styles.tableFooterWrapper}>
                      <View style={{ flex: 0.5 }}>
                        <View style={styles.tableFooter}>
                          <Text>Total Pendapatan: </Text>
                          <Text>
                            Rp.
                            {' '}
                            {fees.total_debit.toLocaleString('id')}
                          </Text>
                        </View>
                      </View>
                      <View style={{ flex: 0.5 }}>
                        <View style={styles.tableFooter}>
                          <Text>Total Potongan: </Text>
                          <Text>
                            Rp.
                            {' '}
                            {fees.total_credit.toLocaleString('id')}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.footer}>
                      <Text style={{ textAlign: 'center' }}>
                        Penerimaan Bersih: Rp.
                        {' '}
                        {fees.take_home_pay.toLocaleString('id')}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </Page>
        ))}
      </Document>
    </PDFViewer>
  );
};

const mapStateToProps = ({ production, option }) => ({
  fees: production.fees,
  options: option.options,
});

export default connect(mapStateToProps)(Invoice);
