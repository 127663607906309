import React, { useState } from 'react';
import debounce from 'lodash/debounce';

import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Modal,
  Select,
  InputNumber,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import config from '../../config';
import api from '../../utils/api';
import { getImage } from '../../utils/image';

const { Option } = Select;
const { confirm } = Modal;

const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => (
      <>
        <h3>{record.name}</h3>
        <p style={{ fontSize: 12 }}>
          SKU:
          {' '}
          {record.sku}
        </p>
      </>
    ),
  },
  {
    title: 'Gambar',
    dataIndex: 'pictures',
    key: 'pictures',
    render: (_, record) => {
      let pictures = [];
      try {
        pictures = JSON.parse(record.pictures);
      } catch (error) {}

      return (
        <Space>
          {pictures.map((picture, idx) => (
            <div
              key={idx}
              style={{ maxWidth: 90, overflow: 'hidden', borderRadius: 4 }}
            >
              <img
                src={getImage(picture, 100)}
                alt="slider"
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          ))}
        </Space>
      );
    },
  },
  {
    title: 'Stock',
    dataIndex: 'stock_qty',
    key: 'stock_qty',
    render: (text) => (text ? (
      <span>
        {text}
        {' '}
        pcs.
      </span>
    ) : 'Kosong'),
  },
  {
    title: 'Dicetak',
    dataIndex: 'qtyToPrint',
    key: 'qtyToPrint',
    render: (text) => `${text} pcs.`,
  },
];

const PrintQR = () => {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [qtyToPrint, setQtyToPrint] = useState(0);

  const storage = localStorage.getItem(config.BARCODE_KEY) || '[]';
  let barcodes = [];
  try {
    barcodes = JSON.parse(storage);
  } catch (error) {}
  const [print, setPrint] = useState(barcodes);

  const onSelectRow = (_, rows) => {
    setSelectedRowKeys(rows.map((r) => r.id));
  };

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/product?name=${value || ''}`);
    setProducts(res.data);
  }, 500);

  const handlePrintQR = () => {
    const protocol = process.env.REACT_APP_STAGE === 'production' ? 'https://' : 'http://';

    window.open(`${protocol + window.location.host}/barcode`, '_blank');
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <h2>Cetak QRCode</h2>
        </Col>
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button
              type="danger"
              style={{ marginRight: 10 }}
              disabled={selectedRowKeys.length === 0}
              onClick={() => confirm({
                title: 'Batal Cetak',
                content: 'Batal mencetak QR motif ini?',
                onOk: () => {
                  const items = print.filter(
                    (x) => !selectedRowKeys.includes(x.id),
                  );
                  localStorage.setItem(
                    config.BARCODE_KEY,
                    JSON.stringify(items),
                  );
                  setPrint(items);
                  setSelectedRowKeys([]);
                },
              })}
            >
              Hapus
            </Button>
            <Button type="primary" onClick={() => setOpen(true)}>
              <PlusOutlined />
              {' '}
              Tambah
            </Button>

            <Button
              type="primary"
              disabled={print.length === 0}
              onClick={handlePrintQR}
              style={{ marginLeft: 16 }}
            >
              Cetak QR
            </Button>
          </div>
          <Table
            rowKey="id"
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectRow,
            }}
            columns={columns}
            dataSource={print}
            pagination={false}
          />
        </Col>
      </Row>
      <Modal
        destroyOnClose
        visible={open}
        title="Tambah Produk Baru"
        okText="Simpan"
        cancelText="Batal"
        onCancel={() => {
          setOpen(false);
          setSelected(null);
        }}
        onOk={() => {
          setOpen(false);
          setSelected(null);
          setQtyToPrint(0);

          const items = [...print, { ...selected, qtyToPrint }];
          setPrint(items);
          localStorage.setItem(config.BARCODE_KEY, JSON.stringify(items));
        }}
      >
        <Select
          showSearch
          placeholder="Ketik nama motif"
          filterOption={false}
          onSearch={onSearch}
          onSelect={(value) => {
            const product = products.find((x) => x.id === value);
            if (product) {
              setSelected(product);
              setQtyToPrint(product.stock_qty);
            }
          }}
          style={{ width: '100%' }}
        >
          {products.map((product) => (
            <Option key={product.id} value={product.id}>
              {product.name}
            </Option>
          ))}
        </Select>
        <InputNumber
          min={1}
          value={qtyToPrint}
          placeholder="Jumlah barang dalam pcs"
          onChange={(value) => setQtyToPrint(value)}
          style={{
            marginTop: 16,
            width: '50%',
          }}
        />
      </Modal>
    </>
  );
};

export default PrintQR;
