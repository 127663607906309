import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.FABRIC_LOADING,
});

export const fetchFabrics = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const qs = Object.keys(params)
      .filter((x) => params[x])
      .map((x) => `${x}=${params[x]}`)
      .join('&');

    const { data: res } = await api.get(`/production/fabrics?${qs}`);

    if (res.success) {
      dispatch({
        type: constant.FABRICS_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createFabric = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/production/fabrics', data);

    if (res.success) {
      message.success('Berhasil menambahkan kain');
      dispatch(fetchFabrics({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchFabric = (fabricID) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/production/fabrics/${fabricID}`);

    if (res.success) {
      dispatch({
        type: constant.FABRIC_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteFabric = (fabric) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/production/fabrics/${fabric.id}`);

    if (res.success) {
      message.success('Berhasil menghapus kain.');
      dispatch(fetchFabrics({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateFabric = (formData, fabric) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/production/fabrics/${fabric.id}`, formData);

    if (res.success) {
      message.success('Berhasil mengubah kain.');
      dispatch(fetchFabrics({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};
