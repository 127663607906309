import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import moment from 'moment';

import {
  Form,
  Button,
  Row,
  Col,
  InputNumber,
  Table,
  Space,
  PageHeader,
  Card,
  Select,
  DatePicker,
  Statistic,
  Modal,
  Upload,
  Tag,
  Typography,
} from 'antd';

import { format } from 'date-fns';
import { UploadOutlined } from '@ant-design/icons';
import { actions } from '../../../modules/reducers';
import api from '../../../utils/api';
import { pcsToDozen, countQuantity, dozenToPcs } from '../../../utils/number';
import CreateReturnModal from '../components/return/create';
import { getImage } from '../../../utils/image';
import StatusUpdateModal from '../components/status/modal';
import { batchStatusColorMapper, mapBatchStatus, mapBatchStatusString } from '../utils';

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const Detail = ({
  dispatch,
  match,
  batch,
  history,
  returnPagination,
  feeHistory,
  returnHistory,
}) => {
  const { params } = match;

  const [returnHistoryPage, setReturnHistoryPage] = useState(1);

  const [isEditable, setIsEditable] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState([]);
  const [uploadedFileList, setUploadedFileList] = useState([]);
  const [totalDozen, setTotalDozen] = useState(0);
  const [totalPcs, setTotalPcs] = useState(0);
  const [resultDozen, setResultDozen] = useState(0);
  const [resultPcs, setResultPcs] = useState(0);

  const [tailors, setTailors] = useState([]);
  const [models, setModels] = useState([]);
  const [tailor, setTailor] = useState(null);
  const [model, setModel] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [createReturnModal, setCreateReturnModal] = useState(false);
  const [statusUpdateModalOpened, setStatusUpdateModalOpened] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchBatch(params.id));
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(actions.fetchBatchReturns(params.id, returnHistoryPage));
  }, [dispatch, params, returnHistoryPage]);

  useEffect(() => {
    if (batch) {
      const sewingTotal = pcsToDozen(batch.sewing_total);
      setTotalDozen(sewingTotal.dozen);
      setTotalPcs(sewingTotal.pcs);

      const sewingResult = pcsToDozen(batch.sewing_result);
      setResultDozen(sewingResult.dozen);
      setResultPcs(sewingResult.pcs);

      if (batch.production_tailor) {
        setTailor({
          key: batch.production_tailor.id,
          label: batch.production_tailor.name,
          value: batch.production_tailor.id,
        });
      }

      setModel({
        key: batch.production_model.id,
        label: batch.production_model.name,
        value: batch.production_model.id,
      });

      if (batch.production_period) {
        setSelectedPeriod({
          key: batch.production_period.id,
          label: `${format(new Date(batch.production_period.start_at), 'yyyy')} - 
          ${format(new Date(batch.production_period.end_at), 'yyyy')}`,
          value: batch.production_period.id,
        });
      }

      let pictures = [];
      let fileList = [];

      if (batch.buku_potong.length > 0) {
        try {
          pictures = batch.buku_potong;

          fileList = pictures
            .map((picture, idx) => ({
              uid: `rc-upload-${idx}`,
              name: /[^/]*$/.exec(picture),
              status: 'done',
              url: picture,
              thumbUrl: getImage(picture, 100),
            }))
            .sort((a, b) => {
              const first = a.uid.split('-');
              const second = b.uid.split('-');
              return second[second.length - 1] - first[first.length - 1];
            });
        } catch (err) {
          console.error(err);
        }
      }

      setUploadedFileList(fileList);
      setUploadedUrl(pictures);
      setIsEditable(batch.is_editable && batch.return_history.length > 0);
      setSelectedStatus(mapBatchStatus[batch?.status]);
    }
  }, [batch]);

  useEffect(() => {
    (async () => {
      const { data: res } = await api.get('/production/periods');
      setPeriods(res.data);
    })();
  }, []);

  const onFinish = (data) => {
    delete data.model;
    delete data.tailor;
    delete data.sewing_total;
    delete data.sewing_result;
    delete data.buku_potong;

    const formData = {
      ...data,
      cutting_at: data.cutting_at ? format(new Date(data.cutting_at), 'yyyy-MM-dd') : null,
      sewing_at: data.sewing_at ? format(new Date(data.sewing_at), 'yyyy-MM-dd') : null,
      sewing_total: dozenToPcs(totalDozen, totalPcs),
      sewing_result: dozenToPcs(resultDozen, resultPcs),
      production_tailor_id: tailor ? tailor.value : null,
      production_model_id: model.value,
      production_period_id: selectedPeriod ? selectedPeriod.value : null,
      buku_potong: uploadedUrl,
    };

    dispatch(actions.updateBatch(formData, batch, { history }));
  };

  const onFinishCreateReturn = (data) => {
    dispatch(actions.createBatchReturn(data, batch));
    setCreateReturnModal(false);
  };

  const onMarkComplete = (data) => {
    // console.log(data)
    dispatch(actions.updateBatchStatus(data, batch, { history }));
    setStatusUpdateModalOpened(false);
  };

  const handleDeleteReturn = (returns) => (
    confirm({
      title: 'Hapus barang kembali',
      content: 'Anda yakin ingin menghapus barang ini?',
      onOk: () => {
        dispatch(actions.deleteBatchReturn(returns, batch));
      },
    })
  );

  const onModelSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/models?name=${value || ''}`);
    setModels(res.data);
  }, 500);

  const onTailorSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/tailors?name=${value || ''}`);
    setTailors(res.data);
  }, 500);

  const handleReturnTableChange = (p) => {
    setReturnHistoryPage(p.current);
  };

  const renderBatchStatistics = () => (
    <>
      <Statistic
        title="Jahitan Selesai"
        value={batch?.sewing_result}
        valueStyle={{ color: '#1890ff' }}
        style={{
          minHeight: 100,
          padding: 16,
          borderRight: '1px solid #EFEFEF',
        }}
      />
      <Statistic
        title="Sisa Jahitan"
        value={batch?.sewing_left_out}
        valueStyle={{ color: '#1890ff' }}
        style={{
          minHeight: 100,
          padding: 16,
          borderRight: '1px solid #EFEFEF',
        }}
      />
      <Statistic
        title="Profit"
        value={`${batch?.profit_percentage}%`}
        valueStyle={{ color: '#1890ff' }}
        style={{
          minHeight: 100,
          padding: 16,
          borderRight: '1px solid #EFEFEF',
        }}
      />
    </>
  );

  const handleRemovePicture = (picture) => {
    setUploadedUrl(uploadedUrl.filter((url) => picture.url !== url));
  };

  const handleChangeUpload = (info) => {
    let filelist = [...info.fileList];

    filelist = filelist
      .map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }

        return file;
      })
      .sort((a, b) => {
        const first = a.uid.split('-');
        const second = b.uid.split('-');
        return second[second.length - 1] - first[first.length - 1];
      });

    setUploadedFileList(filelist);
  };

  const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 10 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 7, offset: 7 },
  };

  const returnHistoryColumns = [
    {
      title: 'Total Harga',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (_, record) => `Rp. ${record?.total_price?.toLocaleString('ID')}`,
    },
    {
      title: 'Total Kembali',
      dataIndex: 'total_returned',
      key: 'total_returned',
      render: (value) => countQuantity(value),
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="danger"
            onClick={() => {
              handleDeleteReturn(record);
            }}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  const fabricColumns = [
    {
      title: 'Daftar Kain',
      dataIndex: 'fabrics',
      key: 'fabrics',
      render: (_, record) => {
        const fabrics = JSON.parse(record.fabrics);

        return (
          <ul>
            {
              fabrics.map((fabric) => (
                <li>
                  {fabric.name}
                  . Harga: Rp.
                  {' '}
                  {fabric?.price_per_yard?.toLocaleString('ID')}
                  . Penggunaan:
                  {' '}
                  {fabric.total_fabrics_in_yard}
                </li>
              ))
            }
          </ul>
        );
      },
    },
    {
      title: 'Penggunaan rata-rata',
      dataIndex: 'usage_avg',
      key: 'usage_avg',
      render: (usage_avg) => `${usage_avg} / yard`,
    },
  ];

  const props = {
    customRequest: async (options) => {
      const data = new FormData();
      data.append('folder', 'products');
      data.append('images', options.file);

      try {
        const { data: res } = await api.post('/upload/v2', data);

        if (res.success) {
          options.onSuccess(res.data, options.file);

          const uploaded = res.data.map(({ secure_url }) => secure_url);
          setUploadedUrl((uploadState) => [...uploadState, ...uploaded]);
        } else {
          options.onError(res.message);
        }
      } catch (err) {
        options.onError(err);
      }
    },
    multiple: true,
    onChange: handleChangeUpload,
    onRemove: handleRemovePicture,
    defaultFileList: uploadedFileList !== undefined && [...uploadedFileList],
    listType: 'picture',
  };

  return (
    <>
      <PageHeader
        title="Detail Seri"
        subTitle={batch ? batch.batch_no : ''}
        style={{ padding: '0 0 16px' }}
        onBack={() => history.goBack()}
        extra={
          <Button onClick={() => setStatusUpdateModalOpened(true)} type="primary" disabled={mapBatchStatus[batch?.status] === 'FINISHED'}>Ubah Status Seri</Button>
        }
      />
      <Row gutter={16}>
        <Col span={24} style={{ paddingBottom: '8px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Card>
                {batch ? (
                  <Form
                    {...formItemLayout}
                    layout="horizontal"
                    name="normal_login"
                    className="login-form"
                    labelCol={{ span: 7 }}
                    initialValues={{
                      ...batch,
                      cutting_at: batch.cutting_at ? moment(batch.cutting_at) : null,
                      sewing_at: batch.sewing_at ? moment(batch.sewing_at) : null,
                      model: {
                        key: batch.production_model.id,
                        label: batch.name,
                        value: batch.production_model.id,
                      },
                      tailor: {
                        key: batch.production_tailor ? batch.production_tailor.id : null,
                        label: batch.production_tailor ? batch.production_tailor.name : null,
                        value: batch.production_tailor ? batch.production_tailor.id : null,
                      },
                      period: {
                        key: batch.production_period ? batch.production_period.id : null,
                        label: batch.production_period ? `${format(new Date(batch.production_period.start_at), 'yyyy')} - 
                        ${format(new Date(batch.production_period.end_at), 'yyyy')}` : null,
                        value: batch.production_period ? batch.production_period.id : null,
                      },
                      status: mapBatchStatus[batch.status],
                    }}
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="model"
                      label="Model baju"
                      rules={[
                        {
                          required: true,
                          message: 'Mohon pilih model yang ingin dijahit',
                        },
                      ]}
                    >
                      <Select
                        labelInValue
                        showSearch
                        placeholder="Ketik Nama Model"
                        onSearch={onModelSearch}
                        filterOption={false}
                        onSelect={(value) => setModel(value)}
                        disabled={isEditable}
                      >
                        {models.map((model) => (
                          <Option key={model.id} value={model.id}>
                            {model.model.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="tailor"
                      label="Penjahit"
                    >
                      <Select
                        labelInValue
                        showSearch
                        placeholder="Ketik Nama Penjahit"
                        onSearch={onTailorSearch}
                        filterOption={false}
                        onSelect={(value) => setTailor(value)}
                        disabled={isEditable}
                      >
                        {tailors.map((tailor) => (
                          <Option key={tailor.id} value={tailor.id}>
                            {tailor.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="status"
                      label="Status"
                    >
                      <Select disabled={mapBatchStatus[batch?.status] !== "DRAFT"} onChange={(value) => setSelectedStatus(value)}>
                        <Option value="INPROGRESS">Diproses</Option>
                        <Option value="FINISHED">Selesai</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name="period" label="Periode">
                      <Select
                        labelInValue
                        filterOption={false}
                        onSelect={(value) => setSelectedPeriod(value)}
                        disabled={isEditable}
                      >
                        {periods.map((period) => (
                          <Option key={period.id} value={period.id}>
                            {format(new Date(period.start_at), 'yyyy')}
                            {' '}
                            -
                            {' '}
                            {format(new Date(period.end_at), 'yyyy')}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="sewing_at"
                      label="Dijahit di tanggal"
                      rules={selectedStatus !== 'DRAFT'
                      && [
                        {
                          required: true,
                          message: 'Mohon masukkan tanggal jahitan!',
                        },
                      ]}
                    >
                      <DatePicker disabled={isEditable} format="DD-MM-YYYY" />
                    </Form.Item>
                    <Form.Item
                      name="cutting_at"
                      label="Dipotong di tanggal"
                      rules={selectedStatus !== 'DRAFT'
                      && [
                        {
                          required: true,
                          message: 'Mohon masukkan tanggal pemotongan!',
                        },
                      ]}
                    >
                      <DatePicker disabled={isEditable} format="DD-MM-YYYY" />
                    </Form.Item>
                    <Form.Item
                      name="sewing_price"
                      label="Harga Jahitan"
                      rules={[
                        {
                          required: true,
                          message: 'Mohon masukkan harga jahitan!',
                        },
                      ]}
                    >
                      <InputNumber disabled={isEditable} />
                    </Form.Item>
                    <Form.Item
                      name="sewing_total"
                      label="Total Jahitan"
                      rules={selectedStatus !== 'DRAFT'
                      && [
                        {
                          required: true,
                          message: 'Mohon masukkan total jahitan!',
                        },
                      ]}
                    >
                      <InputNumber
                        value={totalDozen}
                        disabled={isEditable}
                        onChange={(value) => {
                          setTotalDozen(value);
                        }}
                      />
                      {' '}
                      lusin
                      <InputNumber
                        value={totalPcs}
                        disabled={isEditable}
                        style={{ marginLeft: 8 }}
                        onChange={(value) => {
                          setTotalPcs(value);
                        }}
                      />
                      {' '}
                      pcs
                    </Form.Item>
                    <Form.Item
                      name="sewing_result"
                      label="Hasil Jahitan"
                      rules={[
                        {
                          required: true,
                          message: 'Mohon masukkan hasil jahitan!',
                        },
                      ]}
                    >
                      <InputNumber value={resultDozen} disabled />
                      {' '}
                      lusin
                      <InputNumber value={resultPcs} disabled style={{ marginLeft: 8 }} />
                      {' '}
                      pcs
                    </Form.Item>
                    <Form.Item
                      name="buku_potong"
                      label="Gambar"
                      rules={[
                        {
                          required: true,
                          message: 'Mohon upload gambar produk!',
                        },
                      ]}
                    >
                      <Upload {...props} fileList={uploadedFileList} disabled={isEditable}>
                        <Button disabled={isEditable}>
                          <UploadOutlined />
                          {' '}
                          Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item {...buttonItemLayout}>
                      <Button type="primary" htmlType="submit">
                        Simpan
                      </Button>
                    </Form.Item>
                  </Form>
                ) : null}
              </Card>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Card
                  title="Statistik"
                  extra={(
                    <>
                      Status:
                      {' '}
                      <Tag color={batchStatusColorMapper[batch?.status]}>{mapBatchStatusString[batch?.status]}</Tag>
                    </>
                  )}
                >
                  {batch ? (
                    <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
                      {renderBatchStatistics()}
                    </Space>
                  ) : null}
                </Card>
                <Card
                  title="Barang Kembali"
                  extra={(
                    <Button type="primary" onClick={() => setCreateReturnModal(true)}>
                      Input Barang Kembali
                    </Button>
                  )}
                >
                  <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <div style={{ display: 'flex' }}>
                      <Text style={{ flex: 1 }}>
                        Catatan:
                        {' '}
                        {batch?.notes ? batch?.notes : 'Belum ada catatan'}
                      </Text>
                      <Button style={{ marginLeft: 'auto' }} type="primary" onClick={() => setStatusUpdateModalOpened(true)}>
                        Ubah
                      </Button>
                    </div>
                    <Table
                      rowKey="id"
                      columns={returnHistoryColumns}
                      dataSource={returnHistory}
                      onChange={handleReturnTableChange}
                      pagination={{
                        current: parseInt(returnHistoryPage) || 1,
                        total: (returnPagination && returnPagination.total) || 0,
                        showSizeChanger: false,
                      }}
                    />
                  </Space>

                  <CreateReturnModal
                    visible={createReturnModal}
                    onCreate={onFinishCreateReturn}
                    onCancel={() => setCreateReturnModal(false)}
                  />
                </Card>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ paddingBottom: '8px' }}>
          <Card title="Kain Jahit">
            <Table
              columns={fabricColumns}
              dataSource={batch?.fabrics}
              onChange={handleReturnTableChange}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
      <StatusUpdateModal
        visible={statusUpdateModalOpened}
        onCancel={() => setStatusUpdateModalOpened(false)}
        onComplete={onMarkComplete}
        batch={batch}
      />
    </>
  );
};

const mapStateToProps = ({ batch }) => ({
  batch: batch.batch,
  loading: batch.loading,
  expensePagination: batch.expenseHistoryPagination,
  feePagination: batch.feeHistoryPagination,
  returnPagination: batch.returnHistoryPagination,
  feeHistory: batch.feeHistory,
  expenseHistory: batch.expenseHistory,
  returnHistory: batch.returnHistory,
});

export default connect(mapStateToProps)(Detail);
