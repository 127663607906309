import * as constant from './constants';

export const initialState = {
  loading: false,
  expenses: [],
  expense: null,
  pagination: null,
};

const expenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.EXPENSE_LOADING:
      return {
        ...state,
        loading: action.loading,
        expenses: [],
        expense: null,
        pagination: null,
      };
    case constant.EXPENSES_FULFILLED:
      return {
        ...state,
        loading: false,
        expenses: action.payload,
        pagination: action.pagination,
      };
    case constant.EXPENSE_FULFILLED:
      return {
        ...state,
        loading: false,
        expense: action.payload,
      };
    default:
      return state;
  }
};

export default expenseReducer;
