import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.BANKACCOUNT_LOADING,
});

export const fetchBankAccounts = () => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get('/bank');

    if (res.success) {
      dispatch({
        type: constant.BANKACCOUNTS_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const CreateBankAccount = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/bank', data);

    if (res.success) {
      message.success('Berhasil Menambahkan Bank Akun');
      dispatch(fetchBankAccounts());
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchBankAccount = (accountId) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/bank/${accountId}`);

    if (res.success) {
      dispatch({
        type: constant.BANKACCOUNT_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteBankAccount = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete('/bank', {
      data: {
        ids: data,
      },
    });

    if (res.success) {
      message.success('Berhasil Menghapus Akun Bank');
      dispatch(fetchBankAccounts());
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateBankAccount = (formData, bankAccount, { history }) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/bank/${bankAccount.id}`, formData);

    if (res.success) {
      message.success('Berhasil Mengedit Akun Bank');
      dispatch(fetchBankAccounts());
      history.push('/options');
    }
  } catch (err) {
    console.error(err);
  }
};
