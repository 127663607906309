import * as constant from './constants';

export const initialState = {
  loading: false,
  models: [],
  model: null,
  pagination: null,
};

const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.MODEL_LOADING:
      return {
        ...state,
        loading: action.loading,
        models: [],
        model: null,
        pagination: null,
      };
    case constant.MODELS_FULFILLED:
      return {
        ...state,
        loading: false,
        models: action.payload,
        pagination: action.pagination,
      };
    case constant.MODEL_FULFILLED:
      return {
        ...state,
        loading: false,
        model: action.payload,
      };
    default:
      return state;
  }
};

export default modelReducer;
