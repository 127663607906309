import React from 'react';
import { useCookies } from 'react-cookie';

import { Layout, Menu } from 'antd';
import {
  DashboardOutlined,
  LogoutOutlined,
  PictureOutlined,
  QrcodeOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SkinOutlined,
  SwapOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link, useLocation, useHistory } from 'react-router-dom';

import config from '../../config';
import '../../App.css';

const { Sider } = Layout;
const { SubMenu } = Menu;

const hideMenu = [
  /^(\/orders)\/\d+\/(invoice)$/,
  /^(\/production\/fee)\/\d+\/(invoice)$/,
  /^(\/orders)\/\d+\/(checklist)$/,
  /^(\/pos-orders)\/\d+\/(invoice)$/,
  /^(\/pos-orders)\/\d+\/(letter-of-shipping)$/,
  /^(\/carts\/print)$/,
  /^\/(barcode)$/,
  /^\/login$/,
];

const Sidebar = () => {
  const [, , removeCookie] = useCookies([config.COOKIE_NAME]);
  const location = useLocation();
  const history = useHistory();

  const hideSidebar = hideMenu.some((x) => x.test(location.pathname));

  if (hideSidebar) {
    return null;
  }

  return (
    <Sider width={200} className="site-layout-background" theme="dark">
      <div
        style={{
          height: 32,
          background: 'rgba(255, 255, 255, 0.2)',
          margin: 16,
          textAlign: 'center',
          color: '#FFF',
          paddingTop: 4,
          fontWeight: 700,
        }}
      >
        HELLO PGMTA
      </div>
      <Menu
        mode="inline"
        theme="dark"
        style={{ height: '100%', borderRight: 0 }}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/" icon={<DashboardOutlined />}>
          <span>Dashboard</span>
          <Link to="/" />
        </Menu.Item>
        <Menu.Item key="/carts" icon={<ShoppingCartOutlined />}>
          <span>Keranjang Belanja</span>
          <Link to="/carts" />
        </Menu.Item>
        <Menu.Item key="/categories" icon={<TagOutlined />}>
          <span>Model</span>
          <Link to="/categories" />
        </Menu.Item>

        <SubMenu key="productionsub" icon={<SkinOutlined />} title="Produksi">
          <Menu.Item key="/production/tailor" icon={<UserOutlined />}>
            <span>Penjahit</span>
            <Link to="/production/tailor" />
          </Menu.Item>
          <Menu.Item key="/production/model" icon={<UserOutlined />}>
            <span>Model</span>
            <Link to="/production/model" />
          </Menu.Item>
          <Menu.Item key="/production/period" icon={<UserOutlined />}>
            <span>Periode</span>
            <Link to="/production/period" />
          </Menu.Item>
          <Menu.Item key="/production/fabric" icon={<UserOutlined />}>
            <span>Hitung Modal Kain</span>
            <Link to="/production/fabric" />
          </Menu.Item>
          <Menu.Item key="/production/batch" icon={<UserOutlined />}>
            <span>Seri</span>
            <Link to="/production/batch" />
          </Menu.Item>
          <SubMenu key="expensesub" icon={<SkinOutlined />} title="Pendapatan/Potongan">
            <Menu.Item key="/production/tailor/expense">
              <span>Penjahit</span>
              <Link to="/production/tailor/expense" />
            </Menu.Item>
            <Menu.Item key="/production/cutter/expense">
              <span>Tukang Potong</span>
              <Link to="/production/cutter/expense" />
            </Menu.Item>
          </SubMenu>
          <SubMenu key="feesub" icon={<UserOutlined />} title="Gaji">
            <Menu.Item key="/production/tailor/fee">
              <span>Penjahit</span>
              <Link to="/production/tailor/fee" />
            </Menu.Item>
            <Menu.Item key="/production/cutter/fee">
              <span>Tukang Potong</span>
              <Link to="/production/cutter/fee" />
            </Menu.Item>
          </SubMenu>
        </SubMenu>

        <SubMenu key="productsub" icon={<SkinOutlined />} title="Produk">
          <Menu.Item key="/products">
            <span>Web</span>
            <Link to="/products" />
          </Menu.Item>
          <Menu.Item key="/pos-products">
            <span>Toko</span>
            <Link to="/pos-products" />
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="/print-qr" icon={<QrcodeOutlined />}>
          <span>Cetak QR</span>
          <Link to="/print-qr" />
        </Menu.Item>
        <SubMenu key="sub2" icon={<ShoppingOutlined />} title="Pesanan">
          <Menu.Item key="/orders">
            <span>Web</span>
            <Link to="/orders" />
          </Menu.Item>
          <Menu.Item key="/pos-orders">
            <span>Toko</span>
            <Link to="/pos-orders" />
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/pos-order-histories" icon={<SwapOutlined />}>
          <span>History Barang Masuk</span>
          <Link to="/pos-order-histories" />
        </Menu.Item>
        <Menu.Item key="/customers" icon={<UserOutlined />}>
          <span>Pelanggan</span>
          <Link to="/customers" />
        </Menu.Item>
        <Menu.Item key="/slides" icon={<PictureOutlined />}>
          <span>Slides</span>
          <Link to="/slides" />
        </Menu.Item>
        <Menu.Item key="/options" icon={<SettingOutlined />}>
          <span>Pengaturan</span>
          <Link to="/options" />
        </Menu.Item>
        <Menu.Item
          key="/logout"
          icon={<LogoutOutlined style={{ color: 'red' }} />}
        >
          <Link
            to="/logout"
            style={{ color: 'red' }}
            onClick={(e) => {
              e.preventDefault();
              removeCookie('access_token');
              setTimeout(() => history.push('/login'), 1000);
            }}
          >
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
