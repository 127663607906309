import * as constant from './constants';

export const initialState = {
  loading: false,
  orders: [],
  order: null,
  pagination: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.POS_ORDER_LOADING:
      return {
        ...state,
        loading: action.loading,
        orders: [],
        pagination: null,
      };
    case constant.POS_ORDERS_FULFILLED:
      return {
        ...state,
        loading: false,
        orders: action.payload,
        pagination: action.pagination,
      };
    case constant.POS_ORDER_FULFILLED:
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
