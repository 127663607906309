import * as constant from './constants';

export const initialState = {
  loading: false,
  fees: [],
  fee: null,
  pagination: null,
};

const feeReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.FEE_LOADING:
      return {
        ...state,
        loading: action.loading,
        fees: [],
        fee: null,
        pagination: null,
      };
    case constant.FEES_FULFILLED:
      return {
        ...state,
        loading: false,
        fees: action.payload,
        pagination: action.pagination,
      };
    case constant.FEE_FULFILLED:
      return {
        ...state,
        loading: false,
        fee: action.payload,
      };
    default:
      return state;
  }
};

export default feeReducer;
