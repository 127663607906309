import * as constant from './constants';

export const initialState = {
  loading: false,
  totalStats: null,
  graphStats: [],
};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.STATS_LOADING:
      return {
        ...state,
        loading: action.loading,
        totalStats: null,
        graphStats: [],
      };
    case constant.TOTAL_STATS_FULFILLED:
      return {
        ...state,
        loading: false,
        totalStats: action.payload,
      };
    case constant.GRAPH_STATS_FULFILLED:
      return {
        ...state,
        loading: false,
        graphStats: action.payload,
      };
    default:
      return state;
  }
};

export default statsReducer;
