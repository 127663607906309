import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import {
  Form,
  Button,
  Row,
  Col,
  Upload,
  InputNumber,
  Table,
  PageHeader,
  Card,
  Input,
  Select,
  Switch,
  Space,
} from 'antd';
import { format } from 'date-fns';

import StockUpdate from '../../Product/components/stockUpdate';
import { actions } from '../../../modules/reducers';
import api from '../../../utils/api';
import { statusOrderMapper } from '../../../utils/status';
import { getImage } from '../../../utils/image';
import { pcsToDozen, countQuantity } from '../../../utils/number';

import './detail.css';

const { Option } = Select;

const Detail = ({
  dispatch, match, product, productMeta, history, stockAudit, pagination,
}) => {
  const { params } = match;

  const [showStockModal, setShowStockModal] = useState(false);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [generatedSKU, setSKU] = useState('');
  const [uploadedFileList, setUploadedFileList] = useState([]);
  const [dozen, setDozen] = useState(0);
  const [pcs, setPcs] = useState(0);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    dispatch(actions.fetchPOSProduct(params.id));
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(actions.fetchPOSStockAudit(params.id, page));
  }, [dispatch, params, page]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (product) {
      const stock = pcsToDozen(product.stock_qty);
      setDozen(stock.dozen);
      setPcs(stock.pcs);

      setPrice(product.price);
      setDiscount(product.sale_price);

      let pictures = [];
      let fileList = [];

      try {
        pictures = JSON.parse(product.pictures).flat(1);

        fileList = pictures
          .map((picture, idx) => ({
            uid: `rc-upload-${idx}`,
            name: /[^/]*$/.exec(picture),
            status: 'done',
            url: picture,
            thumbUrl: getImage(picture, 100),
          }))
          .sort((a, b) => {
            const first = a.uid.split('-');
            const second = b.uid.split('-');
            return second[second.length - 1] - first[first.length - 1];
          });
      } catch (err) {
        console.error(err);
      }

      setUploadedFileList(fileList);

      if (product.sku) {
        setTimeout(() => {
          setSKU(product.sku);
        }, 300);
      }
    }
  }, [product]);

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/category?name=${value || ''}`);
    setCategories(res.data);
  }, 500);

  const handleTableChange = (p) => {
    setPage(p.current);
  };

  const props = {
    defaultFileList: uploadedFileList !== undefined && [...uploadedFileList],
    listType: 'picture',
  };

  const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 10 },
  };

  const columns = [
    {
      title: 'User',
      dataIndex: 'actor',
      key: 'actor',
    },
    {
      title: 'Tipe',
      dataIndex: 'action',
      key: 'action',
      render: (value) => <span>{statusOrderMapper[value]}</span>,
    },
    {
      title: 'Jumlah',
      dataIndex: 'qty',
      key: 'qty',
      render: (value) => countQuantity(value),
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Tanggal',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => format(new Date(value), 'dd-MM-yyyy'),
    },
  ];

  return (
    <>
      <PageHeader
        title="Detail Produk"
        subTitle={product ? product.name : ''}
        style={{ padding: '0 0 16px' }}
        onBack={() => history.goBack()}
      />
      <Row>
        <Col span={11}>
          <Card>
            {product ? (
              <>
                <Row flex justify="end">
                  <Button type="primary" htmlType="a" href={`/products/${product.id}`}>
                    Edit Produk
                  </Button>
                </Row>
                <Form
                  {...formItemLayout}
                  layout="horizontal"
                  name="normal_login"
                  className="login-form"
                  labelCol={{ span: 7 }}
                  initialValues={{
                    ...product,
                    category: {
                      key: product.category.id,
                      label: product.category.name,
                      value: product.category.id,
                    },
                  }}
                >
                  <Form.Item name="category" label="Model">
                    <Select
                      labelInValue
                      showSearch
                      placeholder="Ketik Nama Model"
                      onSearch={onSearch}
                      filterOption={false}
                      disabled
                    >
                      {categories.map((category) => (
                        <Option key={category.id} value={category.id}>
                          {category.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item name="batch_code" label="Kode Penjahit/Produksi">
                    <Input disabled />
                  </Form.Item>

                  <Form.Item name="name" label="Nama">
                    <Input disabled />
                  </Form.Item>

                  <Form.Item label="SKU">
                    <Input value={generatedSKU} style={{ background: '#EEE' }} />
                  </Form.Item>

                  <Form.Item label="Stok Produk">
                    <InputNumber disabled value={dozen} />
                    {' '}
                    lusin
                    <InputNumber disabled value={pcs} style={{ marginLeft: 8 }} />
                    {' '}
                    pcs
                  </Form.Item>
                  <Form.Item name="price" label="Harga Produk">
                    <InputNumber defaultValue={0} disabled />
                  </Form.Item>
                  <Form.Item name="sale_price" label="Persentase Diskon">
                    <InputNumber
                      defaultValue={0}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace('%', '')}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label="Harga Diskon">
                    <InputNumber disabled value={discount > 0 ? price - price * (discount / 100) : 0} />
                  </Form.Item>
                  <Form.Item name="on_sale" valuePropName="checked" label="Diskon">
                    <Switch disabled />
                  </Form.Item>
                  <Form.Item name="pictures" label="Gambar">
                    <Upload {...props} fileList={uploadedFileList} />
                  </Form.Item>
                </Form>
              </>
            ) : null}
          </Card>
        </Col>
        <Col span={12} offset={1}>
          <Card title="Manajemen Stock" style={{ marginBottom: 20 }}>
            <Space>
              <Button type="primary" onClick={() => setShowStockModal(true)}>
                Edit Stock
              </Button>
            </Space>
          </Card>
          <Card title="History Stock">
            <Table
              rowKey="id"
              columns={columns}
              dataSource={stockAudit}
              onChange={handleTableChange}
              pagination={{
                current: parseInt(page) || 1,
                total: (pagination && pagination.total) || 0,
                showSizeChanger: false,
              }}
            />
            <StockUpdate
              visible={showStockModal}
              onCreate={(data) => {
                dispatch(actions.updateStockPOSProduct(data, productMeta)).then(() => {
                  dispatch(actions.fetchPOSProduct(params.id));
                  dispatch(actions.fetchPOSStockAudit(params.id, page));
                });
              }}
              onCancel={() => setShowStockModal(false)}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ posProduct }) => ({
  product: posProduct.product,
  productMeta: posProduct.productMeta,
  stockAudit: posProduct.stockAudit,
  loading: posProduct.loading,
  pagination: posProduct.stockAuditPagination,
});

export default connect(mapStateToProps)(Detail);
