/* eslint-disable import/prefer-default-export */
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.STATS_LOADING,
});

export const fetchTotalStats = () => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get('/stats/total');

    if (res.success) {
      dispatch({
        type: constant.TOTAL_STATS_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchGraphStats = () => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get('/stats/graph');

    if (res.success) {
      dispatch({
        type: constant.GRAPH_STATS_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};
