import React from 'react';
import {
  Modal, Form, Input, InputNumber,
} from 'antd';

const { TextArea } = Input;

const AddressCreate = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Tambah Alamat Baru"
      okText="Simpan"
      cancelText="Batal"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item
          name="name"
          label="Nama Alamat"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan Nama Alamat',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="receiver"
          label="Nama Penerima"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan Nama Penerima',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Nomor telepon penerima"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan Nomor telepon penerima',
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="address"
          label="Alamat Lengkap"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan Alamat Lengkap',
            },
          ]}
        >
          <TextArea rows={4} placeholder="Alamat Lengkap" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddressCreate;
