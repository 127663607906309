import React, { useEffect } from 'react';
import { PageHeader, Row, Col, Space, Button, Modal, Card, Descriptions, Table } from 'antd';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { countQuantity } from '../index';
import { actions } from '../../../modules/reducers';

const { confirm } = Modal;

const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) =>
      record.pos_product.detail.on_sale
        ? `${record.pos_product.detail.name} (disc ${record.pos_product.detail.sale_price}%)`
        : record.pos_product.detail.name,
  },
  {
    title: 'Gambar',
    dataIndex: 'picture',
    key: 'picture',
    render: (_, record) => {
      let pictures = [];
      try {
        pictures = JSON.parse(record.pos_product.detail.pictures);
      } catch (error) {}

      return (
        <Space size="middle">
          {pictures.map((picture, idx) => (
            <div style={{ maxWidth: 60, overflow: 'hidden', borderRadius: 4 }} key={idx}>
              <img src={picture} alt="slider" style={{ width: '100%', height: '100%' }} />
            </div>
          ))}
        </Space>
      );
    },
  },
  {
    title: 'Harga Barang',
    dataIndex: 'price',
    key: 'price',
    render: (_, record) =>
      record.pos_product.detail.on_sale
        ? `Rp. ${record.pos_product.detail.sale_price.toLocaleString('ID')}`
        : `Rp. ${record.pos_product.detail.price.toLocaleString('ID')}`,
  },
  {
    title: 'Banyak Pembelian',
    dataIndex: 'qty',
    key: 'qty',
    render: (text) => countQuantity(text),
  },
  {
    title: 'Subtotal',
    dataIndex: 'subtotal',
    key: 'subtotal',
    render: (_, record) => {
      const price = record.pos_product.detail.on_sale
        ? record.pos_product.detail.sale_price
        : record.pos_product.detail.price;
      const subtotal = record.qty * price;
      return `Rp. ${subtotal.toLocaleString('ID')}`;
    },
  },
];

function Detail({ dispatch, match, history, posOrderHistory }) {
  const { params } = match;

  useEffect(() => {
    dispatch(actions.fetchPOSOrderHistory(params.id));
  }, [dispatch, params.id]);

  return (
    <>
      <PageHeader
        title="Detail History Barang Masuk"
        subTitle=""
        style={{ padding: '0 0 16px' }}
        onBack={() => history.goBack()}
      />
      <Row>
        {posOrderHistory && (
          <>
            <Col span={24} style={{ marginBottom: 32 }}>
              <Space direction="horizontal" style={{ marginBottom: 16 }}>
                <Button
                  type="danger"
                  target="_blank"
                  onClick={() => {
                    confirm({
                      title: 'Hapus History Barang Masuk',
                      content: 'Apakah anda yakin ingin menghapus history barang masuk ini?',
                      onOk: () => {
                        dispatch(actions.cancelPOSOrderHistory(params.id, history));
                      },
                    });
                  }}
                >
                  Cancel
                </Button>
              </Space>

              <Card title="Deskripsi">
                <Descriptions bordered size="small">
                  <Descriptions.Item label="Tanggal">
                    {format(new Date(posOrderHistory.invoice_date), 'dd-MM-yyyy')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Aktor">{posOrderHistory.actor}</Descriptions.Item>
                  <Descriptions.Item label="Total">
                    {`Rp. ${posOrderHistory.total.toLocaleString('id')}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Barang BS">
                    {posOrderHistory.is_barang_sortir === 1 ? 'Ya' : 'Tidak'}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: 32 }}>
              <Table columns={columns} dataSource={posOrderHistory.items} />
            </Col>
          </>
        )}
      </Row>
    </>
  );
}

const mapStateToProps = ({ posOrderHistories }) => ({
  posOrderHistory: posOrderHistories.posOrderHistory,
});

export default connect(mapStateToProps)(Detail);
