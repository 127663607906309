import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Input, Select, DatePicker, InputNumber,
} from 'antd';
import { debounce } from 'lodash';
import api from '../../../utils/api';

const { Option } = Select;

const CreateExpenseModal = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  const [expenseCategories, setExpenseCategories] = useState([]);
  const [expenseCategory, setExpenseCategory] = useState(null);
  const [tailors, setTailors] = useState([]);
  const [tailor, setTailor] = useState(null);

  useEffect(() => {
    (async () => {
      const { data: res } = await api.get('/production/expense-categories');
      setExpenseCategories(res.data);
    })();
  }, []);

  const onTailorSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/tailors?name=${value || ''}`);
    setTailors(res.data);
  }, 500);

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Potongan Baru"
      okText="Simpan"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.production_expense_category_id = expenseCategory.value;
            values.production_tailor_id = tailor.value;
            onCreate(values);

            form.resetFields();
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item name="tailor" label="Penjahit">
          <Select
            labelInValue
            showSearch
            placeholder="Ketik Nama Penjahit"
            onSearch={onTailorSearch}
            filterOption={false}
            onSelect={(value) => setTailor(value)}
          >
            {tailors.map((tailor) => (
              <Option key={tailor.id} value={tailor.id}>
                {tailor.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="production_expense_category" label="Kategori">
          <Select
            labelInValue
            showSearch
            placeholder="Ketik Kategori"
            filterOption={false}
            onSelect={(value) => setExpenseCategory(value)}
          >
            {expenseCategories.map((category) => (
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="description"
          label="Deskripsi"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan deskripsi potongan!',
            },
          ]}
        >
          <Input placeholder="Deskripsi" />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Jumlah"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan Jumlah potongan!',
            },
          ]}
        >
          <InputNumber min={0} placeholder="Jumlah" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="date"
          label="Tanggal"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal potongan!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateExpenseModal;
