import React from 'react';
import { Modal, Form, Input, Select, InputNumber } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

const ProductCreate = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Ubah Stok"
      okText="Simpan"
      cancelText="Batal"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          action: 'IN',
          description: null,
        }}
      >
        <Form.Item name="action" label="Tipe">
          <Select>
            <Option value="IN">Masuk</Option>
            <Option value="ADJUSTMENT">Kurangi</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="qty"
          label="Jumlah"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan stock produk!',
            },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item name="description" label="Deskripsi">
          <TextArea rows={4} placeholder="Deskripsi" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProductCreate;
