import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, PageHeader, Card, Form, Input, Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { actions } from '../../../modules/reducers';
import { addressColumn } from '../utils';
import AddressCreate from '../components/create';
import AddressEdit from '../components/edit';

const Detail = ({ dispatch, match, history, user, address }) => {
  const { params } = match;
  const [updateUserForm] = Form.useForm();
  const [updatePasswordForm] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [editAddressVisible, setEditAddressVisible] = useState(false);
  const [editAddress, setEditAddress] = useState();

  useEffect(() => {
    dispatch(actions.fetchUser(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(actions.fetchAddress());
  }, [dispatch]);

  const handleUpdateProfile = (values) => {
    updateUserForm
      .validateFields()
      .then((values) => {
        updateUserForm.resetFields();
        dispatch(actions.updateUser(params.id, values));
      })
      .catch((info) => {
        alert('Validate Failed:', info);
      });
  };

  const handleSubmit = () => {
    updatePasswordForm
      .validateFields()
      .then((values) => {
        updatePasswordForm.resetFields();
        dispatch(actions.updatePassword(params.id, values));
      })
      .catch((info) => {
        alert('Validate Failed:', info);
      });
  };

  const onDeleteAddress = (addressID) => {
    dispatch(actions.deleteAddress(addressID));
  };

  const onAddNewAddress = (data) => {
    dispatch(actions.addNewAddress(data));
    setVisible(false);
  };

  const onEditAddress = (id, data) => {
    dispatch(actions.editAddress(id, data));
    setEditAddressVisible(false);
  };

  return (
    <>
      <PageHeader
        title="Detail Customer"
        subTitle={user ? user.name : ''}
        style={{ padding: '0 0 16px' }}
        onBack={() => history.goBack()}
      />
      {user ? (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card title="Data Profil">
              <Form
                form={updateUserForm}
                onFinish={handleUpdateProfile}
                initialValues={{ name: user.name, email: user?.email, phone: user.phone }}
                layout="vertical"
              >
                <Form.Item
                  name="name"
                  label="Nama lengkap"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan nama lengkap anda',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan email anda',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="Nomor Telepon"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan nomor telepon anda',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Simpan
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Ganti password">
              <Form form={updatePasswordForm} layout="vertical" name="form_in_modal">
                <Form.Item
                  name="password"
                  label="Ganti Password"
                  rules={[
                    {
                      required: true,
                      message: 'Password tidak boleh kosong!',
                    },
                  ]}
                >
                  <Input placeholder="Masukkan password baru" />
                </Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                  Simpan
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      ) : null}
      <Row gutter={[0, 16]}>
        <Col span={24} style={{ marginTop: 32 }}>
          <Card title="Manajemen Alamat">
            <Button type="primary" onClick={() => setVisible(true)} style={{ marginBottom: 16 }}>
              <PlusOutlined /> Tambah Alamat
            </Button>
            <Table
              rowKey="id"
              columns={addressColumn(onDeleteAddress)}
              dataSource={address}
              pagination={false}
              onRow={(record) => ({
                onClick: () => {
                  setEditAddress(record);
                  setEditAddressVisible(true);
                },
              })}
            />
          </Card>
        </Col>
      </Row>
      <AddressCreate visible={visible} onCreate={onAddNewAddress} onCancel={() => setVisible(false)} />
      <AddressEdit
        visible={editAddressVisible}
        onCreate={onEditAddress}
        onCancel={() => setEditAddressVisible(false)}
        address={editAddress}
      />
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
  address: user.address,
});

export default connect(mapStateToProps)(Detail);
