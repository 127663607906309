import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Space,
  Table,
  PageHeader,
  Descriptions,
  Button,
  Tag,
  Card,
  Modal,
  Form,
  Input,
  InputNumber,
} from 'antd';
import { format } from 'date-fns';
import { DollarTwoTone } from '@ant-design/icons';

import { actions } from '../../../modules/reducers';
import { statusOrderMapper } from '../../../utils/status';
import { countQuantity } from '../../../utils/number';

const { confirm } = Modal;

const colorMapper = {
  WAITING_PAYMENT: 'warning',
  CANCELLED: 'red',
  REJECTED: 'red',
  DONE: 'green',
  PAYMENT_RECEIVED: 'volcano',
};

const ItemColumns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (record.product.on_sale
      ? `${record.name} (disc ${record.product.sale_price}%)`
      : record.name),
  },
  {
    title: 'Gambar',
    dataIndex: 'picture',
    key: 'picture',
    render: (text, record) => {
      let pictures = [];
      try {
        pictures = JSON.parse(record.picture);
      } catch (error) {}

      return (
        <Space size="middle">
          {pictures.map((picture, idx) => (
            <div
              style={{ maxWidth: 60, overflow: 'hidden', borderRadius: 4 }}
              key={idx}
            >
              <img
                src={picture}
                alt="slider"
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          ))}
        </Space>
      );
    },
  },
  {
    title: 'Harga Barang',
    dataIndex: 'price',
    key: 'price',
    render: (_, record) => (record.sale_price
      ? `Rp. ${record.sale_price?.toLocaleString('ID')}`
      : `Rp. ${record.price?.toLocaleString('ID')}`),
  },
  {
    title: 'Banyak Pembelian',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (text) => countQuantity(text),
  },
  {
    title: 'Subtotal',
    dataIndex: 'subtotal',
    key: 'subtotal',
    render: (_, record) => `Rp. ${record.subtotal.toLocaleString('ID')}`,
  },
];

const LogColumns = [
  {
    title: 'Aktor',
    dataIndex: 'actor',
    key: 'actor',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Jam',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (_, record) => format(new Date(record.created_at), 'dd-MM-yyyy HH:mm'),
  },
];

const Detail = ({
  dispatch, match, history, order,
}) => {
  const [form] = Form.useForm();
  const { params } = match;

  useEffect(() => {
    dispatch(actions.fetchOrder(params.id));
  }, [dispatch, params.id]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      dispatch(actions.setDiscount(params.id, values));
      handleCancel();
    });
  };

  let StatusButtons;

  if (order) {
    StatusButtons = () => {
      switch (order.status) {
        case 'WAITING_PAYMENT':
          return (
            <Space direction="horizontal">
              <Button
                type="primary"
                onClick={() => confirm({
                  title: 'Update Status',
                  content:
                      'Apakah anda yakin ingin mengupdate status order ini?',
                  onOk: () => {
                    dispatch(
                      actions.changeOrderStatus('PAYMENT_RECEIVED', order),
                    );
                  },
                })}
              >
                Pembayaran Diterima
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => confirm({
                  title: 'Update Status',
                  content:
                      'Apakah anda yakin ingin mengupdate status order ini?',
                  onOk: () => {
                    dispatch(actions.rejectOrder(order.id));
                  },
                })}
              >
                Tolak
              </Button>
            </Space>
          );
        case 'PAYMENT_RECEIVED':
          return (
            <Button
              type="primary"
              onClick={() => confirm({
                title: 'Update Status',
                content:
                    'Apakah anda yakin ingin mengupdate status order ini?',
                onOk: () => {
                  dispatch(actions.changeOrderStatus('PREPARING', order));
                },
              })}
            >
              Barang Disiapkan
            </Button>
          );
        case 'PREPARING':
          return (
            <Button
              type="primary"
              onClick={() => confirm({
                title: 'Update Status',
                content:
                    'Apakah anda yakin ingin mengupdate status order ini?',
                onOk: () => {
                  dispatch(actions.changeOrderStatus('READY', order));
                },
              })}
            >
              Siap Dikirim
            </Button>
          );
        case 'READY':
          return (
            <Button
              type="primary"
              onClick={() => confirm({
                title: 'Update Status',
                content:
                    'Apakah anda yakin ingin mengupdate status order ini?',
                onOk: () => {
                  dispatch(actions.changeOrderStatus('DELIVERING', order));
                },
              })}
            >
              Sedang Dikirim
            </Button>
          );
        case 'DELIVERING':
          return (
            <Button
              type="primary"
              onClick={() => confirm({
                title: 'Update Status',
                content:
                    'Apakah anda yakin ingin mengupdate status order ini?',
                onOk: () => {
                  dispatch(actions.changeOrderStatus('DELIVERED', order));
                },
              })}
            >
              Diterima
            </Button>
          );
        case 'DELIVERED':
          return (
            <Button
              type="primary"
              onClick={() => confirm({
                title: 'Update Status',
                content:
                    'Apakah anda yakin ingin mengupdate status order ini?',
                onOk: () => {
                  dispatch(actions.changeOrderStatus('DONE', order));
                },
              })}
            >
              Selesai
            </Button>
          );
        default:
          return null;
      }
    };
  }

  return (
    <>
      <PageHeader
        title="Detail Pesanan"
        subTitle={order ? order.invoice_no : ''}
        style={{ padding: '0 0 16px' }}
        onBack={() => history.goBack()}
      />
      <Row>
        {order ? (
          <>
            <Col span={24} style={{ marginBottom: 32 }}>
              {!['REJECTED', 'CANCELED'].includes(order.status) ? (
                <Space direction="horizontal" style={{ marginBottom: 16 }}>
                  <Button type="primary" target="_blank" href={`/orders/${order.id}/invoice`}>
                    Print Invoice
                  </Button>
                  <Button type="primary" target="_blank" href={`/orders/${order.id}/checklist`}>
                    Print Order Check List
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: 16 }}
                    icon={<DollarTwoTone />}
                    onClick={() => setIsModalVisible(true)}
                  >
                    Diskon
                  </Button>
                  <StatusButtons editedOrder={order} />
                </Space>
              ) : null}
              <Card title="Pesanan">
                <Descriptions bordered size="small">
                  <Descriptions.Item label="Invoice">{order.invoice_no}</Descriptions.Item>
                  <Descriptions.Item label="Status">
                    <Tag color={colorMapper.hasOwnProperty(order.status) ? colorMapper[order.status] : 'blue'}>
                      {statusOrderMapper[order.status]}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Tanggal">
                    {format(new Date(order.created_at), 'dd-MM-yyyy')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Diskon">
                    <strong style={{ color: 'green', fontWeight: 700 }}>
                      {order.discount ? `Rp. ${order.discount.toLocaleString('id')}` : '-'}
                    </strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Total">
                    <strong>
                      {order.total_after_discount ? `Rp. ${order.total_after_discount.toLocaleString('id')}` : null}
                    </strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Keterangan Diskon">{order?.discount_desc}</Descriptions.Item>
                  <Descriptions.Item label="Pelanggan">{order.customer_name}</Descriptions.Item>
                  <Descriptions.Item label="Telp">{order.customer_phone}</Descriptions.Item>
                  <Descriptions.Item label="Pembayaran">
                    <Tag>{statusOrderMapper[order.payment]}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Jenis Pengiriman" span={2}>
                    <Tag>{statusOrderMapper[order.delivery_type]}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Pengiriman">
                    {order.delivery_type !== 'PICKUP' ? (
                      <>
                        <p style={{ margin: '0' }}>
                          <strong>Penerima:</strong>
                          {' '}
                          {order.customer_receiver_name}
                        </p>
                        <p style={{ margin: '0' }}>
                          <strong>No Telp Penerima:</strong>
                          {' '}
                          {order.customer_receiver_phone}
                        </p>
                        <p style={{ margin: '0' }}>
                          <strong>Alamat Penerima:</strong>
                          {' '}
                          {order.customer_address}
                        </p>
                      </>
                    ) : (
                      '-'
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: 32 }}>
              <Card title="Daftar Pembelian">
                <Table rowKey="id" columns={ItemColumns} dataSource={order.items} />
              </Card>
            </Col>
            <Col span={12} style={{ marginBottom: 32 }}>
              <Card title="Status History">
                <Table rowKey="id" columns={LogColumns} dataSource={order.logs} />
              </Card>
            </Col>
          </>
        ) : null}
      </Row>
      {order ? (
        <Modal title="Beri Diskon" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              percentage: ((order.discount / order.total) * 100).toFixed(2),
              discount: order.discount,
              discount_desc: order.discount_desc,
            }}
          >
            <Form.Item
              name="percentage"
              label="Diskon dalam persen"
              rules={[
                {
                  required: true,
                  message: 'Diskon dalam persen tidak boleh kosong',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace('%', '')}
                onChange={(value) => {
                  if (value) {
                    const disc = (order.total * value) / 100;
                    form.setFieldsValue({
                      percentage: value,
                      discount: disc,
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="discount"
              label="Diskon"
              rules={[
                {
                  required: true,
                  message: 'Diskon dalam angka rupiah tidak boleh kosong',
                },
              ]}
            >
              <Input
                addonBefore="Rp. "
                onChange={({ target }) => {
                  if (target.value) {
                    const percent = ((target.value / order.total) * 100).toFixed(2);
                    form.setFieldsValue({
                      percentage: percent,
                      discount: target.value,
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="discount_desc"
              label="Keterangan Diskon"
              rules={[
                {
                  required: true,
                  message: 'Keterangan diskon tidak boleh kosong',
                },
              ]}
            >
              <Input placeholder="Keterangan Diskon" />
            </Form.Item>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ order }) => ({
  order: order.order,
});

export default connect(mapStateToProps)(Detail);
