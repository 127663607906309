import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.CATEGORY_LOADING,
});

export const fetchCategories = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(
      `/category?page=${params.page}&name=${params.name}&limit=${params.limit}`
    );

    dispatch({
      type: constant.CATEGORIES_FULFILLED,
      payload: res.data,
      pagination: {
        total: res.pagination.rowCount,
        current: res.pagination.page,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const fetchSum = (params = {}) => async (dispatch) => {
  dispatch(loading());

  try {
    const qs = Object.keys(params)
      .filter((x) => params[x])
      .map((x) => `${x}=${params[x]}`)
      .join('&');

    const { data: res } = await api.get(`/total-sums?${qs}`);

    if (res.success) {
      dispatch({
        type: constant.CATEGORIES_SUM,
        payload: res.data.total,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createCategory = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/category', data);

    if (res.success) {
      dispatch(fetchCategories({ page: 1, name: '' }));
      message.success('Berhasil Menambah Model');
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchCategory = (id) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/category/${id}`);

    if (res.success) {
      dispatch({
        type: constant.CATEGORY_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteCategory = (data, { history }) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/category/${data.id}`);

    if (res.success) {
      message.success('Berhasil menghapus Model');
      history.push('/categories');
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateCategory = (formData, category, { history }) => async (
  dispatch
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/category/${category.id}`, formData);

    if (res.success) {
      message.success('Berhasil update Model');
      dispatch(fetchCategories({ page: 1, name: '' }));
      history.push('/categories');
    }
  } catch (err) {
    console.error(err);
  }
};

export const searchCategories = (value) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/category?name=${value}`);

    if (res.success) {
      dispatch({
        type: constant.CATEGORIES_SEARCHED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};
