import React, { useEffect, useState } from 'react';
import {
  Modal, Form, DatePicker, InputNumber,
} from 'antd';
import { dozenToPcs, pcsToDozen } from '../../../../utils/number';

const CreateReturnModal = ({
  visible, onCreate, onCancel, data,
}) => {
  const [form] = Form.useForm();

  const [dozen, setDozen] = useState(0);
  const [pcs, setPcs] = useState(0);

  useEffect(() => {
    if (data) {
      const totalReturned = pcsToDozen(data.total_returned);
      setDozen(totalReturned.dozen);
      setPcs(totalReturned.pcs);
    }
  }, [data]);

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Barang Kembali"
      okText="Simpan"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.total_returned = dozenToPcs(dozen, pcs);
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal" initialValues={data}>
        <Form.Item name="total_returned" label="Total Kembali">
          <InputNumber
            min={0}
            value={dozen}
            onChange={(value) => {
              setDozen(value);
            }}
          />
          {' '}
          lusin
          <InputNumber
            min={0}
            value={pcs}
            style={{ marginLeft: 8 }}
            onChange={(value) => {
              setPcs(value);
            }}
          />
          {' '}
          pcs
        </Form.Item>
        <Form.Item
          name="date"
          label="Tanggal Kembali"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal kembali!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateReturnModal;
