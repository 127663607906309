export const statusOrderMapper = {
  WAITING_PAYMENT: 'Menunggu Pembayaran',
  PAYMENT_RECEIVED: 'Pembayaran Diterima',
  PREPARING: 'Barang Disiapkan',
  READY: 'Siap Dikirim',
  DELIVERING: 'Sedang Dikirim',
  DELIVERED: 'Diterima',
  DONE: 'Selesai',
  CANCELLED: 'Dibatalkan',
  REJECTED: 'Ditolak',
  DELIVERY: 'Pengiriman menggunakan paket',
  CASH: 'Cash',
  BANK_TRANSFER: 'Transfer Bank',
  PICKUP: 'Ambil di Toko',
  IN: 'Stock Masuk',
  OUT: 'Stock Keluar',
  ADJUSTMENT: 'Pengurangan Stock',
};

export const dateFormat = 'dd-mm-yyyy';
