import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.CART_LOADING,
});

const defaultParams = {
  limit: 1000,
  page: 1,
  user: '',
  is_prepared: null,
};

export const fetchCarts = (filter = defaultParams) => async (dispatch) => {
  dispatch(loading());

  const mapper = {
    isPrepared: 0,
  };

  const query = Object.keys(filter)
    .filter((k) => filter[k])
    .map((k) => `${mapper.hasOwnProperty(k) ? mapper[k] : k}=${filter[k]}`)
    .join('&');

  try {
    const res = await api.get(`/cart/all?${query}`);

    if (res.data.success) {
      dispatch({
        type: constant.CARTS_FULFILLED,
        payload: res.data.data,
        pagination: {
          total: res.data.pagination.rowCount,
          current: res.data.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchCartByUser = (userId) => async (dispatch) => {
  dispatch(loading());

  try {
    const res = await api.get(`/cart/${userId}/user`);

    if (res.data.success) {
      dispatch({
        type: constant.CART_FULFILLED,
        payload: res.data.data,
        pagination: {
          total: res.data.pagination.rowCount,
          current: res.data.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteCarts = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete('/cart/delete', {
      data: {
        ids: data,
      },
    });

    if (res.success) {
      message.success('Berhasil menghapus keranjang belanja');
      dispatch(fetchCarts({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const togglePrepareCart = (data, id) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/cart/${id}/prepare`, data);

    if (res.success) {
      message.success('Berhasil Mengedit Pesanan');
      dispatch(fetchCarts({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};
