import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactTags from 'react-tag-autocomplete';
import debounce from 'lodash/debounce';

import {
  Form,
  Button,
  Row,
  Col,
  Upload,
  InputNumber,
  Table,
  Space,
  PageHeader,
  Card,
  Input,
  Select,
  Switch,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { format } from 'date-fns';

import { actions } from '../../../modules/reducers';
import api from '../../../utils/api';
import StockUpdate from '../components/stockUpdate';
import { statusOrderMapper } from '../../../utils/status';
import { getImage } from '../../../utils/image';
import { skuGenerator, pcsToDozen, countQuantity } from '../../../utils/number';

import './detail.css';

const { Option } = Select;

const Detail = ({
  dispatch,
  match,
  product,
  history,
  stockAudit,
  pagination,
}) => {
  const { params } = match;

  const [page, setPage] = useState(1);
  const [uploadedUrl, setUploadedUrl] = useState([]);
  const [StockModal, setStockModal] = useState(false);
  const [category, setCategory] = useState({
    key: -1,
    label: 'Loading...',
    value: -1,
  });
  const [tags, setTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [batch_code, setBatchCode] = useState('');
  const [name, setName] = useState('');
  const [generatedSKU, setSKU] = useState('');
  const [uploadedFileList, setUploadedFileList] = useState([]);
  const [dozen, setDozen] = useState(0);
  const [pcs, setPcs] = useState(0);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    dispatch(actions.fetchProduct(params.id));
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(actions.fetchStockAudit(params.id, page));
  }, [dispatch, params, page]);

  useEffect(() => {
    const SKU = skuGenerator(batch_code, name);
    setSKU(SKU);
  }, [batch_code, name]);

  useEffect(() => {
    if (product) {
      const stock = pcsToDozen(product.stock_qty);
      setDozen(stock.dozen);
      setPcs(stock.pcs);

      setTags(product.tags.map((tag) => ({ id: tag.id, name: tag.name })));
      setCategory({
        key: product.category.id,
        label: product.category.name,
        value: product.category.id,
      });
      setPrice(product.price);
      setDiscount(product.sale_price);

      let pictures = [];
      let fileList = [];

      try {
        pictures = JSON.parse(product.pictures).flat(1);

        fileList = pictures
          .map((picture, idx) => ({
            uid: `rc-upload-${idx}`,
            name: /[^/]*$/.exec(picture),
            status: 'done',
            url: picture,
            thumbUrl: getImage(picture, 100),
          }))
          .sort((a, b) => {
            const first = a.uid.split('-');
            const second = b.uid.split('-');
            return second[second.length - 1] - first[first.length - 1];
          });
      } catch (err) {
        console.error(err);
      }

      setUploadedFileList(fileList);
      setUploadedUrl(pictures);
      setBatchCode(product.batch_code);
      setName(product.name);

      if (product.sku) {
        setTimeout(() => {
          setSKU(product.sku);
        }, 300);
      }
    }
  }, [product]);

  const handleInputTag = debounce(async (query) => {
    const { data: res } = await api.get(`/tags?${query}`);

    if (res.data.length) {
      setTagOptions(res.data);
    }
  }, 500);

  const onFinish = (data) => {
    delete data.category;

    const formData = {
      ...data,
      sku: generatedSKU,
      category_id: category.value,
      pictures: uploadedUrl,
      tags: tags.map((tag) => tag.name),
    };

    dispatch(actions.updateProduct(formData, product, { history }));
  };

  const onFinishStock = (data) => {
    dispatch(actions.UpdateStockProduct(data, product));
  };

  const handleTagDelete = (i) => {
    const selectedTags = tags.slice(0);
    selectedTags.splice(i, 1);
    setTags(selectedTags);
  };

  const handleTagAddition = (tag) => {
    const newTag = [].concat(tags, tag);
    setTags(newTag);
  };

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/category?name=${value || ''}`);
    setCategories(res.data);
  }, 500);

  const handleRemovePicture = (picture) => {
    setUploadedUrl(uploadedUrl.filter((url) => picture.url !== url));
  };

  const handleTableChange = (p) => {
    setPage(p.current);
  };

  const handleChangeUpload = (info) => {
    let filelist = [...info.fileList];

    filelist = filelist
      .map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }

        return file;
      })
      .sort((a, b) => {
        const first = a.uid.split('-');
        const second = b.uid.split('-');
        return second[second.length - 1] - first[first.length - 1];
      });

    setUploadedFileList(filelist);
  };

  const props = {
    customRequest: async (options) => {
      const data = new FormData();
      data.append('folder', 'products');
      data.append('images', options.file);

      try {
        const { data: res } = await api.post('/upload/v2', data);

        if (res.success) {
          options.onSuccess(res.data, options.file);

          const uploaded = res.data.map(({ secure_url }) => secure_url);
          setUploadedUrl((uploadState) => [...uploadState, ...uploaded]);
        } else {
          options.onError(res.message);
        }
      } catch (err) {
        options.onError(err);
      }
    },
    multiple: true,
    onChange: handleChangeUpload,
    onRemove: handleRemovePicture,
    defaultFileList: uploadedFileList !== undefined && [...uploadedFileList],
    listType: 'picture',
  };

  const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 10 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 7, offset: 7 },
  };

  const columns = [
    {
      title: 'User',
      dataIndex: 'actor',
      key: 'actor',
    },
    {
      title: 'Tipe',
      dataIndex: 'action',
      key: 'action',
      render: (value) => <span>{statusOrderMapper[value]}</span>,
    },
    {
      title: 'Jumlah',
      dataIndex: 'qty',
      key: 'qty',
      render: (value) => countQuantity(value),
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Tanggal',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => format(new Date(value), 'dd-MM-yyyy'),
    },
  ];

  return (
    <>
      <PageHeader
        title="Detail Produk"
        subTitle={product ? product.name : ''}
        style={{ padding: '0 0 16px' }}
        onBack={() => history.goBack()}
      />
      <Row>
        <Col span={11}>
          <Card>
            {product ? (
              <Form
                {...formItemLayout}
                layout="horizontal"
                name="normal_login"
                className="login-form"
                labelCol={{ span: 7 }}
                initialValues={{
                  ...product,
                  category: {
                    key: product.category.id,
                    label: product.category.name,
                    value: product.category.id,
                  },
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="category"
                  label="Model"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon pilih salah satu model!',
                    },
                  ]}
                >
                  <Select
                    labelInValue
                    showSearch
                    placeholder="Ketik Nama Model"
                    onSearch={onSearch}
                    filterOption={false}
                    onSelect={(value) => setCategory(value)}
                  >
                    {categories.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="batch_code"
                  label="Kode Penjahit/Produksi"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan kode penjahit atau/dan kode produksi!',
                    },
                  ]}
                >
                  <Input onChange={(e) => setBatchCode(e.target.value)} />
                </Form.Item>

                <Form.Item
                  name="name"
                  label="Nama"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon Masukkan nama produk anda!',
                    },
                  ]}
                >
                  <Input onChange={(e) => setName(e.target.value)} />
                </Form.Item>

                <Form.Item label="SKU (otomatis)">
                  <Input value={generatedSKU} style={{ background: '#EEE' }} onChange={(e) => setSKU(e.target.value)} />
                </Form.Item>

                <Form.Item label="Stok Produk">
                  <InputNumber disabled value={dozen} />
                  {' '}
                  lusin
                  <InputNumber disabled value={pcs} style={{ marginLeft: 8 }} />
                  {' '}
                  pcs
                </Form.Item>
                <Form.Item
                  name="price"
                  label="Harga Produk"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon masukkan harga produk!',
                    },
                  ]}
                >
                  <InputNumber
                    defaultValue={0}
                    onChange={(value) => {
                      setPrice(value);
                    }}
                  />
                </Form.Item>
                <Form.Item name="sale_price" label="Persentase Diskon">
                  <InputNumber
                    defaultValue={0}
                    onChange={(value) => {
                      setDiscount(value);
                    }}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                  />
                </Form.Item>
                <Form.Item label="Harga Diskon">
                  <InputNumber disabled value={discount > 0 ? price - price * (discount / 100) : 0} />
                </Form.Item>
                <Form.Item name="on_sale" valuePropName="checked" label="Aktifkan Diskon">
                  <Switch />
                </Form.Item>
                <Form.Item name="tags" label="Tag">
                  <ReactTags
                    allowNew
                    tags={tags}
                    suggestions={tagOptions}
                    onDelete={handleTagDelete}
                    onAddition={handleTagAddition}
                    onInput={handleInputTag}
                  />
                </Form.Item>
                <Form.Item
                  name="pictures"
                  label="Gambar"
                  rules={[
                    {
                      required: true,
                      message: 'Mohon upload gambar produk!',
                    },
                  ]}
                >
                  <Upload {...props} fileList={uploadedFileList}>
                    <Button>
                      <UploadOutlined />
                      {' '}
                      Click to Upload
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                  <Button type="primary" htmlType="submit">
                    Simpan
                  </Button>
                </Form.Item>
              </Form>
            ) : null}
          </Card>
        </Col>
        <Col span={12} offset={1}>
          <Card title="Manajemen Stock" style={{ marginBottom: 20 }}>
            <Space>
              <Button type="primary" onClick={() => setStockModal(true)}>
                Edit Stock
              </Button>
            </Space>
          </Card>
          <Card title="History Stock">
            <Table
              rowKey="id"
              columns={columns}
              dataSource={stockAudit}
              onChange={handleTableChange}
              pagination={{
                current: parseInt(page) || 1,
                total: (pagination && pagination.total) || 0,
                showSizeChanger: false,
              }}
            />
            <StockUpdate visible={StockModal} onCreate={onFinishStock} onCancel={() => setStockModal(false)} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ product }) => ({
  product: product.product,
  stockAudit: product.stockAudit,
  loading: product.loading,
  pagination: product.stockAuditPagination,
});

export default connect(mapStateToProps)(Detail);
