/* eslint-disable import/prefer-default-export */
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.OPTIONS_LOADING,
});

export const fetchOptions = () => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get('/option');

    if (res.success) {
      dispatch({
        type: constant.OPTIONS_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateOptions = (data) => async (dispatch) => {
  dispatch(loading());

  const keys = Object.keys(data);

  const formData = keys.map((key) => ({
    key,
    value: `${data[key]}`,
  }));

  try {
    const { data: res } = await api.put('/option', formData);

    if (res.success) {
      dispatch(fetchOptions());
    }
  } catch (err) {
    console.error(err);
  }
};
