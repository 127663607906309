import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.PERIOD_LOADING,
});

export const fetchPeriods = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const qs = Object.keys(params)
      .filter((x) => params[x])
      .map((x) => `${x}=${params[x]}`)
      .join('&');

    const { data: res } = await api.get(`/production/periods?${qs}`);

    if (res.success) {
      dispatch({
        type: constant.PERIODS_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createPeriod = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/production/periods', data);

    if (res.success) {
      message.success('Berhasil menambahkan periode');
      dispatch(fetchPeriods({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchPeriod = (periodID) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/production/periods/${periodID}`);

    if (res.success) {
      dispatch({
        type: constant.PERIOD_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deletePeriod = (period) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/production/periods/${period.id}`);

    if (res.success) {
      message.success('Berhasil menghapus periode.');
      dispatch(fetchPeriods({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const updatePeriod = (formData, period) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/production/periods/${period.id}`, formData);

    if (res.success) {
      message.success('Berhasil mengubah periode.');
      dispatch(fetchPeriods({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};
