import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import config from '../../config';

const PrivateRoute = ({ component, ...rest }) => {
  const [cookies] = useCookies([config.COOKIE_NAME]);

  if (!component) {
    throw Error('Component is undefined');
  }

  const Component = component;
  const render = (props) => {
    if (cookies.access_token) {
      return <Component {...props} />;
    }
    return <Redirect to="/login" />;
  };

  return (
    <Route {...rest} render={render} />
  );
};

export default PrivateRoute;
