import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.TAILOR_LOADING,
});

export const fetchTailors = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const qs = Object.keys(params)
      .filter((x) => params[x])
      .map((x) => `${x}=${params[x]}`)
      .join('&');

    const { data: res } = await api.get(`/production/tailors?${qs}`);

    if (res.success) {
      dispatch({
        type: constant.TAILORS_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createTailor = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/production/tailors', data);

    if (res.success) {
      message.success('Berhasil menambahkan penjahit');
      dispatch(fetchTailors({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchTailor = (tailorID) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/production/tailors/${tailorID}`);

    if (res.success) {
      dispatch({
        type: constant.TAILOR_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteTailor = (tailor) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/production/tailors/${tailor.id}`);

    if (res.success) {
      message.success('Berhasil menghapus penjahit');
      dispatch(fetchTailors({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateTailor = (formData, tailor) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/production/tailors/${tailor.id}`, formData);

    if (res.success) {
      message.success('Berhasil mengubah penjahit');
      dispatch(fetchTailors({ page: 1 }));
    }
  } catch (err) {
    console.error(err);
  }
};
