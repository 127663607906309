import React, { useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Select,
  DatePicker,
  InputNumber,
  Button,
  Upload,
  message,
} from 'antd';
import { debounce } from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import api from '../../../utils/api';
import { dozenToPcs } from '../../../utils/number';

const { Option } = Select;

const CreateModal = ({
  visible, onCreate, onCancel, data,
}) => {
  const [form] = Form.useForm();

  const [uploaded, setUploaded] = useState([]);
  const [tailors, setTailors] = useState([]);
  const [models, setModels] = useState([]);
  const [tailor, setTailor] = useState(null);
  const [model, setModel] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('DRAFT');

  const [dozen, setDozen] = useState(0);
  const [pcs, setPcs] = useState(0);

  useEffect(() => {
    (async () => {
      const { data: res } = await api.get('/production/periods');
      setPeriods(res.data);
    })();
  }, []);

  const props = {
    multiple: true,
    listType: 'picture',
    customRequest: async (options) => {
      const data = new FormData();
      data.append('folder', 'products');
      data.append('images', options.file);

      try {
        const { data: res } = await api.post('/upload/v2', data);

        if (res.success) {
          options.onSuccess(res.data, options.file);
          const uploadResponse = res.data.map(({ secure_url }) => secure_url);
          setUploaded((upload) => [...upload, ...uploadResponse]);
        } else {
          options.onError(res.message);
        }
      } catch (err) {
        options.onError(err);
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} gambar berhasil diupload`);
      }
      if (info.file.status === 'error') {
        message.error(`${info.file.name} gambar gagal diupload`);
      }
    },
  };

  const onModelSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/models?name=${value || ''}`);
    setModels(res.data);
  }, 500);

  const onTailorSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/production/tailors?name=${value || ''}`);
    setTailors(res.data);
  }, 500);

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Tambah Seri Baru"
      okText="Simpan"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            delete values.model;
            delete values.pictures;
            delete values.period;

            values.buku_potong = uploaded;
            values.production_model_id = model.value;
            values.sewing_total = dozenToPcs(dozen, pcs);
            values.production_period_id = selectedPeriod.value;
            values.production_tailor_id = tailor ? tailor.value : null;

            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          ...data,
          status: 'DRAFT',
        }}
      >
        <Form.Item
          name="pictures"
          label="Buku Potong"
          rules={[
            {
              required: true,
              message: 'Mohon upload gambar produk!',
            },
          ]}
        >
          <Upload {...props}>
            <Button>
              <UploadOutlined />
              {' '}
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="model"
          label="Model baju"
          rules={[
            {
              required: true,
              message: 'Mohon pilih model yang ingin dijahit',
            },
          ]}
        >
          <Select
            labelInValue
            showSearch
            placeholder="Ketik Nama Model"
            onSearch={onModelSearch}
            filterOption={false}
            onSelect={(value) => setModel(value)}
          >
            {models.map((model) => (
              <Option key={model.id} value={model.id}>
                {model.model.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="tailor"
          label="Penjahit"
          rules={selectedStatus !== 'DRAFT'
          && [
            {
              required: true,
              message: 'Mohon pilih penjahit',
            },
          ]}
        >
          <Select
            labelInValue
            showSearch
            placeholder="Ketik Nama Penjahit"
            onSearch={onTailorSearch}
            filterOption={false}
            onSelect={(value) => setTailor(value)}
          >
            {tailors.map((tailor) => (
              <Option key={tailor.id} value={tailor.id}>
                {tailor.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
        >
          <Select onChange={(value) => setSelectedStatus(value)}>
            <Option value="DRAFT">Draft</Option>
            <Option value="INPROGRESS">Diproses</Option>
            <Option value="FINISHED">Selesai</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="period"
          label="Periode jahit"
          rules={[
            {
              required: true,
              message: 'Mohon pilih periode jahit',
            },
          ]}
        >
          <Select
            labelInValue
            filterOption={false}
            onSelect={(value) => setSelectedPeriod(value)}
          >
            {periods.map((period) => (
              <Option key={period.id} value={period.id}>
                {format(new Date(period.start_at), 'yyyy')}
                {' '}
                -
                {' '}
                {format(new Date(period.end_at), 'yyyy')}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="sewing_at"
          label="Dijahit di tanggal"
          rules={selectedStatus !== 'DRAFT'
          && [
            {
              required: true,
              message: 'Mohon masukkan tanggal jahitan!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item
          name="cutting_at"
          label="Dipotong di tanggal"
          rules={selectedStatus !== 'DRAFT'
          && [
            {
              required: true,
              message: 'Mohon masukkan tanggal pemotongan!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item
          name="sewing_total"
          label="Total Jahitan"
        >
          <InputNumber
            value={dozen}
            onChange={(value) => {
              setDozen(value);
            }}
          />
          {' '}
          lusin
          <InputNumber
            value={pcs}
            style={{ marginLeft: 8 }}
            onChange={(value) => {
              setPcs(value);
            }}
          />
          {' '}
          pcs
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateModal;
