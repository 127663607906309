import * as c from "./constants";

export const initialState = {
  loading: false,
  users: [],
  user: null,
  address: null,
  pagination: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.USER_LOADING:
      return {
        ...state,
        loading: action.loading,
        users: [],
        user: null,
        address: [],
        pagination: null,
      };
    case c.USER_FULFILLED:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case c.ADDRESS_FULFILLED:
      return {
        ...state,
        address: action.payload,
        loading: false,
      };
    case c.USER_LIST_FULFILLED:
      return {
        ...state,
        users: action.payload,
        pagination: action.pagination,
      };
    default:
      return state;
  }
};

export default userReducer;
