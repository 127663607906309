import React from 'react';
import {
  Modal,
  Form,
  DatePicker,
} from 'antd';

const CreatePeriodModal = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Tambah Periode Baru"
      okText="Tambah"
      cancelText="Batal"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="start_at"
          label="Tanggal Mulai Periode"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal mulai periode!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item
          name="end_at"
          label="Tanggal Selesai Periode"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan tanggal selesai periode!',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePeriodModal;
