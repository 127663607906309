export const countQuantity = (count) => {
  const DOZEN = 12;
  const pcs = count % 12 !== 0 ? `${count % 12} pcs` : '';
  return count < DOZEN
    ? `${count} pcs.`
    : `${Math.floor(count / DOZEN)} lusin ${pcs}`;
};

export { default as PosOrderHistoriesList } from './List';
export { default as PosOrderHistoriesDetail } from './Detail';
