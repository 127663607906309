/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Modal,
  Form,
  Card,
  Input,
  Select,
} from 'antd';
import { PlusOutlined, FilterOutlined } from '@ant-design/icons';

import { actions } from '../../../modules/reducers';

import ProductCreate from '../components/create';
import api from '../../../utils/api';
import { getImage } from '../../../utils/image';
import { countQuantity } from '../../../utils/number';

const { confirm } = Modal;
const { Option } = Select;

const ProductPage = ({ dispatch, products, pagination, history, query }) => {
  const page = query.get('page');
  const name = query.get('name');
  const categoryId = query.get('category_id');

  useEffect(() => {
    dispatch(
      actions.fetchProducts({
        page: page || 1,
        name: name || '',
        category_id: categoryId || '',
      })
    );

    dispatch(
      actions.fetchCategories({
        page: page || 1,
      })
    );

    dispatch(actions.fetchTags());
  }, [dispatch, page, name, categoryId]);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [ShowFilter, setShowFilter] = useState(false);
  const [categories, setCategories] = useState([]);

  const onCreate = (values) => {
    const formData = {
      ...values,
      view_count: 0,
      tags: values.tags !== 'undefined' ? values.tags : [],
    };

    dispatch(actions.createProduct(formData));
    setVisible(false);
  };

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <>
          <h3>{record.name}</h3>
          <p style={{ fontSize: 12 }}>SKU: {record.sku}</p>
          <p style={{ fontSize: 12 }}>Model: {record.category.name}</p>
          <Space>
            <span style={{ fontSize: 11 }}>Dilihat: {record.view_count}</span>
          </Space>
        </>
      ),
    },
    {
      title: 'Gambar',
      dataIndex: 'pictures',
      key: 'pictures',
      render: (text, record) => {
        const pictures = JSON.parse(record.pictures);

        return (
          <Space>
            {pictures.map((picture, idx) => (
              <div
                key={idx}
                style={{ maxWidth: 90, overflow: 'hidden', borderRadius: 4 }}
              >
                <img
                  src={getImage(picture, 100)}
                  alt="slider"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            ))}
          </Space>
        );
      },
    },
    {
      title: 'Stock',
      dataIndex: 'stock_qty',
      key: 'stock_qty',
      render: (text) => (text ? <span>{countQuantity(text)}</span> : 'Kosong'),
    },
    {
      title: 'Harga',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => {
        return record.on_sale ? (
          <>
            <span
              style={{ textDecoration: 'line-through' }}
            >{`Rp. ${record.price.toLocaleString('ID')} `}</span>
            <span style={{ color: 'red' }}>{`Rp. ${(
              record.price -
              (record.sale_price / 100) * record.price
            ).toLocaleString('ID')}`}</span>
          </>
        ) : (
          `Rp. ${record.price.toLocaleString('ID')}`
        );
      },
    },
    {
      title: 'Diskon',
      dataIndex: 'sale_price',
      key: 'sale_price',
      render: (text) => (text ? <span>{text}%.</span> : '-'),
    },
  ];

  const onSelectRow = (_, rows) => {
    setSelectedRowKeys(rows.map((r) => r.id));
  };

  const handleTableChange = (p) => {
    query.set('page', p.current);
    // const path = p.current === 1 ? '/products' : `/products?page=${p.current}`;
    history.push(`/products?${query.toString()}`);
  };

  const handleFilter = (value) => {
    Object.keys(value).forEach((param) => {
      if (param === 'category_id') {
        if (value[param] && value[param].value > 0) {
          query.set(param, value[param].value);
        }
      } else if (value[param]) {
        query.set(param, value[param]);
      }
    });

    query.set('page', 1);
    history.push(`/products?${query.toString()}`);
  };

  const onSearch = debounce(async (value) => {
    const { data: res } = await api.get(`/category?name=${value}`);
    setCategories(res.data);
  }, 500);

  return (
    <>
      <Row>
        <Col span={24}>
          <h2>Daftar Item di Web</h2>
        </Col>
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button
              type="danger"
              style={{ marginRight: 10 }}
              disabled={selectedRowKeys.length === 0}
              onClick={() =>
                confirm({
                  title: 'Hapus Motif',
                  content: 'Anda yakin ingin menghapus Motif ini?',
                  onOk: () => {
                    setSelectedRowKeys([]);
                    dispatch(actions.deleteProduct(selectedRowKeys));
                  },
                })
              }
            >
              Hapus
            </Button>
            <Button type="primary" onClick={() => setVisible(true)}>
              <PlusOutlined /> Tambah
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 15 }}
              onClick={() => setShowFilter(!ShowFilter)}
            >
              <FilterOutlined /> {ShowFilter ? 'Sembunyikan Filter' : 'Filter'}
            </Button>
          </div>
          <div style={{ marginBottom: 16 }}>
            {ShowFilter ? (
              <Form layout="horizontal" form={form} onFinish={handleFilter}>
                <Card title="Filter berdasarkan">
                  <Col span={8}>
                    <Form.Item name="name">
                      <Input placeholder="Nama" />
                    </Form.Item>
                    <Form.Item name="category_id">
                      <Select
                        labelInValue
                        showSearch
                        placeholder="Ketik Nama Model"
                        onSearch={onSearch}
                        filterOption={false}
                      >
                        {categories.map((category) => (
                          <Option key={category.id} value={category.id}>
                            {category.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 8 }}
                    >
                      Terapkan Filter
                    </Button>
                    <Button
                      type="primary"
                      htmlType="reset"
                      onClick={() => {
                        form.resetFields();
                        history.push('/products');
                      }}
                      danger
                    >
                      Reset Filter
                    </Button>
                  </>
                </Card>
              </Form>
            ) : null}
          </div>
          <Table
            rowKey="id"
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectRow,
            }}
            columns={columns}
            dataSource={products}
            onChange={handleTableChange}
            onRow={(record) => ({
              onClick: () => {
                history.push(`/products/${record.id}`);
              },
            })}
            pagination={{
              current: parseInt(page) || 1,
              total: (pagination && pagination.total) || 0,
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <ProductCreate
        visible={visible}
        onCreate={onCreate}
        onCancel={() => setVisible(false)}
      />
    </>
  );
};

const mapStateToProps = ({ product }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    products: product.products,
    loading: product.loading,
    pagination: product.pagination,
    query,
  };
};

export default connect(mapStateToProps)(ProductPage);
