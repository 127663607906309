import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  PageHeader,
  Row,
  Table,
  Modal,
  Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { actions } from '../../../modules/reducers';
import CreateTailorFeeModal from '../components/create';

const { confirm } = Modal;

const CutterFeePage = ({
  dispatch,
  fees,
  query,
  pagination,
  history,
}) => {
  const page = query.get('page') || 1;
  const tailor_id = query.get('tailor_id');

  const [createModalVisible, setCreateModalVisible] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchFees({ page, tailor_id, tailor_type: 'CUT' }));
  }, [dispatch, page, tailor_id]);

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/production/cutter/fee?${query.toString()}`);
  };

  const handleDeleteClicked = (fee) => (
    confirm({
      title: 'Hapus Rekap Gaji',
      content: 'Anda yakin ingin menghapus Rekap Gaji ini?',
      onOk: () => {
        dispatch(actions.deleteCutterFee(fee));
      },
    })
  );

  const onCreate = (values) => {
    dispatch(actions.createCutterFee({ ...values }));
    setCreateModalVisible(false);
  };

  const feeExpandable = (row) => {
    const feeItemColumns = [
      {
        title: 'Kategori',
        dataIndex: 'category',
        key: (_, record) => `category-${record.id}`,
      },
      {
        title: 'Jumlah',
        dataIndex: 'total',
        key: (_, record) => `total-${record.id}`,
        render: (_, record) => `${record.type === 'C' ? '-' : ''} Rp. ${record.total.toLocaleString('ID')}`,
      },
    ];

    return <Table rowKey="id" columns={feeItemColumns} dataSource={row.items} pagination={false} />;
  };

  const columns = [
    {
      title: 'Tanggal Mulai Rekap',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (_, record) => format(new Date(record.start_date), 'dd MMM yyyy'),
    },
    {
      title: 'Tanggal Selesai Rekap',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (_, record) => format(new Date(record.end_date), 'dd MMM yyyy'),
    },
    {
      title: 'Tanggal Pembayaran',
      dataIndex: 'paid_at',
      key: 'paid_at',
      render: (_, record) => format(new Date(record.paid_at), 'dd MMM yyyy'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            target="_blank"
            href={`/production/fee/${record.id}/invoice`}
          >
            Print Gaji
          </Button>
          <Button type="danger" onClick={() => handleDeleteClicked(record)}>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <PageHeader title="Gaji Tukang Potong" style={{ padding: '0 0 16px' }} />
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => setCreateModalVisible(true)}>
              <PlusOutlined />
              Tambah
            </Button>
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={fees}
            onChange={handleTableChange}
            expandable={{ expandedRowRender: feeExpandable, defaultExpandedRowKeys: ['0'] }}
            pagination={{
              current: +query.get('page') || 1,
              total: (pagination && pagination.total) || 0,
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <CreateTailorFeeModal
        visible={createModalVisible}
        onCreate={onCreate}
        onCancel={() => setCreateModalVisible(false)}
      />
    </>
  );
};

const mapStateToProps = ({ fee }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    query,
    fees: fee.fees,
    pagination: fee.pagination,
  };
};

export default connect(mapStateToProps)(CutterFeePage);
