import { message } from 'antd';
import * as constant from './constants';
import api from '../../../utils/api';

const loading = () => ({
  type: constant.FEE_LOADING,
});

export const fetchFees = (params) => async (dispatch) => {
  dispatch(loading());

  try {
    const qs = Object.keys(params)
      .filter((x) => params[x])
      .map((x) => `${x}=${params[x]}`)
      .join('&');

    const { data: res } = await api.get(`/production/tailor-fees?${qs}`);

    if (res.success) {
      dispatch({
        type: constant.FEES_FULFILLED,
        payload: res.data,
        pagination: {
          total: res.pagination.rowCount,
          current: res.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchFee = (modelID) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/production/tailor-fees/${modelID}`);

    if (res.success) {
      dispatch({
        type: constant.FEE_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createFee = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/production/tailor-fees', data);

    if (res.success) {
      message.success('Berhasil menyimpan gaji penjahit.');
      dispatch(fetchFees({ page: 1, tailor_type: 'SEW' }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteFee = (model) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/production/tailor-fees/${model.id}`);

    if (res.success) {
      message.success('Berhasil menghapus biaya penjahit.');
      dispatch(fetchFees({ page: 1, tailor_type: 'SEW' }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateFee = (formData, model) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/production/tailor-fees/${model.id}`, formData);

    if (res.success) {
      message.success('Berhasil mengubah biaya penjahit.');
      dispatch(fetchFees({ page: 1, tailor_type: 'SEW' }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const createCutterFee = (data) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.post('/production/tailor-fees', data);

    if (res.success) {
      message.success('Berhasil menyimpan gaji penjahit.');
      dispatch(fetchFees({ page: 1, tailor_type: 'CUT' }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteCutterFee = (model) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/production/tailor-fees/${model.id}`);

    if (res.success) {
      message.success('Berhasil menghapus biaya penjahit.');
      dispatch(fetchFees({ page: 1, tailor_type: 'CUT' }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateCutterFee = (formData, model) => async (
  dispatch,
) => {
  dispatch(loading());

  try {
    const { data: res } = await api.put(`/production/tailor-fees/${model.id}`, formData);

    if (res.success) {
      message.success('Berhasil mengubah biaya penjahit.');
      dispatch(fetchFees({ page: 1, tailor_type: 'CUT' }));
    }
  } catch (err) {
    console.error(err);
  }
};
