/* eslint-disable import/prefer-default-export */
import { message } from 'antd';

import api from '../../../utils/api';
import * as constant from './constants';

const loading = () => ({
  type: constant.POS_ORDER_LOADING,
});

export const fetchPOSOrders = (params) => async (dispatch) => {
  dispatch(loading());

  const qs = Object.keys(params)
    .filter((x) => params[x])
    .map((x) => `${x}=${params[x]}`)
    .join('&');

  try {
    const res = await api.get(`/pos-order?${qs}`);

    if (res.data.success) {
      dispatch({
        type: constant.POS_ORDERS_FULFILLED,
        payload: res.data.data,
        pagination: {
          total: res.data.pagination.rowCount,
          current: res.data.pagination.page,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchPOSOrder = (orderId) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.get(`/pos-order/${orderId}/detail`);

    if (res.success) {
      dispatch({
        type: constant.POS_ORDER_FULFILLED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deletePOSOrder = (orderId, history) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete(`/pos-order/${orderId}/cancel`);

    if (res.success) {
      message.success('Berhasil menghapus keranjang belanja');
      history.goBack();
    } else {
      if (res.message !== '') {
        message.error(res.message);
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const deletePOSProduct = (datas) => async (dispatch) => {
  dispatch(loading());

  try {
    const { data: res } = await api.delete('/pos-product', {
      data: {
        ids: datas,
      },
    });

    if (res.success) {
      dispatch(fetchPOSOrders({ page: 1, name: '', category_id: '' }));
      message.success('Berhasil Menghapus Produk');
    }
  } catch (err) {
    console.error(err);
  }
};
