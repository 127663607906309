/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import {
  Row,
  Col,
  Table,
  PageHeader,
  Button,
  Form,
  Card,
  DatePicker,
  Input,
  Checkbox,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FilterOutlined,
} from '@ant-design/icons';

import { countQuantity } from '../index';
import { actions } from '../../../modules/reducers';

const { RangePicker } = DatePicker;

const POSOrderHistories = ({
  dispatch,
  posOrderHistories,
  pagination,
  history,
  query,
}) => {
  const page = query.get('page');
  const actor = query.get('actor');
  const is_barang_sortir = query.get('is_barang_sortir');
  const start_date = query.get('start_date');
  const end_date = query.get('end_date');

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(
      actions.fetchPOSOrderHistories({
        page: page || 1,
        actor,
        is_barang_sortir,
        start_date,
        end_date,
      }),
    );
  }, [dispatch, page, actor, is_barang_sortir, start_date, end_date]);

  const [ShowFilter, setShowFilter] = useState(false);

  const columns = [
    {
      title: 'Actor',
      dataIndex: 'actor',
      key: 'actor',
      render: (actor) => <span>{actor}</span>,
    },
    {
      title: 'Barang Sortir',
      dataIndex: 'is_barang_sortir',
      key: 'is_barang_sortir',
      render: (text) => (
        <div>
          {text ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
          ) : (
            <CloseCircleOutlined style={{ color: 'red' }} />
          )}
        </div>
      ),
    },
    {
      title: 'Total Motif',
      dataIndex: 'items',
      key: 'items',
      render: (i) => `${i?.length || 0} motif`,
    },
    {
      title: 'Total Barang',
      dataIndex: 'items',
      key: 'items',
      render: (items) => {
        const t = items.reduce((x, y) => x + y.qty, 0);
        return countQuantity(t);
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (value) => `Rp. ${value.toLocaleString('id')}`,
    },
    {
      title: 'Ditambahkan',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => (value ? format(new Date(value), 'dd MMM yyyy HH:mm') : '-'),
    },
  ];

  const handleTableChange = (p) => {
    query.set('page', p.current);
    history.push(`/pos-order-histories?${query.toString()}`);
  };

  const handleFilter = (value) => {
    if (value.date_range) {
      const [start, end] = value.date_range;
      if (start) {
        query.set('start_date', start.format('YYYY-MM-DD'));
      }
      if (end) {
        query.set('end_date', end.format('YYYY-MM-DD'));
      }
    } else if (value.actor) {
      query.set('actor', value.actor);
    }

    query.set('is_barang_sortir', value.is_barang_sortir ? 1 : 0);

    query.delete('page');
    history.push(`/pos-order-histories?${query.toString()}`);
  };

  const handleReset = () => {
    form.resetFields();
    history.push('/pos-order-histories');
  };

  return (
    <Row>
      <Col span={24} style={{ marginBottom: 16 }}>
        <PageHeader title="History Barang Masuk" style={{ padding: '0 0 16px' }} />
        <Button type="primary" onClick={() => setShowFilter(!ShowFilter)}>
          <FilterOutlined />
          {' '}
          {ShowFilter ? 'Sembunyikan Filter' : 'Filter'}
        </Button>
      </Col>
      {ShowFilter ? (
        <Col span={24} style={{ marginBottom: 16 }}>
          <Form layout="vertical" form={form} onFinish={handleFilter} onReset={handleReset}>
            <Card title="Filter berdasarkan">
              <Col span={8}>
                <Form.Item name="actor">
                  <Input placeholder="Actor" />
                </Form.Item>
                <Form.Item name="date_range">
                  <RangePicker placeholder={['Tanggal mulai', 'Tanggal akhir']} />
                </Form.Item>
                <Form.Item name="is_barang_sortir" valuePropName="checked">
                  <Checkbox>Barang Sortir</Checkbox>
                </Form.Item>
              </Col>
              <>
                <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                  Terapkan Filter
                </Button>
                <Button type="primary" htmlType="reset" danger>
                  Reset Filter
                </Button>
              </>
            </Card>
          </Form>
        </Col>
      ) : null}
      <Col span={24}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={posOrderHistories}
          onRow={(record) => ({
            onClick: () => {
              history.push(`/pos-order-histories/${record.id}`);
            },
          })}
          onChange={handleTableChange}
          pagination={{
            current: parseInt(page) || 1,
            total: (pagination && pagination.total) || 0,
            showSizeChanger: false,
          }}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ posOrderHistories }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    pagination: posOrderHistories.pagination,
    posOrderHistories: posOrderHistories.posOrderHistories,
    query,
  };
};

export default connect(mapStateToProps)(POSOrderHistories);
