import * as constant from './constants';

export const initialState = {
  loading: false,
  products: [],
  product: null,
  stockAudit: [],
  stockAuditPagination: null,
  pagination: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.PRODUCT_LOADING:
      return {
        ...state,
        loading: action.loading,
        products: [],
        product: null,
        stockAudit: [],
        pagination: null,
      };
    case constant.PRODUCT_FULFILLED:
      return {
        ...state,
        loading: false,
        product: action.payload,
      };
    case constant.PRODUCTS_FULFILLED:
      return {
        ...state,
        loading: false,
        products: action.payload,
        pagination: action.pagination,
      };
    case constant.PRODUCT_STOCK_AUDIT_FULFILLED:
      return {
        ...state,
        loading: false,
        stockAudit: action.payload,
        stockAuditPagination: action.pagination,
      };
    default:
      return state;
  }
};

export default productReducer;
